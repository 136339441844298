import { EditOutlined } from '@mui/icons-material';
import { Box, Chip, Grid2, Link, Modal, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '~/utils/context/AppContext';
import { getOdmChipIcon, getOdmStatusWording, getOdmTypeLabel, getOdmVariant } from '~/utils/references';
import { appTheme } from '~/utils/theme';
import _ from 'lodash';
import { WizzardMissionOrderRecap } from '../WizzardMissionOrder/WizzardMissionOrderRecap';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

export const OdmsList = ({ odms, setProductDatas, refreshData }) => {
  const { referencial } = useContext(AppContext);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editableOdm, setEditableOdm] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const mediaQueries = useContext(MediaQueryContext);
  const [odmsList, setOdmsList] = useState(null);

  const onClose = () => {
    setEditModalOpen(false);
    refreshData();
  };

  useEffect(() => {
    if (odms === null || odms.length === 0) return;
    setHasMore(odms.length > 3);
    const orderEdl = [7, 1, 6, 2, 3, 5, 8, 4, 0];
    const sortedOdms = _.sortBy(odms, (odm) => {
      const status = odm.edl?.status || odm.scan?.status || '';
      return orderEdl.indexOf(status);
    });
    setOdmsList(sortedOdms);
  }, [odms]);

  return (
    <>
      {odmsList && odmsList.length > 0 && (
        <Grid2 container spacing={0}>
          <Grid2 size={12}>
            <Grid2 container spacing={0}>
              {odmsList.map((odm, index) => (
                <Grid2 key={odm.uuid} style={{ display: 'contents' }}>
                  {(index < 3 || !showMore) && (
                    <Grid2 size={12} key={odm.uuid} sx={{ marginBottom: '10px' }}>
                      <Grid2 container spacing={1}>
                        <Grid2 size={3}>
                          <Box>
                            <Chip
                              size="small"
                              icon={getOdmChipIcon(odm)}
                              label={getOdmStatusWording(odm, referencial)}
                              sx={{
                                color: getOdmVariant(odm) + '.main',
                                backgroundColor: getOdmVariant(odm) + '.light'
                              }}
                            />
                          </Box>
                        </Grid2>
                        <Grid2 size={{ xs: 'grow' }}>
                          <Grid2 container spacing={0.5}>
                            <Grid2 size={{ xs: 12 }} sx={{ display: 'inline-flex' }}>
                              <Grid2 container spacing={0}>
                                <Grid2 size={{ xs: 12 }} sx={{ display: 'inline-flex' }}>
                                  <Typography
                                    sx={{ color: 'grey' }}
                                  >{`${new Date(odm.created_at).toLocaleDateString()}${new Date(odm.data.start_lease).toLocaleDateString() !== 'Invalid Date' ? ' - ' + new Date(odm.data.start_lease).toLocaleDateString() : ''}${
                                    odm.appointment_date !== null
                                      ? ' - RDV :' +
                                        new Date(odm.appointment_date).toLocaleDateString() +
                                        ' à ' +
                                        new Date(odm.appointment_date).toLocaleTimeString()
                                      : ''
                                  }`}</Typography>
                                </Grid2>
                                <Grid2 size={{ xs: 12 }} sx={{ display: 'inline-flex' }}>
                                  <Typography
                                    sx={{ fontWeight: 500 }}
                                  >{`${getOdmTypeLabel(odm.kind, odm.edl?.kind)} - ${odm.ref_internal}${odm.user_in_charge ? ' - ' + odm.user_in_charge?.first_name + ' ' + odm.user_in_charge?.last_name : ''}`}</Typography>
                                </Grid2>
                              </Grid2>
                            </Grid2>
                            <Grid2 size={12}>
                              <Typography sx={{ color: 'grey', fontSize: '12px', position: 'relative', top: '-10px' }}>
                                {odm.desc}
                              </Typography>
                            </Grid2>
                          </Grid2>
                        </Grid2>
                        <Box sx={{ flex: 0 }}>
                          <Link
                            className="Product__link"
                            onClick={() => {
                              setEditableOdm(odm);
                              setEditModalOpen(true);
                            }}
                            color="primary"
                          >
                            <EditOutlined />
                          </Link>
                        </Box>
                      </Grid2>
                    </Grid2>
                  )}
                </Grid2>
              ))}
            </Grid2>
          </Grid2>

          {hasMore && (
            <Grid2
              size={12}
              style={{ cursor: 'pointer', textAlign: 'right' }}
              onClick={() => {
                setShowMore(!showMore);
              }}
              sx={{
                '&:hover': {
                  color: appTheme.palette.secondary.main // Change this to your desired color
                }
              }}
            >
              {showMore ? 'Voir plus' : 'Voir moins'}
            </Grid2>
          )}

          {/* EDIT MODAL */}

          {editableOdm && (
            <Modal open={editModalOpen} onClose={onClose}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '7.5%',
                  left: !mediaQueries.matchesMd ? '7.5%' : '25%',
                  width: !mediaQueries.matchesMd ? '75%' : '50%',
                  height: '75%',
                  backgroundColor: 'white',
                  borderRadius: '20px',
                  p: 3
                }}
              >
                <WizzardMissionOrderRecap data={editableOdm} close={onClose} />
              </Box>
            </Modal>
          )}
        </Grid2>
      )}
    </>
  );
};
