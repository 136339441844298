import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Grid2, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import { grey } from '@mui/material/colors';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  createFile,
  deleteFile,
  getProductImages,
  setThumbnail,
  zipFiles,
  getProductMatterportImages,
  api
} from '~/api';
import { ForceDownload } from '~/utils/ForceDownload';
import './ImagePicker.scss';
import { UploadFile } from '../CustomDropZoneArea/CustomDropZoneArea';
import { ProductTitle } from '../ProductTitle/ProductTitle';
import { EquipmentSubTitle } from '../EquipmentSubTitle/EquipmentSubTitle';

type TuseParams = {
  id: string;
  idEquipment: string;
};

interface IImageData {
  date: string;
  desc: string;
  type: string;
  validitydate: string;
}

interface IImages {
  uuid_owner: string;
  name: string;
  file: any;
  kind: string;
  category: string;
  data: IImageData[];
}

export const ImagePicker = ({
  matterport_model_id,
  refreshProductImages,
  productData,
  equipmentData = null,
  generateSubtitleEquipment = null,
  equipmentReferential = null
}) => {
  const { id, idEquipment } = useParams<TuseParams>();
  const [loading, setLoading] = useState(false);
  const [addImage, setAddImage] = useState<boolean>(false);
  const [images, setImages] = useState([]);
  const [importButton, setImportbutton] = useState(true);
  const [isCheckedAllImg, setIsCheckedAllImg] = useState(false);
  const [isCheckedImg, setIsCheckedImg] = useState([]);
  const [isFeaturedImg, setIsFeaturedImg] = useState([]);
  const location = useLocation();
  const currentPage = location.pathname;
  const isEditProduct = !currentPage.includes('equipment') && !currentPage.includes('add-equipment');
  const isEditEquipment = currentPage.includes('equipments');
  const isAddEquipment = currentPage.includes('add-equipment');
  const [files, setFiles] = useState(null);

  const headerRef = useRef(null);

  // initial images

  useEffect(() => {
    console.log(currentPage);
    console.log(isEditEquipment);
    // we are on product page
    if (isEditProduct) {
      getProductImages(id)
        .then((images) => {
          setImages(images);
          let featIndex = images.findIndex((image) => image.kind === 'thumbnail');
          const feat = [];
          if (featIndex === -1 && images && images.length > 0) featIndex = 0;
          feat.push(featIndex);
          setIsFeaturedImg(feat);
        })
        .catch((err) => {});
    }

    // we are on equipment page
    if (isEditEquipment || isAddEquipment) {
      const allImgs = [];
      api.equipments.get(idEquipment).then((res) => {
        console.log(res);
        res.images?.map((img) => {
          allImgs.push(img);
        });

        res.states?.map((state) => {
          state.images?.map((img) => {
            allImgs.push(img);
          });
          state.tags?.map((tag) => {
            tag.images?.map((img) => {
              allImgs.push(img);
            });
          });
        });
        console.log(allImgs);
        setImages(allImgs);
      });
    }
  }, [id]);

  // Send image file
  const handleImageSubmit = (e) => {
    const promisesFiles = [];
    files.forEach((file, index) => {
      const data = new FormData();

      // we are on equipment page
      if (isEditEquipment || isAddEquipment) {
        data.append('uuid_owner', idEquipment);
      }

      // we are on product page
      if (isEditProduct) {
        data.append('uuid_owner', id);
      }

      data.append('category', 'image');
      data.append('kind', 'gallery');
      data.append('file', file);
      promisesFiles.push(createFile(data));
    });

    Promise.all(promisesFiles)
      .then((response) => {
        if (images !== null) {
          response.map((img) => images.push(img));
          setImages(images);
        } else {
          const imgs = [];
          response.map((img) => imgs.push(img));
          setImages(imgs);
        }
        // close
        setAddImage(false);
        refreshProductImages();
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setFiles(null);
      });
    e.preventDefault();
  };

  // Import Matterport Images
  const handleImageImport = () => {
    setLoading(true);
    getProductMatterportImages(id)
      .then((matterportimages) => {
        setImages(matterportimages);
        setLoading(false);
        setImportbutton(false);
        setAddImage(false);
        refreshProductImages();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Check one image
  const handleCheckImg = (event, index) => {
    setIsCheckedImg([...isCheckedImg, index]);
    if (!event.target.checked) {
      setIsCheckedImg(isCheckedImg.filter((item) => item !== index));
    }
  };

  // Check all images
  const handleCheckAll = (event) => {
    if (event.target.checked) {
      setIsCheckedImg(null);
      const checklist = [];
      for (let i = 0; i < images.length; i++) {
        checklist.push(i);
      }
      setIsCheckedImg(checklist);
      setIsCheckedAllImg(true);
    } else {
      setIsCheckedAllImg(false);
      setIsCheckedImg([]);
    }
  };

  // Delete checked images
  const handleDeleteChecked = async () => {
    for (let i = 0; i < isCheckedImg.length; i++) {
      await deleteFile(images[isCheckedImg[i]].uuid);
      delete images[isCheckedImg[i]];
      const newimg = images.filter((item) => item);
      setImages(newimg);
      refreshProductImages();
    }
    const checklist = [];
    setIsCheckedImg(checklist);
    setIsCheckedAllImg(false);
  };

  // Download checked images
  const handleDownloadChecked = async () => {
    setLoading(true);
    const toZip = [];
    for (let i = 0; i < isCheckedImg.length; i++) {
      toZip.push(images[isCheckedImg[i]].uuid);
    }
    zipFiles(toZip)
      .then((resp) => {
        ForceDownload(resp.url, '');
      })
      .catch((err) => {
        console.log(err);
      });
    const checklist = [];
    setIsCheckedImg(checklist);
    setIsCheckedAllImg(false);
  };

  // Set featured image
  const handleFeaturedImg = async (event, index) => {
    setIsFeaturedImg([index]);
    setThumbnail(id, images[index].uuid).then(() => {
      refreshProductImages();
    });
    if (!event.target.checked) {
      setIsFeaturedImg(null);
      // refreshProductImages();
    }
  };

  return (
    <>
      {/* HEADER */}
      <Grid2 container ref={headerRef}>
        <div style={{ backgroundColor: '#fff7f3', width: '100vw', paddingLeft: '15px' }}>
          <Grid2 size={12}>
            <ProductTitle data={productData} />
          </Grid2>
        </div>
        {generateSubtitleEquipment && equipmentData && equipmentReferential && (
          <Grid2 size={12}>
            <EquipmentSubTitle
              generateSubtitleEquipment={generateSubtitleEquipment}
              equipmentData={equipmentData}
              equipmentReferencial={equipmentReferential}
            />
          </Grid2>
        )}
      </Grid2>

      <Grid2 container spacing={0} sx={{ maxHeight: '80%', padding: '15px', justifyContent: 'center' }}>
        <Grid2 size={12} sx={{ mt: '1vh' }}>
          <Grid2
            container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography variant="h3" component="div" sx={{ mb: '2vh' }}>
              {!isEditEquipment ? 'Photos du bien' : "Photos de l'équipement"}
            </Typography>
            <Typography variant="h4" component="div">
              {!isEditEquipment ? 'Gestion des photos du bien' : "Gestion des photos de l'équipement"}
            </Typography>
            {!addImage && (
              <Button
                color="secondary"
                variant="outlined"
                sx={{ color: 'primary', borderRadius: '20px', borderColor: 'secondary.main', mt: '1vh' }}
                onClick={() => setAddImage(!addImage)}
              >
                <AddOutlinedIcon fontSize="small" />
                &nbsp; Ajouter une photo
              </Button>
            )}
          </Grid2>
          {addImage && (
            <Grid2 container sx={{ display: 'flex', alignItems: 'center' }}>
              <Grid2 size={12} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                {matterport_model_id && (
                  <LoadingButton
                    onClick={handleImageImport}
                    loading={loading}
                    variant="outlined"
                    sx={{ borderRadius: '20px', mt: '1vh', mb: '2vh' }}
                  >
                    Importer depuis le modèle 3d
                  </LoadingButton>
                )}
                <Grid2 size={{ xs: 12, md: 4 }} sx={{ mb: '2vh' }}>
                  <UploadFile setFile={setFiles} />
                </Grid2>
              </Grid2>
              <Grid2
                size={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  gap: '4vw'
                }}
              >
                <Button variant="outlined" size="small" color={'primary'} onClick={() => setAddImage(false)}>
                  Annuler
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color={'primary'}
                  onClick={handleImageSubmit}
                  disabled={files === null}
                >
                  Valider
                </Button>
              </Grid2>
            </Grid2>
          )}
          {images && (
            <Grid2 container spacing={2} className="ImagePicker">
              <Grid2 size={12}>
                <Box sx={{ display: 'flex' }} justifyContent="space-between">
                  <Box
                    sx={{ fontSize: 14, display: 'flex', flexWrap: 'wrap', alignContent: 'center' }}
                    color="text.secondary"
                  >
                    {isCheckedImg.length} Sélectionnée(s)
                  </Box>
                  <Box justifyContent="flex-end">
                    {isCheckedImg.length > 0 && (
                      <IconButton
                        size="large"
                        aria-label="supprimer l'image"
                        component="span"
                        onClick={handleDeleteChecked}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    )}
                    {isCheckedImg.length > 0 && (
                      <IconButton
                        size="large"
                        aria-label="télécharger"
                        component="span"
                        onClick={handleDownloadChecked}
                      >
                        <FileDownloadIcon />
                      </IconButton>
                    )}
                    <Checkbox className="ImagePicker__CheckAll" onClick={handleCheckAll} checked={isCheckedAllImg} />
                  </Box>
                </Box>
              </Grid2>
              {images.map((image, index) => (
                <Grid2 size={{ xs: 6, md: 2 }} key={index}>
                  <Card elevation={3}>
                    <Checkbox
                      sx={{
                        position: 'absolute',
                        top: 7,
                        right: 7,
                        color: 'primary.main',
                        background: 'white',
                        padding: 0,
                        borderRadius: 0,
                        width: '16px',
                        height: '16px'
                      }}
                      onChange={(event) => handleCheckImg(event, index)}
                      checked={isCheckedImg.includes(index)}
                    />
                    <CardMedia
                      component="img"
                      image={process.env.REACT_APP_BASEURL_MEDIAS + '/' + image.uri}
                      alt={image.name}
                    />
                    {isEditProduct && (
                      <CardActions>
                        <Checkbox
                          onChange={(event) => handleFeaturedImg(event, index)}
                          checked={isFeaturedImg?.includes(index)}
                          icon={<BookmarkBorderIcon fontSize="small" />}
                          checkedIcon={<BookmarkIcon fontSize="small" />}
                          sx={{
                            '&.Mui-checked': {
                              color: grey[800]
                            }
                          }}
                        />
                      </CardActions>
                    )}
                  </Card>
                  <br />
                </Grid2>
              ))}
            </Grid2>
          )}
        </Grid2>
      </Grid2>
    </>
  );
};

export default ImagePicker;
