import { axiosGet, axiosPatch } from '.';
import axios from 'axios';

export const getDpeList = (idDpe, size) => {
  let urls = [
    // `https://data.ademe.fr/data-fair/api/v1/datasets/dpe-france/lines/?size=40&q=${idDpe}`,
    `https://data.ademe.fr/data-fair/api/v1/datasets/dpe-v2-logements-neufs/lines/?size=${size}&q=${idDpe}`,
    // `https://data.ademe.fr/data-fair/api/v1/datasets/dpe-tertiaire/lines/?size=40&q=${idDpe}`,
    // `https://data.ademe.fr/data-fair/api/v1/datasets/dpe-v2-tertiaire-2/lines/?size=40&q=${idDpe}`
    `https://data.ademe.fr/data-fair/api/v1/datasets/dpe-v2-logements-existants/lines?size=${size}&q=${idDpe}`
  ];
  const requests = urls.map((url) => axios.get(url));
  return axios.all(requests);
};
export const getDpe = (idDpe) => {
  return axiosGet(`/dpe/certified/${idDpe}`);
};

export const assignDpe = (idProduct, idDpe, data) => {
  return axiosPatch(`products/${idProduct}/dpe/assign/${idDpe}`, data);
};
