import { Grid2, Box, Typography } from '@mui/material';
import AdemeSearchStep from './AdemeSearchField';
import AddressSearchField from './AddressSearchField';
import { useEffect } from 'react';

const LocalisationBloc = ({
  title,
  text,
  searchType,
  resetSearchField,
  setResetSearchField,
  setAdemeDatas = null,
  setGooglePlaceDatas = null
}) => {
  useEffect(() => {
    console.log('resetSearchField', resetSearchField);
  }, [resetSearchField]);

  return (
    <Grid2 size={{ xs: 12, md: 4 }}>
      <Box
        sx={{
          padding: '16px',
          borderRadius: '8px',
          border: '1px solid #f6F6F6',
          boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)'
        }}
      >
        <Grid2 container>
          <Grid2 size={{ xs: 'grow' }}>
            <Grid2 container sx={{ color: '#062746' }}>
              <Grid2>
                <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#000', lineHeight: '1.2' }}>
                  {title}
                </Typography>
              </Grid2>
            </Grid2>

            <Grid2 container>
              <Grid2 size={{ xs: 'grow' }}>
                <Typography sx={{ marginTop: '8px', fontSize: '16px', color: '#929292', mb: '20px' }}>
                  {text}
                </Typography>
              </Grid2>
            </Grid2>
            <Grid2 container>
              <Grid2
                size={{ xs: 'grow' }}
                sx={{
                  mb: '20px'
                }}
              >
                {searchType === 'ademe' && (
                  <AdemeSearchStep
                    resetSearchField={resetSearchField}
                    setResetSearchField={setResetSearchField}
                    setAdemeDatas={setAdemeDatas}
                  />
                )}
                {searchType === 'google-places' && (
                  <AddressSearchField
                    resetSearchField={resetSearchField}
                    setResetSearchField={setResetSearchField}
                    setGooglePlaceDatas={setGooglePlaceDatas}
                  />
                )}
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </Box>
    </Grid2>
  );
};

export default LocalisationBloc;
