import { use, useContext, useEffect, useState } from 'react';
import BasicModal from '../BasicModal/BasicModal';
import {
  Grid2,
  Button,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
  Autocomplete
} from '@mui/material';
import { api } from '~/api';
import { ProductTitle } from '../ProductTitle/ProductTitle';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { AppContext } from '~/utils/context/AppContext';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';
import { WizzardContact } from '~/app/components/WizzardContact/WizzardContact';

export const LeaseAddUpdate = ({ productDatas, setLeaseAddOpen, setPropertyData, leaseData }) => {
  const { referencial } = useContext(AppContext);
  const mediaQueries = useContext(MediaQueryContext);

  const [modalOpen, setModalOpen] = useState<boolean>(true);
  const [leaseRegime, setLeaseRegime] = useState(leaseData?.regime ? leaseData?.regime : '');
  const [typeLease, setTypeLease] = useState<number>(leaseData?.leaseType ? leaseData?.leaseType : null);
  const [startLease, setStartLease] = useState<dayjs.Dayjs | null>(
    leaseData?.startDate ? dayjs(leaseData?.startDate) : null
  );
  const [endLease, setEndLease] = useState<dayjs.Dayjs | null>(leaseData?.endDate ? dayjs(leaseData?.endDate) : null);
  const [exitLease, setExitLease] = useState<dayjs.Dayjs | null>(
    leaseData?.exitDate ? dayjs(leaseData?.exitDate) : null
  );
  const [residents, setResidents] = useState(leaseData?.tenants ? leaseData?.tenants : []);
  const [allContactsOrgaForm, setAllContactsOrgaForm] = useState([]);
  const [addContact, setAddContact] = useState<boolean>(false);
  const [allContacts, setAllContacts] = useState([]);
  const [createLease, setCreateLease] = useState(leaseData ? false : true);
  const [autocompleteComponents, setAutocompleteComponents] = useState([{ id: 0, value: null }]);

  const [error, setError] = useState(false);

  const modalClosed = () => {
    setLeaseAddOpen(false);
  };

  const handleLease = (event) => {
    setLeaseRegime(event.target.value);
  };

  const handleDurationLease = (event) => {
    setTypeLease(event.target.value);
  };

  const handleStartLease = (date) => {
    setStartLease(date);
  };

  const handleEndLease = (date) => {
    setEndLease(date);
  };

  const hanldeExitLease = (date) => {
    setExitLease(date);
  };

  const handleResidents = (e, newValue, index) => {
    const updatedResidents = [...residents];

    if (newValue) {
      updatedResidents[index] = newValue;
    } else {
      updatedResidents.splice(index, 1);
    }
    setResidents(updatedResidents);
  };

  const handleRemoveAutocomplete = (index) => {
    if (autocompleteComponents.length <= 1) {
      return;
    }

    const updatedComponents = [...autocompleteComponents];
    updatedComponents.splice(index, 1);
    setAutocompleteComponents(updatedComponents);

    const updatedResidents = [...residents];
    updatedResidents.splice(index, 1);
    setResidents(updatedResidents);
  };

  const handleAddAutocomplete = () => {
    const lastId = autocompleteComponents[autocompleteComponents.length - 1].id;
    setAutocompleteComponents([...autocompleteComponents, { id: lastId + 1, value: null }]);
  };

  const handleError = () => {
    setError(!error);
  };

  useEffect(() => {
    if (leaseData && leaseData?.tenants?.length > 1) {
      setAutocompleteComponents(
        leaseData?.tenants.map((item, index) => {
          return { id: index, value: item };
        })
      );
    }
  }, [leaseData]);

  useEffect(() => {
    if (!productDatas) return;
    api.product.contact.get(productDatas.uuid).then((res) => {
      if (res) {
        setAllContacts(res);
      }
    });
  }, [productDatas]);

  const getAllContacts = () => {
    api?.contacts?.search().then((res) => {
      setAllContactsOrgaForm(res);
    });
  };

  useEffect(() => {
    getAllContacts();
  }, [productDatas]);

  const createNewLease = () => {
    const leasePayload = {
      kind: leaseRegime,
      start_at: startLease.format(),
      end_at: endLease.format(),
      duration: Math.floor(typeLease),
      uuid_contacts: residents.map((item) => item.uuid),
      uuid_product: productDatas.uuid
    };

    api.lease
      .create(leasePayload)
      .then((response) => {
        setModalOpen(false);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const updateLease = () => {
    const leasePayload = {
      kind: leaseRegime,
      start_at: startLease.format(),
      end_at: endLease.format(),
      duration: Math.floor(typeLease),
      uuid_contacts: residents.map((item) => item.uuid),
      uuid_product: productDatas.uuid,
      exit_at: exitLease ? exitLease.format() : null
    };

    api.lease
      .update(leaseData.uuid, leasePayload)
      .then((response) => {
        setModalOpen(false);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const handleCloseModalContact = () => {
    setAddContact(!addContact);
  };

  return (
    <BasicModal
      modalOpen={modalOpen}
      onModalOpen={(open: boolean) => setModalOpen(open)}
      onModalClose={modalClosed}
      confirmClose={false}
    >
      <Grid2 container spacing={2} justifyContent={'center'} alignItems={'center'}>
        <Grid2
          size={12}
          style={{
            backgroundColor: '#fff7f3',
            width: '100vw',
            paddingLeft: '15px',
            position: 'sticky',
            top: 0,
            zIndex: 1
          }}
        >
          <ProductTitle data={productDatas} />
        </Grid2>

        <Grid2
          container
          spacing={2}
          justifyContent={'center'}
          alignItems={'center'}
          display={'flex'}
          sx={{ marginX: mediaQueries?.matchesMd ? '12vw' : '6vw' }}
        >
          <Grid2 size={12} sx={{ textAlign: 'center' }}>
            {createLease ? (
              <Typography variant="h4">Renseignez les informations liées au bail </Typography>
            ) : (
              <Typography variant="h4">Modifier les informations liées au bail </Typography>
            )}
          </Grid2>

          {/* Type de bail */}
          <Grid2 size={{ xs: 12, md: 6 }}>
            <TextField
              label="Type de bail *"
              variant="outlined"
              size={mediaQueries?.matchesMd ? 'small' : 'medium'}
              fullWidth
              onChange={handleDurationLease}
              type="number"
              value={typeLease}
              error={error && !typeLease}
            >
              {typeLease}
            </TextField>
          </Grid2>

          {/* Régime locatif */}
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Grid2 size={{ xs: 12 }}>
              <FormControl fullWidth>
                <InputLabel id="type-bail-label" size="small" error={error && !leaseRegime}>
                  Régime locatif *
                </InputLabel>
                <Select
                  labelId="type-bail-label"
                  value={leaseRegime}
                  onChange={handleLease}
                  required
                  label="Régime locatif"
                  size={mediaQueries?.matchesMd ? 'small' : 'medium'}
                  error={error && !leaseRegime}
                >
                  {referencial['lease.kind']?.map((lease, index) => (
                    <MenuItem key={index} value={lease.id}>
                      {lease.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid2>
          </Grid2>

          {/* Dates */}
          <Grid2 size={{ xs: 12, md: createLease ? 6 : 4 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
              <DatePicker
                label="Début de bail *"
                value={startLease}
                onChange={(date) => handleStartLease(date)}
                format="DD/MM/YYYY"
                sx={{ width: '100%' }}
                slotProps={{
                  textField: {
                    error: error && !startLease
                  }
                }}
              />
            </LocalizationProvider>
          </Grid2>

          <Grid2 size={{ xs: 12, md: createLease ? 6 : 4 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
              <DatePicker
                value={endLease}
                onChange={(date) => handleEndLease(date)}
                format="DD/MM/YYYY"
                label="Fin de bail *"
                sx={{ width: '100%' }}
                slotProps={{
                  textField: {
                    error: error && !endLease
                  }
                }}
              />
            </LocalizationProvider>
          </Grid2>

          {!createLease && (
            <Grid2 size={{ xs: 12, md: createLease ? 6 : 4 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                <DatePicker
                  value={exitLease}
                  onChange={(date) => hanldeExitLease(date)}
                  format="DD/MM/YYYY"
                  label="Date de sortie *"
                  sx={{ width: '100%' }}
                  slotProps={{
                    textField: {
                      error: error && !exitLease
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid2>
          )}

          {/* Résidents */}
          {autocompleteComponents.map((component, index) => (
            <Grid2 size={{ xs: 12, md: 12 }} key={index}>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Autocomplete
                  options={allContactsOrgaForm}
                  getOptionLabel={(option) => option?.name || ''}
                  size={mediaQueries?.matchesMd ? 'small' : 'medium'}
                  onChange={(e, newValue) => handleResidents(e, newValue, index)}
                  isOptionEqualToValue={(option, value) => option?.name === value?.name}
                  value={residents[index] || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Rechercher un locataire *"
                      variant="outlined"
                      error={error && !residents[index]}
                    />
                  )}
                  noOptionsText="Aucun locataire trouvé"
                />
              </Grid2>

              {index === autocompleteComponents.length - 1 && (
                <Grid2 size={{ xs: 8, md: 12 }} sx={{ mt: 2 }}>
                  {autocompleteComponents.length > 1 && (
                    <Button onClick={() => handleRemoveAutocomplete(index)}>
                      <PersonRemoveIcon color="secondary" />
                    </Button>
                  )}
                  <Button onClick={handleAddAutocomplete}>
                    <PersonAddIcon color="primary" />
                  </Button>
                </Grid2>
              )}
            </Grid2>
          ))}

          <Grid2 size={12}>
            <Button
              variant="contained"
              sx={{ borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => setAddContact(!addContact)}
            >
              <AddOutlinedIcon /> Créer un nouveau contact
            </Button>
          </Grid2>

          {addContact && (
            <WizzardContact
              open={addContact}
              isProductPage={true}
              onClose={handleCloseModalContact}
              isNewContact={true}
              productId={productDatas.uuid}
              contacts={allContacts}
              setContacts={setAllContacts}
            />
          )}

          {error && (
            <Typography variant="caption" color="error">
              Veuillez remplir tous les champs
            </Typography>
          )}

          {/* Sticky Buttons */}
          <Grid2
            size={12}
            sx={{
              position: 'sticky',
              bottom: 0,
              backgroundColor: '#fff',
              padding: 2,
              display: 'flex',
              justifyContent: 'center',
              zIndex: 10
            }}
          >
            <Button
              variant="outlined"
              sx={{ marginRight: 2 }}
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Annuler
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (!leaseRegime || !startLease || !endLease || !typeLease || residents.length === 0) {
                  handleError();
                }
                if (createLease) {
                  createNewLease();
                } else {
                  updateLease();
                }
              }}
            >
              Enregistrer
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </BasicModal>
  );
};
