import { IosShareOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { referentielEventTypes } from '~/utils/references';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { getIcon } from 'material-file-icons';
import { useContext, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { createFile, getProduct, postMessage, patchTag } from '~/api';
import { IProduct, ModalContentType } from '~/interfaces';
import { EventShareDialog } from '~/app/components/EventShareDialog/EventShareDialog';
import { EquipmentsList } from '~/app/components/EquipmentsList/EquipmentsList';
import { appTheme } from '~/utils/theme';
import auth from '~/app/components/Auth/AuthApi';
import './Events.scss';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

type TuseParams = {
  id: string;
  idEvent: string;
};

const EventDetail = ({
  setDataForm,
  dataForm,
  eventData,
  idEvent,
  setEventData,
  productDatas = null,
  setWizzardEquipmentOpen = null,
  wizzardEquipmentCreationMode = null,
  setWizzardEquipmentCreationMode = null,
  setSelectedUUIDEquipment = null,
  setWizzardEventCreationMode = null,
  setWizzardEventOpen = null,
  setSelectedUUIDEvent = null,
  refreshRequired = null
}) => {
  const { id } = useParams<TuseParams>();
  const [messagesList, setMessagesList] = useState(null);
  const [parsedJwt, setParsedJwt] = useState(null);
  const [modalType, setModalType] = useState<ModalContentType>();
  const [currentMessageUUID, setCurrentLMessageUUID] = useState(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [observation, setObservation] = useState('');
  const [messageUuidSeelected, setMessageUuidSeelected] = useState('');
  const [productData, setProductData] = useState<null | IProduct>(null);
  const [address, setAddress] = useState(null);
  const [openShareEventDialog, setOpenShareEventDialog] = useState(false);
  const [userName, setUserName] = useState<any>('');

  const mediaQueries = useContext(MediaQueryContext);

  useEffect(() => {
    setParsedJwt(auth.getJWT());
    if (parsedJwt) displayMessages();
    if (eventData.user_creator) setUserName(eventData.user_creator.first_name + ' ' + eventData.user_creator.last_name);
  }, []);

  useEffect(() => {
    if (parsedJwt) displayMessages();
    setObservation('');
  }, [eventData, parsedJwt]);

  const handleSubmit = (observation) => {
    const obj = { content: observation };
    postMessage(idEvent.toString(), obj).then((res) => {
      const newEventData = JSON.parse(JSON.stringify(eventData));
      if (newEventData.messages === null) {
        newEventData.messages = [];
      }
      newEventData.messages.push(res);
      setEventData(newEventData);
    });
  };

  const handleModal = (type: ModalContentType) => {
    getProduct(id === undefined ? eventData.uuid_owner : id).then((res) => {
      setProductData(res);
      setAddress(`${res.address.street}, ${res.address.city}, ${res.address.country}`);
      setModalOpen(!modalOpen);
      setModalType(type);
    });
  };

  const handleSuppressMsg = (id) => {
    setMessageUuidSeelected(id);
    handleModal('Suppress');
    setModalOpen(true);
  };

  const displayDate = (date: string) => {
    const data = new Date(date);
    return data.toLocaleDateString('fr') + ' à ' + data.toLocaleTimeString([], { timeStyle: 'short' });
  };

  const buildRefEventTypesValues = () => {
    const arrType = [];
    referentielEventTypes.forEach((item, index) => {
      if (index > 0)
        arrType.push(
          <MenuItem key={index} value={Number(index)}>
            {item}
          </MenuItem>
        );
    });
    return arrType;
  };

  let uuid_message = '';

  const FileIcon = ({ data, baseUrlApi }) => {
    return (
      <Grid2
        container
        style={{ cursor: 'pointer' }}
        alignItems="center"
        onClick={() => {
          clickFile(`${baseUrlApi}${data.uri}`);
        }}
      >
        <Grid2
          style={{
            maxWidth: '54px',
            maxHeight: '54px'
          }}
          className="Events__document"
          dangerouslySetInnerHTML={{ __html: getIcon(data.name).svg }}
        ></Grid2>
      </Grid2>
    );
  };

  const handleImageChange = (e, uuid_owner) => {
    const file = e.target.files[0];
    const body = new FormData();

    body.append('file', file);
    body.append('uuid_owner', uuid_owner);
    body.append('owner_kind', 'message');

    createFile(body).then((res) => {
      eventData.messages.forEach((message, index) => {
        if (message.uuid !== uuid_owner) {
        } else {
          if (message.files === null) {
            message.files = [];
          }
          message.files.push(res);
        }
      });

      setEventData({ ...eventData });
    });
  };

  const displayMessages = () => {
    const messagesElements = [];
    const displayRelatedFiles = (files, isCurrrentUser) => {
      const filesArray = [];
      files.forEach((file, index) => {
        const baseUrlApi = process.env.REACT_APP_BASEURL_MEDIAS + '/';
        filesArray.push(
          <Tooltip key={index} title={file.name} arrow placement="top">
            <Grid2 key={index}>
              {file.kind === 'image' ? (
                <img
                  alt={file.name}
                  className="Events__document"
                  style={{
                    cursor: 'pointer',
                    maxWidth: '54px',
                    maxHeight: '54px'
                  }}
                  onClick={() => {
                    clickFile(`${baseUrlApi}${file.uri}`);
                  }}
                  src={`${baseUrlApi}${file.uri}`}
                ></img>
              ) : (
                FileIcon({ data: file, baseUrlApi: baseUrlApi })
              )}
            </Grid2>
          </Tooltip>
        );
      });

      return (
        <Grid2
          sx={{ paddingTop: '10px' }}
          container
          spacing={1}
          direction="row"
          justifyContent={isCurrrentUser ? 'flex-end' : 'flex-start'}
          alignItems="flex-start"
        >
          {filesArray}
        </Grid2>
      );
    };

    if (eventData.messages)
      eventData.messages.forEach((message, index) => {
        const isCurrrentUser = message.uuid_user === parsedJwt.uuid_user;
        const user = isCurrrentUser ? 'moi' : message.creator_name;

        messagesElements.push(
          <Grid2
            size={15}
            sx={{
              margin: '10px',
              textAlign: isCurrrentUser ? 'right' : 'left',
              color: isCurrrentUser ? 'green' : '',
              flewDirection: 'reverse'
            }}
            key={index}
          >
            {isCurrrentUser && (
              <div style={{ display: 'inline-block' }}>
                <label htmlFor="upload" className="Events__form__action__uploadMini">
                  <span></span>{' '}
                  <AddIcon
                    style={{ cursor: 'pointer', color: '#062746' }}
                    onClick={() => {
                      setCurrentLMessageUUID(message.uuid);
                      uuid_message = message.uuid;
                    }}
                  />
                </label>
                <input
                  type="file"
                  name="upload"
                  id="upload"
                  hidden
                  onChange={(event) => {
                    handleImageChange(event, uuid_message);
                  }}
                />
                <DeleteIcon
                  style={{ cursor: 'pointer', color: '#062746' }}
                  id={message.uuid}
                  onClick={() => {
                    handleSuppressMsg(message.uuid);
                  }}
                ></DeleteIcon>
              </div>
            )}

            <TextField
              sx={{
                backgroundColor: isCurrrentUser ? appTheme.palette.primary[50] : '',
                width: { md: '60%' }
              }}
              label={'Le ' + displayDate(message.created_at) + ' par ' + user}
              multiline
              disabled
              fullWidth
              value={message.content}
            ></TextField>
            <div>{message.files && message.files.length > 0 && displayRelatedFiles(message.files, isCurrrentUser)}</div>
          </Grid2>
        );
      });

    const container = (
      <Grid2 container spacing={2}>
        {messagesElements}
      </Grid2>
    );

    setMessagesList(container);
  };

  const updateTag = (tag) => {
    const newTagData = JSON.parse(JSON.stringify(tag));
    delete newTagData.images;
    delete newTagData.position;
    patchTag(tag.uuid.toString(), tag).then((res) => {});
  };

  const generateImages = () => {
    const totalFiles = [...eventData.files];
    if (eventData?.tags) {
      eventData?.tags.forEach((tag) => {
        console.log(tag);
        if (tag?.images && tag.images.length > 0) totalFiles.push(...tag.images);
      });
    }

    const reactElements = [];

    totalFiles.map((file, index) => {
      const baseUrlApi = process.env.REACT_APP_BASEURL_MEDIAS + '/';
      reactElements.push(
        <Tooltip key={index} title={file.name} arrow placement="top">
          <Grid2 key={index} sx={{ pr: '5px' }}>
            {file.kind === 'image' ? (
              <img
                alt={file.name}
                className="Events__document"
                style={{
                  cursor: 'pointer',
                  maxWidth: '54px',
                  maxHeight: '54px',
                  border: '1px solid #062746'
                }}
                onClick={() => {
                  clickFile(`${baseUrlApi}${file.uri}`);
                }}
                src={`${baseUrlApi}${file.uri}`}
              ></img>
            ) : (
              FileIcon({ data: file, baseUrlApi: baseUrlApi })
            )}
          </Grid2>
        </Tooltip>
      );
    });
    return reactElements;
  };

  const clickFile = (uri) => {
    window.open(uri, '_blank');
  };

  if (eventData === undefined) return null;

  return (
    <div>
      {!id && (
        <Box
          sx={{
            p: 4,
            pt: 2.3,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {productDatas && productDatas.address && (
            <div>
              <Link to={`/product/${productDatas.uuid}`}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h5" sx={{ color: 'primary.main' }}>
                    {`${productDatas.address.street} - ${productDatas.address.city} - ${productDatas.address.zip_code}`}
                  </Typography>
                </Box>
              </Link>
            </div>
          )}
        </Box>
      )}

      <Box
        sx={{
          p: 4,
          pt: 2.3,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h5" sx={{ color: 'primary.main' }}>
            {referentielEventTypes[eventData.kind]}
          </Typography>
        </Box>
        <Box>
          <Button
            color="primary"
            size="medium"
            startIcon={<IosShareOutlined />}
            variant="outlined"
            onClick={() => setOpenShareEventDialog(true)}
          >
            Partager l’évènement
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          p: 4,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            border: '2px solid',
            borderColor: 'primary.main',
            p: 4,
            borderRadius: '20px',
            alignItems: 'center',
            flexGrow: 1
          }}
        >
          <Grid2 container spacing={0}>
            <Grid2 size={6}>
              <Box sx={{ flexGrow: 0.5 }}>
                <Typography variant="h6" sx={{ color: 'primary.main' }}>
                  Détails du ticket
                </Typography>
                <Typography sx={{ fontSize: 12, fontWeight: 'bold' }} color="text.secondary">
                  Date :
                </Typography>
                {eventData.created_at && (
                  <Typography variant="body2" sx={{ color: 'primary.main' }} gutterBottom>
                    {new Date(eventData.created_at).toLocaleDateString('fr-FR') +
                      ' à ' +
                      new Date(eventData.created_at).toLocaleTimeString('fr-FR')}
                  </Typography>
                )}
                <Typography sx={{ fontSize: 12, fontWeight: 'bold' }} color="text.secondary">
                  Description :
                </Typography>
                <Typography variant="body2" sx={{ color: 'primary.main' }} gutterBottom>
                  {eventData.desc}
                </Typography>
              </Box>
            </Grid2>
            <Grid2 size={6}>
              <Box sx={{ flexGrow: 0.5 }}>
                {eventData.data && eventData.data.contact_requestor && (
                  <>
                    <Typography sx={{ fontSize: 12, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                      Déclarant :
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'primary.main' }}>
                      {eventData.data.contact_requestor.name}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'primary.main' }}>
                      {eventData.data.contact_requestor.phone}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'primary.main' }} gutterBottom>
                      {eventData.data.contact_requestor.email}
                    </Typography>
                  </>
                )}

                {userName && (
                  <>
                    <Typography sx={{ fontSize: 12, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                      Déclarant :
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'primary.main' }}>
                      {userName}
                    </Typography>
                  </>
                )}
                <Box style={{ paddingTop: '10px' }}>
                  <Button
                    onClick={() => {
                      setSelectedUUIDEvent(eventData.uuid);
                      setWizzardEventCreationMode(false);
                      setWizzardEventOpen(true);
                    }}
                    color="primary"
                    size="medium"
                    startIcon={<RemoveRedEyeOutlinedIcon />}
                    variant="contained"
                  >
                    Localisation 3D
                  </Button>
                </Box>
              </Box>
            </Grid2>
          </Grid2>
          <Grid2 container sx={{ marginTop: '10px' }}>
            {eventData.files && <>{generateImages()}</>}
          </Grid2>
        </Box>
      </Box>

      <Grid2 container>
        <Grid2 size={12} className="Events__detail-event" id="detail-event">
          <Box className="Events__lineBlock__title">{'Gestion du ticket'}</Box>
          <Grid2 container spacing={2}>
            {dataForm?.status && (
              <Grid2 size={{ xs: 3 }}>
                <FormControl sx={{ marginTop: '20px' }} fullWidth>
                  <InputLabel id="eventType">{'Statut'}</InputLabel>
                  <Select
                    labelId="eventType"
                    label="Statut"
                    value={Number(dataForm?.status)}
                    fullWidth
                    onChange={(e) => {
                      setDataForm({ ...dataForm, status: Number(e.target.value) });
                    }}
                  >
                    <MenuItem value={2}>A qualifier</MenuItem>
                    <MenuItem value={3}>En cours</MenuItem>
                    <MenuItem value={4}>Terminé</MenuItem>
                    <MenuItem value={5}>Archivé</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>
            )}

            {
              <Grid2 size={{ xs: 7 }}>
                <FormControl sx={{ marginTop: '20px' }} fullWidth>
                  <InputLabel id="kind">{'Type de ticket'}</InputLabel>
                  <Select
                    labelId="kind"
                    label="Type de ticket"
                    fullWidth
                    value={dataForm.kind !== 0 ? dataForm.kind : null}
                    onChange={(e) => {
                      setDataForm({ ...dataForm, kind: Number(e.target.value) });
                    }}
                  >
                    {buildRefEventTypesValues()}
                  </Select>
                </FormControl>
              </Grid2>
            }
            <Grid2 size={{ xs: 2 }}>
              <FormControlLabel
                label="Urgent"
                control={
                  <Checkbox
                    checked={dataForm.urgent}
                    color={'primary'}
                    onChange={(e) => {
                      setDataForm({ ...dataForm, urgent: e.target.checked });
                    }}
                  />
                }
                sx={{ marginTop: '20px' }}
              />
            </Grid2>
          </Grid2>

          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 'grow' }} sx={{ pt: '15px' }}>
              <div id="detail-event">
                <Box className="Events__lineBlock__title" sx={{ flexGrow: 1, pb: '10px' }}>
                  {'Observations'}
                </Box>

                {messagesList}

                <FormControl
                  sx={{ marginTop: '20px' }}
                  fullWidth
                  onSubmit={() => {
                    handleSubmit(observation);
                  }}
                >
                  <TextField
                    sx={{ paddingBottom: '20px', width: 'auto' }}
                    id="outlined-multiline-static"
                    label="Nouvelle observation"
                    multiline
                    rows={4}
                    value={observation}
                    onChange={(e) => setObservation(e.target.value)}
                  />
                  {observation !== '' && (
                    <Button
                      type="submit"
                      color={'primary'}
                      variant="contained"
                      onClick={() => {
                        handleSubmit(observation);
                      }}
                    >
                      Créer observation
                    </Button>
                  )}
                </FormControl>
              </div>
              <br />
              <br />
            </Grid2>
          </Grid2>

          <Grid2 size={{ xs: 'grow' }}>
            <Accordion defaultExpanded={true} disableGutters={true}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Box className="Events__lineBlock__title" sx={{ flexGrow: 1 }}>
                  {'Associer des équipements'}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {eventData && (
                  <EquipmentsList
                    idEvent={idEvent}
                    assignMode={true}
                    data={eventData}
                    precheckList={eventData.equipments}
                    setWizzardEquipmentOpen={setWizzardEquipmentOpen}
                    setWizzardEquipmentCreationMode={setWizzardEquipmentCreationMode}
                    wizzardEquipmentCreationMode={wizzardEquipmentCreationMode}
                    setSelectedUUIDEquipment={setSelectedUUIDEquipment}
                    refreshRequired={refreshRequired}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </Grid2>

          <br />
        </Grid2>
      </Grid2>
      {openShareEventDialog && (
        <EventShareDialog
          onClose={() => setOpenShareEventDialog(false)}
          open={openShareEventDialog}
          eventData={eventData}
        />
      )}
    </div>
  );
};

export default EventDetail;
