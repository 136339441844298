import { Grid2, Button } from '@mui/material';
import LocalisationBloc from '../SubComponents/LocalisationBloc';
import { useContext, useEffect, useState } from 'react';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

const SearchAddressStep = ({
  setCurrentStep,
  setTitle,
  ademeDatas,
  setAdemeDatas,
  googlePlaceDatas,
  setGooglePlaceDatas
}) => {
  const mediaQueries = useContext(MediaQueryContext);
  const [resetSearchField, setResetSearchField] = useState('');

  useEffect(() => {
    setTitle('Où est situé votre bien ?');
  }, []);

  useEffect(() => {
    if (ademeDatas) {
      setCurrentStep(4);
    }
  }, [ademeDatas]);
  useEffect(() => {
    if (googlePlaceDatas) {
      setCurrentStep(4);
    }
  }, [googlePlaceDatas]);

  return (
    <Grid2 container justifyContent={'center'}>
      <Grid2 size={12}>
        <Grid2 container spacing={3} justifyContent={'center'}>
          <LocalisationBloc
            searchType={'ademe'}
            title={'En saisissant le numéro Ademe du DPE'}
            text={'Nous utiliserons alors les données officielles'}
            resetSearchField={resetSearchField}
            setResetSearchField={setResetSearchField}
            setAdemeDatas={setAdemeDatas}
          ></LocalisationBloc>

          <LocalisationBloc
            searchType={'google-places'}
            title={'En saisissant l’adresse postale'}
            text={"C'est plus long"}
            resetSearchField={resetSearchField}
            setResetSearchField={setResetSearchField}
            setGooglePlaceDatas={setGooglePlaceDatas}
          ></LocalisationBloc>
        </Grid2>

        <Grid2
          container
          spacing={3}
          justifyContent={'center'}
          sx={{
            mt: '20px',
            position: 'sticky', // Rend les boutons "collants"
            bottom: 0, // Position en bas de la fenêtre si sticky s'applique
            bgcolor: 'white', // Fond blanc pour couvrir le contenu en dessous
            zIndex: 10, // S'assure que les boutons sont au premier plan
            boxShadow: '0 -2px 5px rgba(0,0,0,0)', // Ajoute une ombre douce si besoin
            pb: 2 // Ajoute du padding vertical pour éviter une hauteur trop compacte
          }}
        >
          <Grid2 size={12} sx={{ pt: '30px', textAlign: 'center' }}>
            <Button
              fullWidth={mediaQueries.matchesMd ? false : true}
              color={'primary'}
              sx={{ textDecoration: 'underline' }}
              onClick={() => {
                setCurrentStep(2);
              }}
            >
              {'Retour'}
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default SearchAddressStep;
