import { useContext, useEffect } from 'react';
import { Button, Grid2 } from '@mui/material';
import { api } from '~/api';
import { useNavigate } from 'react-router-dom';
import ProductItem from '../SubComponents/ProductItem';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

const ValidationStep = ({ setTitle, setCurrentStep, productDatas, setProductDatas, setModalOpen, dpe }) => {
  const navigate = useNavigate();
  const mediaQueries = useContext(MediaQueryContext); // Accès aux media queries depuis le contexte
  const closeWizzard = (gotoProductPage = false) => {
    if (productDatas.uuid && productDatas.uuid !== '') {
      api.product.update(productDatas.uuid, { is_draft: false }).then((response) => {
        console.log(response);
        if (dpe && dpe.uuid) {
          api.ademe.assign(productDatas.uuid, dpe.uuid, {}).then((response) => {
            console.log('response assign', response);
          });
        }
        setModalOpen(false);
        if (gotoProductPage) {
          navigate(`/product/${productDatas.uuid}`);
        }
      });
    }
  };

  useEffect(() => {
    setTitle('Voilà ! Et maintenant :');
  }, []);

  return (
    <>
      <Grid2 container spacing={3} justifyContent={'center'}>
        <Grid2 size={{ xs: 6, md: 4, lg: 2 }}>
          <ProductItem
            id={15}
            logoId={15}
            name={'Voir la fiche du bien'}
            setSelectedProduct={() => {
              closeWizzard(true);
            }}
          />
        </Grid2>
        <Grid2 size={12}>
          <Grid2
            container
            spacing={3}
            justifyContent={'center'}
            sx={{
              mt: '20px',
              position: 'sticky', // Rend les boutons "collants"
              bottom: 0, // Position en bas de la fenêtre si sticky s'applique
              bgcolor: 'white', // Fond blanc pour couvrir le contenu en dessous
              zIndex: 10, // S'assure que les boutons sont au premier plan
              boxShadow: '0 -2px 5px rgba(0,0,0,0)', // Ajoute une ombre douce si besoin
              pb: 2 // Ajoute du padding vertical pour éviter une hauteur trop compacte
            }}
          >
            <Grid2 size={12} sx={{ pt: '30px', textAlign: 'center' }}>
              <Button
                fullWidth={mediaQueries.matchesMd ? false : true}
                //   variant={'outlined'}
                color={'primary'}
                sx={{ textDecoration: 'underline' }}
                onClick={() => {
                  closeWizzard(false);
                }}
              >
                {'Fermer'}
              </Button>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
    </>
  );
};

export default ValidationStep;
