import { Box, Typography } from '@mui/material';
import { renderEquipmentIcon } from '~/utils/references';
import { appTheme } from '~/utils/theme';
import _ from 'lodash';

export const EquipmentSubTitle = ({ generateSubtitleEquipment, equipmentData, equipmentReferencial }) => {
  const displayIcon = () => {
    if (equipmentData.category_kind === null) {
      if (equipmentData.tab === 'inventoryEquipments') {
        return renderEquipmentIcon(18, true, 'white');
      } else {
        return renderEquipmentIcon(
          _.find(equipmentReferencial.equipmentCategoryKinds, {
            id:
              _.find(equipmentReferencial.equipments, { id: equipmentData.kind }) &&
              _.find(equipmentReferencial.equipments, { id: equipmentData.kind }).kinds.length > 0
                ? _.find(equipmentReferencial.equipments, { id: equipmentData.kind }).kinds[0]
                : null
          })
            ? _.find(equipmentReferencial.equipmentCategoryKinds, {
                id:
                  _.find(equipmentReferencial.equipments, { id: equipmentData.kind }) &&
                  _.find(equipmentReferencial.equipments, { id: equipmentData.kind }).kinds.length > 0
                    ? _.find(equipmentReferencial.equipments, { id: equipmentData.kind }).kinds[0]
                    : null
              }).id
            : 0,
          true,
          'white'
        );
      }
    } else {
      return renderEquipmentIcon(equipmentData.category_kind, true, 'white');
    }
  };

  return (
    <div
      style={{
        paddingBottom: '0px',
        paddingTop: '5px',
        paddingLeft: '10px',
        backgroundColor: appTheme.palette.primary.main
      }}
    >
      <Box sx={{ mr: 2 }} style={{ fontSize: '25px', display: 'inline-block' }}>
        {displayIcon()}
      </Box>

      <Box style={{ display: 'inline-block' }}>
        <Typography
          variant="h4"
          sx={{ color: 'primary.main', lineHeight: 1 }}
          style={{ position: 'relative', bottom: '12.5px', color: 'white' }}
        >
          {generateSubtitleEquipment(equipmentData)}
        </Typography>
      </Box>
    </div>
  );
};
