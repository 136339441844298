import React, { useContext } from 'react';
import { Box, Grid2, Typography } from '@mui/material';
import { StepsViewer } from '~/app/components/StepsViewer/StepsViewer';

import { WizzardMissionOrder } from '~/app/components/WizzardMissionOrder/WizzardMissionOrder';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

export const WizzardMissionOrderForm = ({ spinnerOpen, setSpinnerOpen, handleClose, productData, scanOrder }) => {
  const mediaQueries = useContext(MediaQueryContext);
  const [activeStep, setActiveStep] = React.useState(0);

  const steps = ['Choix des missions', 'Informations nécessaires', 'Informations pratiques'];

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={11}>
        <Typography variant={mediaQueries?.matchesMd ? 'h1' : 'h2'} sx={{ color: 'primary.main' }}>
          Nouvel ordre de service
        </Typography>
      </Grid2>
      <Grid2 size={{ xs: 11, md: 3 }}>
        <Box sx={{ maxWidth: 400 }}>
          <StepsViewer steps={steps} currentStep={activeStep} />
        </Box>
      </Grid2>
      <Grid2 size={{ xs: 11, md: 7 }} sx={{ ml: mediaQueries?.matchesMd ? '5vw' : '0' }}>
        <WizzardMissionOrder
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          product={productData ? productData : null}
          closeModal={handleClose}
          scanOrder={scanOrder}
        />
      </Grid2>
    </Grid2>
  );
};
