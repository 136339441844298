import { Box, Typography } from '@mui/material';
import { appTheme } from '~/utils/theme';
import { productKindLogos } from '../ProductItemLogos/ProductItemLogos';
import { useContext } from 'react';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

export const ProductTitle = ({ data }) => {
  const mediaQueries = useContext(MediaQueryContext);
  const showSituation = (data) => {
    const situation = [];

    if (data.address?.building_name && data.address?.building_name !== '') {
      situation.push(data.address?.building_name);
    }

    if (data.address_complement && data.address_complement !== '') {
      situation.push(data.address_complement);
    }

    return (
      <Typography variant="h5" sx={{ color: appTheme.palette.primary.main, fontWeight: 500 }}>
        {situation.join(' - ')}
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        mt: 2,
        width: 1,
        display: 'flex',
        alignItems: 'flex-start'
      }}
    >
      <Box sx={{ mr: 2 }}>{productKindLogos(mediaQueries.matchesMd ? 32 : 20, data.kind)}</Box>
      <Box sx={{ flexGrow: 1, mb: 1 }}>
        {data.address && data.address.street && (
          <Typography
            variant={
              mediaQueries.matchesXl ? 'h1' : mediaQueries.matchesLg ? 'h2' : mediaQueries.matchesMd ? 'h3' : 'h4'
            }
            sx={{ color: appTheme.palette.primary.main, lineHeight: 1 }}
          >
            {data.address.street}, {data.address.zip_code} {data.address.city}
          </Typography>
        )}

        {showSituation(data)}
      </Box>
    </Box>
  );
};
