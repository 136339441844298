import { Box, Typography } from '@mui/material';

import { productKindLogos } from '../../ProductItemLogos/ProductItemLogos';

export const ProductItem = ({ id, logoId, name, setSelectedProduct }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 'auto', // largeur de la carte
        height: 100, // hauteur de la carte
        border: '1px solid #000', // bordure grise
        borderRadius: '8px', // coins arrondis
        backgroundColor: '#fff', // fond blanc
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)', // légère ombre
        pl: '16px', // padding à gauche
        pt: '16px', // padding en haut
        cursor: 'pointer', // curseur pointer,
        ':hover': {
          backgroundColor: '#F1F1F1'
        }
      }}
      onClick={() => setSelectedProduct(id)}
    >
      <div style={{ paddingBottom: '16px' }}>{productKindLogos(32, logoId)}</div>

      <Typography variant="body1" sx={{ lineHeight: 1, fontWeight: 'bold', fontSize: 20 }}>
        {name}
      </Typography>
    </Box>
  );
};

export default ProductItem;
