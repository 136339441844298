import { EditOutlined } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  IconButton,
  Link,
  TextField,
  Typography
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { api } from '~/api';
import { AppContext } from '~/utils/context/AppContext';
import { lotsIcons } from '~/utils/references';
import { appTheme } from '~/utils/theme';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

export const LotsList = ({ lots, setProductDatas }) => {
  const { referencial } = useContext(AppContext);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editableLotId, setEditableLotId] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const [suppressMode, setSuppressMode] = useState(false);

  const onClose = () => {
    setEditModalOpen(false);
  };

  useEffect(() => {
    if (lots === null || lots.length === 0) return;
    setHasMore(lots.length > 3);
  }, [lots]);

  return (
    <>
      {lots && lots.length > 0 && (
        <Grid2 container spacing={0}>
          <Grid2 size={12}>
            <Typography variant="h6" sx={{ pt: '10px', pb: '10px' }}>
              Lots
            </Typography>
          </Grid2>
          <Grid2 size={12}>
            <Grid2 container spacing={0}>
              {lots.map((lot, index) => (
                <Grid2 key={lot.uuid} style={{ display: 'contents' }}>
                  {(index < 3 || !showMore) && (
                    <Grid2 size={4} key={lot.uuid}>
                      <Grid2 container spacing={1}>
                        <Grid2>{lotsIcons(22, lot.kind)}</Grid2>
                        <Grid2 size={{ xs: 'grow' }}>
                          <Grid2 container spacing={0.5}>
                            <Grid2 size={{ xs: 'grow' }} sx={{ display: 'inline-flex' }}>
                              <Typography>
                                {lot.kind === 1
                                  ? lot.name
                                  : referencial['lots.kind']?.find(
                                      (lotFromReferencial) => lot.kind === lotFromReferencial.id
                                    ).name}
                              </Typography>
                              <Link
                                className="Product__link"
                                onClick={() => {
                                  setEditableLotId(lot);
                                  setEditModalOpen(true);
                                }}
                                color="primary"
                                sx={{ marginLeft: '5px', position: 'relative', top: '2px' }}
                              >
                                <EditOutlined />
                              </Link>
                            </Grid2>
                            <Grid2 size={12}>
                              <Typography sx={{ color: 'grey', fontSize: '12px', position: 'relative', top: '-10px' }}>
                                {lot.desc}
                              </Typography>
                            </Grid2>
                          </Grid2>
                        </Grid2>
                      </Grid2>
                    </Grid2>
                  )}
                </Grid2>
              ))}
            </Grid2>
          </Grid2>

          {hasMore && (
            <Grid2
              size={12}
              style={{ cursor: 'pointer', textAlign: 'right' }}
              onClick={() => {
                setShowMore(!showMore);
              }}
              sx={{
                '&:hover': {
                  color: appTheme.palette.secondary.main // Change this to your desired color
                }
              }}
            >
              {showMore ? 'Voir plus' : 'Voir moins'}
            </Grid2>
          )}

          {/* EDIT MODAL */}

          {editableLotId && (
            <Dialog onClose={onClose} open={editModalOpen} PaperProps={{ sx: { width: '500px' } }}>
              {suppressMode ? (
                <>
                  <DialogTitle>Suppresion du lot</DialogTitle>
                  <DialogContent>
                    <Typography>Êtes-vous sûr de vouloir supprimer ce lot ?</Typography>
                    <DialogActions>
                      <Button
                        color="primary"
                        onClick={() => {
                          setSuppressMode(false);
                        }}
                        variant="text"
                      >
                        Annuler
                      </Button>

                      <Button
                        color="primary"
                        onClick={() => {
                          api.lot.delete(editableLotId.uuid).then((res) => {
                            setProductDatas((prev) => {
                              const newLots = prev.lots?.filter((lot) => lot.uuid !== editableLotId.uuid);
                              return { ...prev, lots: newLots };
                            });
                            setEditModalOpen(false);
                          });
                        }}
                        variant="contained"
                      >
                        Supprimer
                      </Button>
                    </DialogActions>
                  </DialogContent>
                </>
              ) : (
                <>
                  <DialogTitle>Edition du lot</DialogTitle>
                  <div style={{ position: 'absolute', right: '10px', top: '10px' }}>
                    <IconButton
                      onClick={() => {
                        setSuppressMode(true);
                      }}
                      size="small"
                    >
                      <DeleteOutlineOutlinedIcon sx={{ color: 'text.primary' }} fontSize="small" />
                    </IconButton>
                  </div>
                  <DialogContent>
                    <Grid2 container>
                      <Grid2 size={12}>
                        <>
                          <span>{lotsIcons(22, editableLotId?.kind)}</span> &nbsp; &nbsp;
                          <span style={{ position: 'relative', top: '-5px' }}>
                            {editableLotId?.kind === 1
                              ? editableLotId.name
                              : referencial['lots.kind'].find(
                                  (referencialLot) => referencialLot.id === editableLotId?.kind
                                )?.name}
                          </span>
                        </>
                      </Grid2>

                      {editableLotId.kind === 1 && (
                        <Grid2 size={12} sx={{ marginTop: '10px' }}>
                          <TextField
                            fullWidth
                            autoComplete="off"
                            size="small"
                            placeholder="Nom "
                            value={editableLotId.name}
                            onChange={(e) => {
                              setEditableLotId({ ...editableLotId, name: e.target.value });
                            }}
                          ></TextField>
                        </Grid2>
                      )}

                      <Grid2 size={12} sx={{ marginTop: '10px' }}>
                        <TextField
                          fullWidth
                          autoComplete="off"
                          size="small"
                          placeholder="Description"
                          value={editableLotId.desc}
                          onChange={(e) => {
                            setEditableLotId({ ...editableLotId, desc: e.target.value });
                          }}
                        ></TextField>
                      </Grid2>
                    </Grid2>
                    <DialogActions>
                      <Button
                        color="primary"
                        onClick={() => {
                          setEditModalOpen(false);
                        }}
                        variant="text"
                      >
                        Annuler
                      </Button>

                      <Button
                        color="primary"
                        onClick={() => {
                          api.lot.update(editableLotId.uuid, editableLotId).then((res) => {
                            setProductDatas((prev) => {
                              const newLots = prev.lots.map((lot) => {
                                if (lot.uuid === editableLotId.uuid) {
                                  return editableLotId;
                                }
                                return lot;
                              });
                              return { ...prev, lots: newLots };
                            });
                            setEditModalOpen(false);
                          });
                        }}
                        variant="contained"
                      >
                        Enregistrer
                      </Button>
                    </DialogActions>
                  </DialogContent>
                </>
              )}
            </Dialog>
          )}
        </Grid2>
      )}
    </>
  );
};
