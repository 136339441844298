import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { appTheme } from '~/utils/theme';
import { Box, Grid2, Typography, Checkbox, FormControl, InputLabel, Select, InputBase } from '@mui/material';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';
import { useContext } from 'react';

const ListFilter = ({
  handleChangeTypeFilter = null,
  buildRefEventTypesValues = null,
  handleChangeSearchFilter,
  search,
  walletChecked = null,
  handleChangeWalletChecked = null,
  closedChecked = null,
  handleChangeClosedChecked = null,
  archivedChecked = null,
  handleChangeArchivedChecked = null,
  productEventsLayout = false
}) => {
  const mediaQueries = useContext(MediaQueryContext); // Accès aux media queries depuis le contexte

  return (
    <Box
      sx={
        handleChangeTypeFilter && buildRefEventTypesValues
          ? {
              pt: 2,
              pb: 1,
              width: '100%'
            }
          : {}
      }
    >
      <Grid2 container alignItems="flex-end">
        <Grid2 size={12}>
          <Box sx={{ flex: 1, mb: 2, width: 1 }}>
            <Grid2 container>
              <Grid2 size={12} sx={{ display: 'flex', alignItems: 'center', mb: '1vh' }}></Grid2>
              <Grid2 size={12}>
                <Box sx={{ flexGrow: 3, mr: 2 }}>
                  <div
                    style={{
                      alignItems: 'center',
                      backgroundColor: appTheme.palette.primary[50],
                      border: `1px solid`,
                      borderColor: appTheme.palette.primary[100],
                      borderRadius: '20px',
                      display: 'flex',
                      height: '36px',
                      fontSize: '16px',
                      width: '100%'
                    }}
                  >
                    <InputBase
                      autoComplete="off"
                      placeholder="Rechercher"
                      onChange={handleChangeSearchFilter}
                      value={search}
                      inputProps={{
                        sx: {
                          pl: 1.8,
                          '&::placeholder': {}
                        }
                      }}
                      sx={{ flex: 1 }}
                    />
                    <SearchOutlinedIcon
                      sx={{
                        color: 'primary.main',
                        ml: 2,
                        mr: 1
                      }}
                      fontSize="medium"
                    />
                  </div>
                </Box>
              </Grid2>
              <Grid2 size={12}>
                <Grid2 container sx={{ mt: '1vh' }}>
                  {handleChangeTypeFilter && buildRefEventTypesValues && (
                    <Grid2 size={3} sx={{ display: 'flex', alignItems: 'center', mb: '1vh' }}>
                      <>
                        <Box sx={{ mr: 2 }}>
                          <Typography variant="body1" sx={{ color: 'primary.main' }}>
                            Filtrer:
                          </Typography>
                        </Box>

                        <Box sx={{ flexGrow: 1, mr: 2 }}>
                          <FormControl fullWidth>
                            <InputLabel id="userType">Type de ticket</InputLabel>
                            <Select
                              labelId="userType"
                              label="Type de ticket"
                              defaultValue=""
                              onChange={handleChangeTypeFilter}
                            >
                              {buildRefEventTypesValues()}
                            </Select>
                          </FormControl>
                        </Box>
                      </>
                    </Grid2>
                  )}
                  <Box>
                    <Typography variant="body1" sx={{ color: 'primary.main' }}>
                      {!productEventsLayout && handleChangeWalletChecked && (
                        <>
                          <Checkbox
                            checked={walletChecked}
                            onChange={handleChangeWalletChecked}
                            id="myEvents"
                            sx={{
                              '& .MuiSvgIcon-root': { fontSize: !mediaQueries.matchesMd ? 18 : 22 },
                              ml: !mediaQueries.matchesMd ? '10px' : '20px'
                            }}
                          />
                          <label htmlFor="myEvents">Mes tickets</label>
                        </>
                      )}
                      {handleChangeClosedChecked && (
                        <>
                          <Checkbox
                            checked={closedChecked}
                            onChange={handleChangeClosedChecked}
                            id="closedEvents"
                            sx={{
                              '& .MuiSvgIcon-root': { fontSize: !mediaQueries.matchesMd ? 18 : 22 },
                              ml: !mediaQueries.matchesMd ? '10px' : '20px'
                            }}
                          />
                          <label htmlFor="closedEvents">Tickets terminés</label>
                        </>
                      )}
                      {handleChangeArchivedChecked && (
                        <>
                          <Checkbox
                            checked={archivedChecked}
                            onChange={handleChangeArchivedChecked}
                            id="archivedEvents"
                            sx={{
                              '& .MuiSvgIcon-root': { fontSize: !mediaQueries.matchesMd ? 18 : 22 },
                              ml: !mediaQueries.matchesMd ? '10px' : '20px'
                            }}
                          />
                          <label htmlFor="archivedEvents">Tickets archivés</label>
                        </>
                      )}
                    </Typography>
                  </Box>
                </Grid2>
              </Grid2>
            </Grid2>
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default ListFilter;
