import {
  Grid2,
  Accordion,
  AccordionSummary,
  Box,
  AccordionDetails,
  Autocomplete,
  TextField,
  Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';
import auth from '~/app/components/Auth/AuthApi';
import { getContactsOrganization } from '~/api';
import { useParams } from 'react-router-dom';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { WizzardContact } from '~/app/components/WizzardContact/WizzardContact';
import { api } from '~/api';

export const LinkContactForm = ({ documentData, setDocumentData, documentTypeLayout }) => {
  type TuseParams = {
    id: string;
  };
  const { id } = useParams<TuseParams>();
  const [allContacts, setAllContacts] = useState([]);
  const [updateContact, setUpdateContact] = useState(true);
  const [autocompleteComponents, setAutocompleteComponents] = useState([{ id: 0, value: null }]);
  const [addContact, setAddContact] = useState<boolean>(false);
  const [linkedContacts, setLinkedContacts] = useState([]);
  const [updateLinkContact, setUpdateLinkContact] = useState(true);

  useEffect(() => {
    const claims = auth.getJWT();
    if (updateContact) {
      getContactsOrganization(claims.uuid_organization)
        .then((orgcontacts) => {
          const ctcs = orgcontacts.filter((contact) => {
            return contact.uuid_owner !== id;
          });
          setAllContacts(ctcs);
          setUpdateContact(false);
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          setUpdateContact(false);
        });
    }
  }, [updateContact]);

  useEffect(() => {
    if (updateLinkContact) {
      api.files.contactLink
        .get(documentData.uuid)
        .then((resp) => {
          setLinkedContacts(resp);
          if (!resp) {
            setAutocompleteComponents([{ id: 0, value: null }]);
          } else {
            const components = resp.map((contact) => {
              return { id: contact.uuid, value: contact };
            });
            setAutocompleteComponents(components);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          setUpdateLinkContact(false);
        });
    }
  }, [updateLinkContact]);

  const handleContactChange = (index, event, contact) => {
    const selectedUUID = contact?.uuid;
    const selectedContact = allContacts.find((contact) => contact.uuid === selectedUUID);
    const updatedComponents = [...autocompleteComponents];

    if (selectedContact && selectedContact.uuid) {
      api.files.contactLink.create(documentData.uuid, selectedContact.uuid).then(() => {
        api.files.contactLink
          .get(documentData.uuid)
          .then((resp) => {
            setLinkedContacts(resp);
            setUpdateLinkContact(true);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } else if (contact === null) {
      handleRemoveAutocomplete(index);
    }
    if (updatedComponents[index].value !== null) {
      handleRemoveAutocomplete(index);
    }
    setAutocompleteComponents(updatedComponents);
  };

  const handleDeleteContact = (contactId) => {
    api.files.contactLink
      .delete(documentData.uuid, contactId)
      .then((resp) => {
        const updatedLinkedContacts = linkedContacts.filter((linkedContact) => linkedContact.uuid !== contactId);
        setLinkedContacts(updatedLinkedContacts);
        setUpdateLinkContact(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddAutocomplete = () => {
    const lastId = autocompleteComponents[autocompleteComponents.length - 1].id;
    setAutocompleteComponents([...autocompleteComponents, { id: lastId + 1, value: null }]);
  };

  const handleRemoveAutocomplete = (index) => {
    const removedComponent = autocompleteComponents[index];
    if (autocompleteComponents.length <= 1) {
      removedComponent && handleDeleteContact(removedComponent.id);
      return;
    }

    if (removedComponent) {
      handleDeleteContact(removedComponent.id);
    }

    const updatedComponents = [...autocompleteComponents];
    updatedComponents.splice(index, 1);
    setAutocompleteComponents(updatedComponents);
  };

  return (
    <div style={{ paddingBottom: '15px' }}>
      {documentData && documentTypeLayout && documentTypeLayout.needContact && (
        <Grid2 size={12} style={{ paddingTop: '5px', paddingBottom: '25px' }}>
          <Accordion defaultExpanded={false} disableGutters={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Box className="Events__lineBlock__title" sx={{ flexGrow: 1 }}>
                {'Associer des contacts'}
              </Box>
            </AccordionSummary>
            {autocompleteComponents.length > 0 && (
              <AccordionDetails style={{ display: 'flex', flexDirection: 'column', marginLeft: '30px' }}>
                {autocompleteComponents.map((component, index) => (
                  <div key={component.id} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                    <Autocomplete
                      options={allContacts}
                      value={component.value}
                      onChange={(event, contact) => handleContactChange(index, event, contact)}
                      getOptionLabel={(option) => option.name}
                      style={{ flex: 1, maxWidth: '200px' }}
                      renderInput={(params) => <TextField {...params} label={`Contact`} variant="outlined" />}
                    />
                    {index === autocompleteComponents.length - 1 ? (
                      <Button onClick={handleAddAutocomplete} disabled={!component.value}>
                        <PersonAddIcon />
                      </Button>
                    ) : (
                      <Button onClick={() => handleRemoveAutocomplete(index)}>
                        <PersonRemoveIcon />
                      </Button>
                    )}
                  </div>
                ))}
              </AccordionDetails>
            )}
            <div className="Contacts__lineBlock" style={{ display: 'flex', justifyContent: 'center' }}>
              <div className="Profil__link" onClick={() => setAddContact(!addContact)}>
                <AddOutlinedIcon /> Créer un nouveau contact
              </div>
            </div>
            <br />
          </Accordion>
        </Grid2>
      )}
      {addContact && (
        <WizzardContact
          onClose={() => {
            setAddContact(false);
            setUpdateContact(true);
          }}
          open={addContact}
          isNewContact={true}
          contacts={allContacts}
          isProductPage={true}
        />
      )}
    </div>
  );
};
