import { appTheme } from '~/utils/theme';
import { Grid2, Button } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import './ZonePanel.scss';

export const ZonePanel = ({
  zonePanelOpen,
  matterport_scan_areas,
  currentZoneId,
  setCurrentSweepArea,
  setCurrentZoneId,
  setZonePanelOpen
}) => {
  return (
    <div
      className="zones_container"
      style={{
        top: zonePanelOpen ? '0px' : '-38px'
      }}
    >
      <Grid2 container spacing={0} justifyContent="center" alignItems="center">
        {matterport_scan_areas &&
          Object.keys(matterport_scan_areas).map((key, index) => {
            return (
              <Grid2 sx={{ margin: '0px 10px' }} key={index}>
                <Button
                  size="small"
                  sx={{ color: currentZoneId !== key ? 'primary' : 'secondary' }}
                  color={currentZoneId !== key ? 'primary' : 'primary'}
                  variant={currentZoneId !== key ? 'text' : 'contained'}
                  onClick={() => {
                    setCurrentZoneId(key);
                    setCurrentSweepArea(matterport_scan_areas[key].sweeps);
                    const iframe = document.getElementById('matterport-frame') as HTMLIFrameElement;
                    const iframeCanvas = iframe.contentWindow.document.getElementsByClassName(
                      'webgl-canvas'
                    )[0] as HTMLCanvasElement;
                    iframeCanvas.focus();
                  }}
                >
                  {key === 'default' ? 'Défaut' : key}
                </Button>
              </Grid2>
            );
          })}
      </Grid2>
      <div style={{ textAlign: 'center' }}>
        <div
          style={{
            borderRadius: '0px 0px 10px 10px',
            display: 'inline-block',
            backgroundColor: !zonePanelOpen ? appTheme.palette.primary.main : `rgba(255, 255, 255, 0.5)`,
            top: '5px',
            position: 'relative',
            height: '11px',
            width: '44px'
          }}
        >
          <div
            style={{
              position: 'relative',
              top: '-7px',
              cursor: 'pointer',
              color: !zonePanelOpen ? '#fff' : appTheme.palette.primary.main
            }}
            onClick={() => {
              setZonePanelOpen(!zonePanelOpen);
            }}
          >
            {!zonePanelOpen && <ArrowDropDownIcon />}
            {zonePanelOpen && <ArrowDropUpIcon />}
          </div>
        </div>
      </div>
    </div>
  );
};
