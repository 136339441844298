// import { Attribution } from '@mui/icons-material';
import { Suspense, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ProductList } from '~/app/pages/ProductList/ProductList';
import DefaultLayout from '~/utils/DefaultLayout/DefaultLayout';
import Auth from '~/utils/context/Auth';
import { Attribution } from '~/app/pages/Attribution/Attribution';
import AuthHandler from '~/app/pages/AuthHandler/AuthHandler';
import { Contacts } from '~/app/pages/Contacts/Contacts';
import { Equipments } from '~/app/pages/Equipments/Equipments';
import { Organizations } from '~/app/pages/Organizations/Organizations';
import { Events } from '~/app/pages/Events/Events';
import { Product } from '~/app/pages/Product/Product';
import { Profil } from '~/app/pages/Profil/Profil';
import { Settings } from '~/app/pages/Settings/Settings';
import { AdemeSearch } from './pages/AdemeSearch/AdemeSearch';
import { MissionOrder } from './pages/MissionOrder/MissionOrder';
import { Dashboard } from './pages/Dashboard/Dashboard';

export default function AppRouter() {
  const LoadingMessage = () => <div>Loading..,</div>;
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [spinnerOpen, setSpinnerOpen] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const value = { isAuthenticated, setIsAuthenticated };

  return (
    <Auth.Provider value={value}>
      <BrowserRouter>
        <Suspense fallback={<LoadingMessage />}>
          <DefaultLayout isSuperAdmin={isSuperAdmin} setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen}>
            <Routes>
              {/* AUTH Routes test */}
              <Route
                path="/register"
                element={<AuthHandler setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} type={'register'} />}
              />
              <Route
                path="/login"
                element={<AuthHandler setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} type={'login'} />}
              />
              <Route
                path="/forgot-password"
                element={
                  <AuthHandler setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} type={'forgot-password'} />
                }
              />
              <Route
                path="/delete-account"
                element={
                  <AuthHandler setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} type={'delete-account'} />
                }
              />
              {/* Other Routes */}
              <Route path="/" element={<ProductList setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />} />
              <Route
                path="/mission_order"
                element={<MissionOrder setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />}
              />
              <Route
                path="/dashboard"
                element={<Dashboard setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />}
              />
              <Route
                path="/contacts"
                element={<Contacts setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />}
              />
              <Route
                path="/dpe_search"
                element={<AdemeSearch setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />}
              />
              <Route
                path="/events"
                element={<Events opened={true} setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />}
              />
              <Route
                path="/events/:idEvent"
                element={<Events opened={true} setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />}
              />
              <Route
                path="/organizations"
                element={<Organizations setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />}
              />
              <Route
                path="/product/:id"
                element={<Product setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />}
              />
              <Route
                path="/product/:id/equipments"
                element={<Equipments setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />}
              />
              <Route
                path="/equipments/:idEquipment"
                element={<Equipments setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />}
              />
              <Route
                path="/product/:id/events"
                element={<Events opened={false} setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />}
              />
              <Route
                path="/product/:id/events/:idEvent"
                element={<Events opened={false} setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />}
              />
              <Route path="/profil" element={<Profil setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />} />
              <Route
                path="/share/:id"
                element={<AuthHandler setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} type={'share'} />}
              />
              <Route
                path="/settings"
                element={<Settings setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />}
              />
            </Routes>
          </DefaultLayout>
        </Suspense>
      </BrowserRouter>
    </Auth.Provider>
  );
}
