import { Box, Divider, Grid2, Typography } from '@mui/material';
import { useContext } from 'react';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

export const PresentationBloc = ({ number, title, text }) => {
  const mediaQueries = useContext(MediaQueryContext);
  return (
    <Grid2 size={{ xs: 12, md: 4 }}>
      <Box
        sx={{
          padding: '16px',
          backgroundColor: '#fff',
          borderRadius: '8px',
          boxShadow: mediaQueries.matchesMd ? '0px 1px 3px rgba(0, 0, 0, 0.2)' : ''
        }}
      >
        <Grid2 container sx={{ color: '#062746' }}>
          <Grid2>
            <Typography variant="h4" sx={{ minWidth: '25px', fontWeight: 'bold', color: '#000', lineHeight: '1.2' }}>
              {number}
            </Typography>
          </Grid2>
          <Grid2>
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#000', lineHeight: '1.2' }}>
              {title}
            </Typography>
          </Grid2>
        </Grid2>

        <Grid2 container>
          <Grid2>
            <Typography variant="h4" sx={{ minWidth: '25px', fontWeight: 'bold', color: '#000' }}></Typography>
          </Grid2>
          <Grid2>
            <Typography sx={{ marginTop: '8px', fontSize: '16px', color: '#929292' }}>{text}</Typography>
          </Grid2>
        </Grid2>

        {!mediaQueries.matchesMd && <Divider sx={{ marginTop: '16px' }} />}
      </Box>
    </Grid2>
  );
};

export default PresentationBloc;
