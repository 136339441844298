import { TextField, InputAdornment } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useEffect, useState } from 'react';

const AddressSearchField = ({ resetSearchField, setResetSearchField, setGooglePlaceDatas }) => {
  const [adressValue, setAdressValue] = useState('');
  const [placeSelected, setPlaceSelected] = useState(null);

  const resetPlaceSearch = () => {
    setAdressValue('');
    setPlaceSelected(null);
  };

  const handleChangeAdressSearch = (e) => {
    setAdressValue(e.target.value);
  };
  const keyPressAdress = (e) => {
    if (e.keyCode == 13) {
    }
    setResetSearchField('ademe');
  };

  useEffect(() => {
    if (resetSearchField === 'google-places') {
      setAdressValue('');
      setPlaceSelected(null);
      setResetSearchField('');
    }
  }, [resetSearchField]);

  useEffect(() => {
    enablePlacesSearch();
  }, []);

  const enablePlacesSearch = () => {
    const input = document.getElementById('autocomplete-input');
    if (input === null) {
      return;
    }
    const autocomplete = new window.google.maps.places.Autocomplete(input as HTMLInputElement, {
      types: ['geocode']
    });
    window.google.maps.event.addListener(autocomplete, 'place_changed', function () {
      const place = autocomplete.getPlace();
      if (place !== undefined && place.address_components) {
        setPlaceSelected(place);
        setAdressValue(place.formatted_address);
        setGooglePlaceDatas(place);

        let streetNumber = null;
        let name = null;
        let zip_code = null;
        let city = null;
        let country = null;
        for (var i = 0; i < place.address_components.length; i++) {
          for (var j = 0; j < place.address_components[i].types.length; j++) {
            if (place.address_components[i].types[j] == 'postal_code') {
              zip_code = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[j] == 'locality') {
              city = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[j] == 'country') {
              country = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[j] == 'route') {
              name = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[j] == 'street_number') {
              streetNumber = place.address_components[i].long_name;
            }
          }
        }

        var latitude = place.geometry.location.lat();
        var longitude = place.geometry.location.lng();

        console.log(streetNumber + ' ' + name, city, zip_code, country, latitude, longitude);
      }
    });
  };
  return (
    <TextField
      fullWidth
      label="Adresse du bien"
      id="autocomplete-input"
      autoComplete="off"
      onKeyUp={keyPressAdress}
      value={adressValue}
      onChange={handleChangeAdressSearch}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            {!placeSelected && (
              <svg width="23" height="32" viewBox="0 0 23 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.2 0C5.008 0 0 5.008 0 11.2C0 19.6 11.2 32 11.2 32C11.2 32 22.4 19.6 22.4 11.2C22.4 5.008 17.392 0 11.2 0ZM3.2 11.2C3.2 6.784 6.784 3.2 11.2 3.2C15.616 3.2 19.2 6.784 19.2 11.2C19.2 15.808 14.592 22.704 11.2 27.008C7.872 22.736 3.2 15.76 3.2 11.2Z"
                  fill="black"
                />
                <path
                  d="M11.2 15.2C13.4091 15.2 15.2 13.4091 15.2 11.2C15.2 8.99086 13.4091 7.2 11.2 7.2C8.99086 7.2 7.2 8.99086 7.2 11.2C7.2 13.4091 8.99086 15.2 11.2 15.2Z"
                  fill="black"
                />
              </svg>
            )}
            {placeSelected && <HighlightOffIcon onClick={resetPlaceSearch}></HighlightOffIcon>}
          </InputAdornment>
        ),
        style: {
          paddingRight: 0
        }
      }}
    ></TextField>
  );
};

export default AddressSearchField;
