import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid2,
  Stack,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Link
} from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerAccount } from '~/api';
import { checkEmail, checkPassword } from '~/utils/check';
import { setSession } from '../AuthApi';
import Auth from '~/utils/context/Auth';
import '../Auth.scss';
import { loadStripe } from '@stripe/stripe-js/pure';
import { MuiTelInput } from 'mui-tel-input';
import { CountryCode } from 'libphonenumber-js';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useLoadScript } from '@react-google-maps/api';

interface IRegister {
  compagny_name: string;
  street: string;
  zip_code: string;
  city: string;
  legal_number: string;
  vat_number: string;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  phone1: string;
  phone2: string;
  organization_kind: number;
  plan_kind: number;
  payment_method_id: string;
}
loadStripe.setLoadParameters({ advancedFraudSignals: false });

export const Register = ({ setSpinnerOpen, spinnerOpen }) => {
  const navigate = useNavigate();
  const initData = {
    compagny_name: '',
    street: '',
    zip_code: '',
    city: '',
    legal_number: '',
    vat_number: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    phone1: '',
    phone2: '',
    organization_kind: 1,
    plan_kind: 1,
    payment_method_id: ''
  };
  const [dataForm, setDataForm] = useState<IRegister>(initData);
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [submit, setSubmit] = useState<boolean>(false);
  const [emailAlreadyUsed, setEmailAlreadyUsed] = useState<boolean>(false);
  const [checkboxCGV, setCheckboxCGV] = useState<boolean>(false);
  const { setIsAuthenticated } = useContext(Auth);
  const [paymentStep, setPaymentStep] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<CountryCode>('FR');
  const [countryCode2, setCountryCode2] = useState<CountryCode>('FR');
  const { REACT_APP_STRIPE_API_KEY } = process.env;
  const [stripePromise, setStripePromise] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ['places']
  });

  const handleChangeCheckboxCGV = (event) => {
    setCheckboxCGV(event.target.checked);
  };

  const handleSubmitForm = () => {
    setSubmit(true);
    setEmailAlreadyUsed(false);
    if (
      dataForm.street &&
      dataForm.zip_code &&
      dataForm.city &&
      dataForm.first_name &&
      dataForm.last_name &&
      dataForm.phone1 &&
      // dataForm.plan_kind &&
      // dataForm.payment_method_id &&
      checkEmail(dataForm.email) &&
      checkPassword(dataForm.password)
    ) {
      registerAccount(dataForm)
        .then((resp) => {
          setSession(resp);
          setIsAuthenticated(true);
          navigate('/');
        })
        .catch((error) => {});
    }
  };

  useEffect(() => {
    setSpinnerOpen(false);
  }, []);

  useEffect(() => {
    if (paymentStep) {
      loadStripe(REACT_APP_STRIPE_API_KEY).then((stripe) => {
        setStripePromise(stripe);
      });
    }
  }, [paymentStep]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const enablePlacesSearch = () => {
    const input = document.getElementById('autocomplete-input');
    if (input === null) return;

    const autocomplete = new window.google.maps.places.Autocomplete(input as HTMLInputElement, {
      types: ['geocode']
    });

    window.google.maps.event.addListener(autocomplete, 'place_changed', function () {
      const place = autocomplete.getPlace();
      if (place !== undefined && place.address_components) {
        let streetNumber = '';
        let route = '';
        let zip_code = '';
        let city = '';

        place.address_components.forEach((component) => {
          const types = component.types;
          if (types.includes('street_number')) streetNumber = component.long_name;
          if (types.includes('route')) route = component.long_name;
          if (types.includes('postal_code')) zip_code = component.long_name;
          if (types.includes('locality')) city = component.long_name;
        });

        const street = `${streetNumber} ${route}`;
        setDataForm((prev) => ({
          ...prev,
          street,
          zip_code,
          city
        }));
      }
    });
  };

  useEffect(() => {
    if (isLoaded) {
      enablePlacesSearch();
    }
  }, [isLoaded]);

  const keyPressAdress = (e) => {
    if (e.keyCode === 13) {
    }
  };

  return (
    <div className="Auth">
      <div className="Auth__header">
        <Box sx={{ mt: 4, mb: 4 }}>
          <img src="/logo3D.svg" height="50" alt="logo" />
        </Box>
        <Typography variant="h5" color={'primary'} sx={{ mb: 3 }}>
          Inscription
        </Typography>
      </div>

      {!paymentStep && (
        <>
          <div className="Auth__hr">
            <Typography variant="body1" color={'primary'} sx={{ mb: 2, textAlign: 'center' }}>
              Société
            </Typography>
            <Grid2 container spacing={1.5}>
              <Grid2 size={12}>
                <TextField
                  label="Nom de l'entreprise"
                  fullWidth
                  required
                  error={submit && dataForm.compagny_name.length === 0}
                  helperText={submit ? (dataForm.compagny_name.length !== 0 ? '' : 'Nom non valide') : ''}
                  value={dataForm.compagny_name}
                  onChange={(e) => setDataForm({ ...dataForm, compagny_name: e.target.value })}
                />
              </Grid2>
              <Grid2 size={12}>
                <TextField
                  id="autocomplete-input"
                  label="Adresse"
                  fullWidth
                  onKeyUp={keyPressAdress}
                  required
                  error={submit && dataForm.street.length === 0}
                  helperText={submit ? (dataForm.street.length !== 0 ? '' : 'Adresse non valide') : ''}
                  value={dataForm.street}
                  onChange={(e) => setDataForm({ ...dataForm, street: e.target.value })}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 3 }}>
                <TextField
                  label="Code postal"
                  fullWidth
                  required
                  error={submit && dataForm.zip_code.length === 0}
                  helperText={submit ? (dataForm.zip_code.length !== 0 ? '' : 'zip non valide') : ''}
                  value={dataForm.zip_code}
                  onChange={(e) => setDataForm({ ...dataForm, zip_code: e.target.value })}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 9 }}>
                <TextField
                  label="Ville"
                  fullWidth
                  required
                  error={submit && dataForm.city.length === 0}
                  helperText={submit ? (dataForm.city.length !== 0 ? '' : 'Ville non valide') : ''}
                  value={dataForm.city}
                  onChange={(e) => setDataForm({ ...dataForm, city: e.target.value })}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <TextField
                  label="SIREN"
                  fullWidth
                  required={false}
                  value={dataForm.legal_number}
                  onChange={(e) => setDataForm({ ...dataForm, legal_number: e.target.value })}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <TextField
                  label="N° TVA"
                  fullWidth
                  required={false}
                  value={dataForm.vat_number}
                  onChange={(e) => setDataForm({ ...dataForm, vat_number: e.target.value })}
                />
              </Grid2>
            </Grid2>
            <hr />
          </div>
          <Grid2 container spacing={1.5}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                label="Prénom"
                fullWidth
                required
                error={submit && dataForm.first_name.length === 0}
                helperText={submit ? (dataForm.first_name.length !== 0 ? '' : 'Nom non valide') : ''}
                value={dataForm.first_name}
                onChange={(e) => setDataForm({ ...dataForm, first_name: e.target.value })}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                label="Nom"
                fullWidth
                required
                error={submit && dataForm.last_name.length === 0}
                helperText={submit ? (dataForm.last_name.length !== 0 ? '' : 'Nom non valide') : ''}
                value={dataForm.last_name}
                onChange={(e) => setDataForm({ ...dataForm, last_name: e.target.value })}
              />
            </Grid2>
            <Grid2 size={12}>
              <TextField
                label="Adresse email"
                fullWidth
                required
                error={(submit && !checkEmail(dataForm.email)) || emailAlreadyUsed}
                helperText={
                  emailAlreadyUsed
                    ? 'Email déjà utilisé'
                    : submit
                      ? checkEmail(dataForm.email)
                        ? ''
                        : 'Adresse email non valide'
                      : ''
                }
                type={'email'}
                value={dataForm.email}
                onChange={(e) => setDataForm({ ...dataForm, email: e.target.value })}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <TextField
                label="Mot de passe"
                fullWidth
                required
                type={showPassword ? 'text' : 'password'}
                error={submit && !checkPassword(dataForm.password)}
                helperText={submit ? (checkPassword(dataForm.password) ? '' : 'Mot de passe non valide') : ''}
                value={dataForm.password}
                onChange={(e) => setDataForm({ ...dataForm, password: e.target.value })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <MuiTelInput
                label="Numéro de téléphone"
                fullWidth
                required
                error={submit && dataForm.phone1.length === 0}
                helperText={submit ? (dataForm.phone1.length !== 0 ? '' : 'Téléphone non valide') : ''}
                value={dataForm.phone1}
                onChange={(value, info) => setDataForm({ ...dataForm, phone1: info.numberValue })}
                defaultCountry={countryCode}
                forceCallingCode
                continents={['EU']}
              />
            </Grid2>
            <Grid2 size={12}>
              <Divider sx={{ my: 2 }}></Divider>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 1 }}>
              <FormControlLabel control={<Checkbox onChange={handleChangeCheckboxCGV} />} label="" />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 11 }}>
              Je certifie avoir lu et accepté{' '}
              <Link href="mentions-legales-cgv-cgu.pdf" target="_blanc" className="Auth__linkCGV">
                les Conditions générales d’utilisation et de vente
              </Link>{' '}
              du site ainsi que la
              <Link href="politique-confidentialite-audit-virtuel-052024.pdf" target="_blanc" className="Auth__linkCGV">
                {' '}
                Politique de Confidentialité
              </Link>
            </Grid2>
          </Grid2>

          <Stack className="Auth__submit">
            <Button
              disabled={!checkboxCGV}
              variant="contained"
              size="medium"
              color={'primary'}
              onClick={handleSubmitForm}
              sx={{ mb: 2, px: '32px', py: '12px' }}
            >
              Valider
            </Button>
          </Stack>
          <Stack className="Auth__hr">
            <Divider sx={{ my: 2 }}>ou</Divider>
            <Button
              variant="outlined"
              color={'primary'}
              sx={{ mb: 2, px: '32px', py: '12px' }}
              onClick={() => {
                setSpinnerOpen(true);
                navigate('/login');
              }}
            >
              Se connecter
            </Button>
          </Stack>
        </>
      )}
      {/* {paymentStep && (
        <Elements stripe={stripePromise}>
          <PaymentForm setFormData={setDataForm} dataForm={dataForm} submitForm={handleSubmitForm} />
        </Elements>
      )} */}
    </div>
  );
};

export default Register;
