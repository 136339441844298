import { Button, DialogActions, FormControl, Grid2, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import '~/app/components/WizzardEquipment/WizzardEquipment';
import { energyClassRef, periodicityRef } from '~/utils/references';
import dayjs from 'dayjs';

export const MoreInfos = ({
  elementDatas,
  nextStepCallBack,
  hasNextStep,
  validateNewElement,
  moreInfosDatas,
  setMoreInfosDatas,
  wizzardCreationMode,
  stepToReturn
}) => {
  const updateDatas = (e, isInDataObject, key) => {
    const newDatas = JSON.parse(JSON.stringify(moreInfosDatas));
    if (isInDataObject) {
      if (newDatas.data === undefined || newDatas.data === null) newDatas.data = {};
      newDatas.data[key] = e;
    } else {
      newDatas[key] = e;
    }
    setMoreInfosDatas(newDatas);
  };

  const handlePatchEquipment = () => {
    validateNewElement();
  };

  const energyClassList = Object.entries(energyClassRef).map(([key, value]) => {
    return <MenuItem value={key}>{value}</MenuItem>;
  });

  const periodicityList = Object.entries(periodicityRef).map(([key, value]) => {
    return <MenuItem value={Number(key)}>{value}</MenuItem>;
  });

  return (
    <Grid2 container spacing={0}>
      <Grid2 size={12}>
        <div className="Wizzards__contentTitle Wizzards__center">Caractéristiques avancées de l'équipement</div>
      </Grid2>
      <Grid2 size={12}>
        <div className="Wizzards__form_item">
          <div style={{ width: '75%', height: '100%', margin: 'auto' }}>
            <Grid2 container spacing={2} direction="row" justifyContent={'center'} alignContent="center">
              <Grid2 size={6}>
                <TextField
                  label="Marque"
                  fullWidth
                  value={moreInfosDatas?.data?.model_brand}
                  onChange={(e) => updateDatas(e.target.value, true, 'model_brand')}
                  autoComplete="off"
                />
              </Grid2>
              <Grid2 size={6}>
                <TextField
                  fullWidth
                  label="Modèle"
                  value={moreInfosDatas?.data?.model_id}
                  onChange={(e) => updateDatas(e.target.value, true, 'model_id')}
                  autoComplete="off"
                />
              </Grid2>
              <Grid2 size={6}>
                <TextField
                  fullWidth
                  label="Numéro de série"
                  value={moreInfosDatas?.data?.model_serial_number}
                  onChange={(e) => updateDatas(e.target.value, true, 'model_serial_number')}
                  autoComplete="off"
                />
              </Grid2>
              <Grid2 size={6}>
                <FormControl fullWidth>
                  <InputLabel id="energy">Energie</InputLabel>
                  <Select
                    labelId="energy"
                    label="Energie"
                    value={moreInfosDatas.energy_class ? moreInfosDatas.energy_class : ''}
                    onChange={(e) => updateDatas(Number(e.target.value), false, 'energy_class')}
                  >
                    {energyClassList}
                  </Select>
                </FormControl>
              </Grid2>
              <Grid2 size={6}>
                <TextField
                  fullWidth
                  label="Point de livraison"
                  value={moreInfosDatas?.data?.meter_pmr}
                  onChange={(e) => updateDatas(e.target.value, true, 'meter_pmr')}
                  autoComplete="off"
                />
              </Grid2>

              <Grid2 size={6}>
                <TextField
                  fullWidth
                  label="Caractéristiques techniques"
                  value={moreInfosDatas?.data?.tech_specs}
                  onChange={(e) => updateDatas(e.target.value, true, 'tech_specs')}
                  autoComplete="off"
                />
              </Grid2>

              <Grid2 size={6}>
                <FormControl fullWidth>
                  <DesktopDatePicker
                    label="Date d'installation"
                    value={moreInfosDatas.installed_at ? dayjs(moreInfosDatas.installed_at) : null}
                    onChange={(e) => updateDatas(e, false, 'installed_at')}
                  />
                </FormControl>
              </Grid2>

              <Grid2 size={6}>
                <FormControl fullWidth>
                  <InputLabel id="periodicity">Périodicité de la maintenance</InputLabel>
                  <Select
                    labelId="periodicity"
                    label="Périodicité de la maintenance"
                    value={moreInfosDatas.maintenance_periodicity ? moreInfosDatas.maintenance_periodicity : ''}
                    onChange={(e) => updateDatas(Number(e.target.value), false, 'maintenance_periodicity')}
                  >
                    {periodicityList}
                  </Select>
                </FormControl>
              </Grid2>

              <Grid2 size={6}>
                <FormControl fullWidth>
                  <DesktopDatePicker
                    label="Date de fin de garantie"
                    value={moreInfosDatas.warranty_end ? dayjs(moreInfosDatas.warranty_end) : null}
                    onChange={(e) => updateDatas(e, false, 'warranty_end')}
                  />
                </FormControl>
              </Grid2>

              <Grid2 size={6}>
                <FormControl fullWidth>
                  <DesktopDatePicker
                    label="Date de prochaine intervention"
                    value={moreInfosDatas.maintenance_next_date ? dayjs(moreInfosDatas.maintenance_next_date) : null}
                    onChange={(e) => updateDatas(e, false, 'maintenance_next_date')}
                  />
                </FormControl>
              </Grid2>

              <Grid2 size={12}>
                <TextField
                  fullWidth
                  label="Informations complémetaires"
                  value={moreInfosDatas?.data?.more_infos}
                  onChange={(e) => updateDatas(e.target.value, true, 'more_infos')}
                  autoComplete="off"
                  multiline
                  rows={4}
                />
              </Grid2>
            </Grid2>
          </div>
        </div>
      </Grid2>
      <Grid2 size={12}>
        <div className="Wizzards__center Wizzards__form_item">
          <DialogActions sx={{ justifyContent: 'center' }}>
            {wizzardCreationMode && stepToReturn === '' && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  nextStepCallBack('moreInfos', false, true);
                }}
              >
                Précédent
              </Button>
            )}
            <Button
              disabled={
                elementDatas.kind !== null && elementDatas.kind !== undefined && elementDatas.kind !== '' ? false : true
              }
              variant="contained"
              color="primary"
              onClick={() => {
                nextStepCallBack('moreInfos');
              }}
            >
              {hasNextStep || stepToReturn !== '' ? 'Suivant' : 'Enregistrer'}
            </Button>
          </DialogActions>
        </div>
      </Grid2>
    </Grid2>
  );
};

export default MoreInfos;
