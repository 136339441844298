import {
  Button,
  FormControl,
  Grid2,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import { useContext, useEffect } from 'react';
import { AppContext } from '~/utils/context/AppContext';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

const PrincipalInformations = ({ setTitle, setCurrentStep, productDatas, setProductDatas }) => {
  const mediaQueries = useContext(MediaQueryContext);
  const { referencial } = useContext(AppContext);
  useEffect(() => {
    setTitle('Renseignez les informations principale du bien ');
  }, []);
  return (
    <>
      <Grid2 size={12} sx={{ textAlign: 'center' }}>
        <Typography variant={mediaQueries.matchesSm ? 'h5' : 'h6'} sx={{ lineHeight: '1.3' }}>
          {'Vous pourrez compléter ou ajouter d’autres informations plus tard, depuis la fiche du bien. '}
        </Typography>
      </Grid2>
      <Grid2 container spacing={0} justifyContent={'center'} sx={{ mt: '50px' }}>
        {mediaQueries.matchesMd && <Grid2 size={4} sx={{ textAlign: 'center' }}></Grid2>}
        <Grid2 size={{ xs: 12, md: 4 }}>
          <Grid2 container spacing={3} justifyContent={'center'} sx={{}}>
            <Grid2 size={12} sx={{ textAlign: 'center' }}>
              <TextField
                fullWidth
                autoComplete="off"
                label="Référence interne"
                size="small"
                value={productDatas?.id_internal}
                onChange={(e) => {
                  setProductDatas({
                    ...productDatas,
                    id_internal: e.target.value
                  });
                }}
              ></TextField>
            </Grid2>
            <Grid2 size={12} sx={{ textAlign: 'center' }}>
              <TextField
                fullWidth
                autoComplete="off"
                label="ID Modèle Matterport"
                size="small"
                value={productDatas?.matterport_scan?.model_id}
                onChange={(e) => {
                  setProductDatas({
                    ...productDatas,
                    matterport_scan: {
                      ...productDatas.matterport_scan,
                      model_id: e.target.value
                    }
                  });
                }}
              ></TextField>
            </Grid2>

            {/* SI Product.kind === appartement (partie privative d'immeuble) */}
            {productDatas?.kind === 1 && (
              <Grid2 size={12}>
                <FormControl fullWidth>
                  <InputLabel size="small" id="demo-simple-select-label">
                    Type de logement
                  </InputLabel>
                  <Select
                    defaultValue=""
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={productDatas?.surface?.logement_type ? productDatas?.surface?.logement_type : ''}
                    label="Type de logement"
                    onChange={(e) => {
                      setProductDatas({
                        ...productDatas,
                        surface: { ...productDatas.surface, logement_type: Number(e.target.value) }
                      });
                    }}
                    size="small"
                  >
                    {referencial['surface.kind'].map((type, index) => {
                      return (
                        <MenuItem key={index} value={index}>
                          {type}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid2>
            )}

            <Grid2 size={12} sx={{ textAlign: 'center' }}>
              <TextField
                type="number"
                fullWidth
                autoComplete="off"
                label="Surface habitable"
                size="small"
                value={
                  !productDatas?.surface?.living_space || productDatas?.surface?.living_space === 0
                    ? ''
                    : Math.floor(productDatas?.surface?.living_space * 100) / 100
                }
                onChange={(e) => {
                  setProductDatas({
                    ...productDatas,
                    surface: {
                      ...productDatas.surface,
                      living_space: Number(e.target.value)
                    }
                  });
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">m²</InputAdornment>
                }}
              ></TextField>
            </Grid2>

            <Grid2 size={12} sx={{ textAlign: 'center' }}>
              <TextField
                type="number"
                fullWidth
                autoComplete="off"
                label="Hauteur sous plafond"
                size="small"
                value={
                  productDatas?.surface?.ceiling_height === 0 ||
                  productDatas?.surface?.ceiling_height === null ||
                  productDatas?.surface?.ceiling_height === undefined
                    ? ''
                    : Math.floor(productDatas?.surface?.ceiling_height * 100) / 100
                }
                onChange={(e) => {
                  setProductDatas({
                    ...productDatas,
                    surface: {
                      ...productDatas.surface,
                      ceiling_height: Number(e.target.value)
                    }
                  });
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">m</InputAdornment>
                }}
              ></TextField>
            </Grid2>

            <Grid2 size={12} sx={{ textAlign: 'center' }}>
              <TextField
                fullWidth
                autoComplete="off"
                label="N° de lot (si applicable)"
                size="small"
                value={productDatas?.data?.lot_number}
                onChange={(e) => {
                  setProductDatas({
                    ...productDatas,
                    data: {
                      ...productDatas.data,
                      lot_number: e.target.value
                    }
                  });
                }}
              ></TextField>
            </Grid2>
            <Grid2 size={12} sx={{ textAlign: 'center' }}>
              <TextField
                fullWidth
                autoComplete="off"
                label="Lot(s) associé(s) (si applicable)"
                size="small"
                value={productDatas?.data?.lot_associated}
                onChange={(e) => {
                  setProductDatas({
                    ...productDatas,
                    data: {
                      ...productDatas.data,
                      lot_associated: e.target.value
                    }
                  });
                }}
              ></TextField>
            </Grid2>
          </Grid2>
        </Grid2>
        {mediaQueries.matchesMd && <Grid2 size={4} sx={{ textAlign: 'center' }}></Grid2>}
      </Grid2>
      <Grid2
        container
        spacing={3}
        justifyContent={'center'}
        sx={{
          mt: '20px',
          position: 'sticky', // Rend les boutons "collants"
          bottom: 0, // Position en bas de la fenêtre si sticky s'applique
          bgcolor: 'white', // Fond blanc pour couvrir le contenu en dessous
          zIndex: 10, // S'assure que les boutons sont au premier plan
          boxShadow: '0 -2px 5px rgba(0,0,0,0)', // Ajoute une ombre douce si besoin
          pb: 2 // Ajoute du padding vertical pour éviter une hauteur trop compacte
        }}
      >
        <Grid2 container spacing={3} justifyContent={'center'} sx={{ mt: '0px' }}>
          <Grid2 size={6} sx={{ textAlign: 'right' }}>
            <Button
              fullWidth={mediaQueries.matchesMd ? false : true}
              color={'primary'}
              sx={{ textDecoration: 'underline' }}
              onClick={() => {
                setCurrentStep(5);
              }}
            >
              {'Retour'}
            </Button>
          </Grid2>
          <Grid2 size={6} sx={{ textAlign: 'left' }}>
            <Button
              fullWidth={mediaQueries.matchesMd ? false : true}
              variant={'contained'}
              color={'primary'}
              sx={{ color: 'white' }}
              onClick={() => {
                setCurrentStep(7);
              }}
            >
              suivant
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </>
  );
};

export default PrincipalInformations;
