import { Grid2 } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import React, { ReactNode, useEffect } from 'react';
import Header from '~/app/components/Header/Header';
import auth from '~/app/components/Auth/AuthApi';

import './DefaultLayout.scss';

interface DefaultLayoutProps {
  children: ReactNode;
  isSuperAdmin: boolean;
  setSpinnerOpen: any;
  spinnerOpen: boolean;
}

export const DefaultLayout = ({ children, setSpinnerOpen, spinnerOpen }: DefaultLayoutProps) => {
  const [open, setOpen] = React.useState(spinnerOpen);
  useEffect(() => {
    setOpen(spinnerOpen);
  }, [spinnerOpen]);

  return (
    <div className="Layout">
      {auth.authenticated() && <Header setSpinnerOpen={setSpinnerOpen} spinnerOpen={spinnerOpen} />}
      <Container maxWidth="xl">
        <Grid2 container>{children}</Grid2>
      </Container>
      <Backdrop invisible={true} sx={{ color: 'primary.main', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default DefaultLayout;
