import { Grid2, Button, Link, Checkbox, FormControlLabel } from '@mui/material';
import { useEffect, useState } from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import _ from 'lodash';
import './EditZone.scss';
import { api } from '~/api';

export default function EditZone({
  currentSweepArea,
  setData,
  uuid_product,
  matterport_scan_areas,
  scan_area_id,
  setCurrentSweepArea,
  setCurrentZoneId,
  currentZoneId
}) {
  const [pathContext, setPathContext] = useState('');
  const [sweepArea, setSweepArea] = useState(
    matterport_scan_areas && matterport_scan_areas[scan_area_id] && matterport_scan_areas[scan_area_id]
      ? matterport_scan_areas[scan_area_id].sweeps
      : []
  );
  const [restrictive, setRestrictive] = useState(
    matterport_scan_areas && matterport_scan_areas[scan_area_id] && matterport_scan_areas[scan_area_id].restrictive
      ? matterport_scan_areas[scan_area_id].restrictive
      : false
  );
  const saveArea = (key) => {
    api[pathContext].get(uuid_product).then((res) => {
      const matterport_scan_areas = res.data.matterport_scan_areas ? res.data.matterport_scan_areas : {};
      matterport_scan_areas[key]
        ? (matterport_scan_areas[key].sweeps = sweepArea)
        : (matterport_scan_areas[key] = { sweeps: sweepArea });
      matterport_scan_areas[key].restrictive = restrictive;
      api[pathContext].update(uuid_product, { data: { ...res.data, matterport_scan_areas } }).then((res) => {
        setData(res);
      });
    });
  };

  const suppressArea = (key) => {
    api[pathContext].get(uuid_product).then((res) => {
      const matterport_scan_areas = res.data.matterport_scan_areas ? res.data.matterport_scan_areas : {};
      matterport_scan_areas[key] = { sweeps: [], restrictive: false };
      api[pathContext].update(uuid_product, { data: { ...res.data, matterport_scan_areas } }).then((res) => {
        setData(res);
        setSweepArea([]);
        setRestrictive(false);
        setCurrentSweepArea([]);
      });
    });
  };

  const [editionMode, setEditionMode] = useState(false);

  useEffect(() => {
    if (matterport_scan_areas && scan_area_id) {
      setRestrictive(
        matterport_scan_areas && matterport_scan_areas[scan_area_id] && matterport_scan_areas[scan_area_id].restrictive
          ? matterport_scan_areas[scan_area_id].restrictive
          : false
      );
    }
  }, [matterport_scan_areas, scan_area_id]);

  useEffect(() => {
    if (currentSweepArea && currentZoneId && scan_area_id && currentZoneId === scan_area_id) {
      setSweepArea(currentSweepArea);
    }
    if (currentZoneId && scan_area_id) {
      setEditionMode(currentZoneId === scan_area_id);
    }
  }, [currentSweepArea, currentZoneId, scan_area_id]);

  useEffect(() => {
    const pathname = window.location.pathname.split('/');
    if (pathname[1] === 'product' || (pathname[1] === 'events' && uuid_product.split('-')[0] === 'pro')) {
      setPathContext('product');
    } else if (pathname[1] === 'building' || (pathname[1] === 'events' && uuid_product.split('-')[0] === 'bui')) {
      setPathContext('building');
    }
  }, []);

  return (
    <div
      style={{
        marginBottom: currentZoneId === scan_area_id ? '10px' : '0px',
        border: currentZoneId === scan_area_id ? '1px solid #062746' : '',
        padding: currentZoneId === scan_area_id ? '10px' : '10px',
        borderRadius: '5px'
      }}
    >
      <Grid2 size={12} sx={{ paddingBottom: '10px' }}>
        <div
          style={{ cursor: 'pointer' }}
          className="BlockTitle"
          onClick={() => {
            setCurrentZoneId(scan_area_id);
            setCurrentSweepArea(sweepArea);
          }}
        >
          <div className="Product__lineBlock" style={{ marginBottom: '0px' }}>
            <div
              style={{ cursor: 'pointer', color: currentZoneId === scan_area_id ? '#F17633' : '#062746' }}
              className="Product__lineBlock__title"
            >
              {scan_area_id === 'default' ? 'Défaut' : scan_area_id}
            </div>

            {scan_area_id !== 'default' && (
              <Link className="Product__link" color="primary">
                <DeleteOutlineIcon
                  onClick={() => {
                    api[pathContext].get(uuid_product).then((res) => {
                      const matterport_scan_areas = res.data.matterport_scan_areas;
                      delete matterport_scan_areas[scan_area_id];
                      api[pathContext]
                        .update(uuid_product, { data: { ...res.data, matterport_scan_areas } })
                        .then((res) => {
                          setData(res);
                          setCurrentZoneId('default');
                        });
                    });
                  }}
                />
              </Link>
            )}
          </div>
        </div>
      </Grid2>
      {sweepArea && (
        <div>
          {editionMode && (
            <div style={{ paddingBottom: '10px' }}>
              {sweepArea && sweepArea.length > 0 && (
                <>
                  <div>{`${sweepArea.length} point${sweepArea.length > 1 ? 's' : ''}  de scan sélectionné${
                    sweepArea.length > 1 ? 's' : ''
                  }`}</div>
                </>
              )}

              {(!sweepArea || sweepArea.length < 1) && <div>Aucuns points séléctionnés</div>}
            </div>
          )}

          {editionMode && (
            <div>
              <Grid2 container spacing={0} justifyContent={'center'} alignItems={'center'}>
                <Grid2 size={12}>
                  {sweepArea && sweepArea.length > 0 && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => {
                            setRestrictive(!restrictive);
                          }}
                          checked={restrictive}
                        />
                      }
                      label="Restrictif"
                    />
                  )}
                </Grid2>
                <Grid2 size={6}>
                  {sweepArea && sweepArea.length > 0 && (
                    <Button
                      size="small"
                      color={'primary'}
                      variant="outlined"
                      onClick={() => {
                        suppressArea(scan_area_id);
                      }}
                    >
                      {' '}
                      Tout supprimer
                    </Button>
                  )}
                </Grid2>
                <Grid2 size={6}>
                  {(((!matterport_scan_areas ||
                    !matterport_scan_areas[scan_area_id] ||
                    !matterport_scan_areas[scan_area_id].sweeps) &&
                    sweepArea.length > 0) ||
                    (matterport_scan_areas &&
                      matterport_scan_areas[scan_area_id] &&
                      (_.difference(sweepArea, matterport_scan_areas[scan_area_id].sweeps).length > 0 ||
                        _.difference(matterport_scan_areas[scan_area_id].sweeps, sweepArea).length > 0 ||
                        restrictive !== matterport_scan_areas[scan_area_id].restrictive))) && (
                    <Button
                      size="small"
                      color={'primary'}
                      variant="contained"
                      onClick={() => {
                        saveArea(scan_area_id);
                      }}
                    >
                      Enregistrer
                    </Button>
                  )}
                </Grid2>
              </Grid2>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
