import { useEffect, useState } from 'react';
import BasicModal from '~/app/components/BasicModal/BasicModal';
import * as React from 'react';
import { api } from '~/api';
import { Button, Grid2 } from '@mui/material';
import { AppContext } from '~/utils/context/AppContext';
import { ProductTitle } from '../ProductTitle/ProductTitle';
import { characteristicsIcons } from '~/utils/references';
import { Checkbox } from '@mui/material';

export const EditCharacteristics = ({ productDatas, setCharacteristicsOpen, setPropertyData }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(true);
  const { referencial } = React.useContext(AppContext);

  const [areCharacteristicsChecked, setAreCharacteristicsChecked] = useState([]);

  const modalClosed = () => {
    setCharacteristicsOpen(false);
  };

  const handleChange = (id, event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setAreCharacteristicsChecked([...areCharacteristicsChecked, id]);
    } else {
      setAreCharacteristicsChecked(areCharacteristicsChecked.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    if (productDatas && productDatas.characteristics && productDatas.characteristics.length > 0) {
      setAreCharacteristicsChecked(productDatas.characteristics);
    }
  }, [productDatas]);

  // Regroup characteristics by category ID
  const groupedCharacteristics = referencial['product.characteristics']?.reduce(
    (acc: Record<string, any[]>, characteristic: { id: number; id_category?: string; name: string }) => {
      if (characteristic.id === 0) return acc; // Exclude characteristic with id 0
      const categoryId = characteristic.id_category || 'default'; // Use 'default' if no categoryId exists

      if (!acc[categoryId]) {
        acc[categoryId] = [];
      }

      // Push the characteristic into the correct category
      acc[categoryId].push(characteristic);

      // Sort the characteristics in this category by name
      acc[categoryId].sort((a, b) => a.name.localeCompare(b.name, 'fr', { sensitivity: 'base' }));

      return acc;
    },
    {}
  );

  const getCategoryName = (categoryId: number) => {
    const categoryName =
      referencial['product.characteristics-category']?.find((category) => category.id === categoryId)?.name ||
      'Sans catégorie';
    return categoryName;
  };

  return (
    <BasicModal
      modalOpen={modalOpen}
      onModalOpen={(open: boolean) => setModalOpen(open)}
      onModalClose={modalClosed}
      confirmClose={false}
    >
      <Grid2 container spacing={2}>
        <Grid2
          size={12}
          style={{
            backgroundColor: '#fff7f3',
            width: '100vw',
            paddingLeft: '15px',
            position: 'sticky',
            top: 0,
            zIndex: 1
          }}
        >
          <ProductTitle data={productDatas} />
        </Grid2>
        <Grid2 container spacing={2} sx={{ p: 2 }}>
          {groupedCharacteristics &&
            Object.entries(groupedCharacteristics).map(([categoryId, characteristics]) => (
              <Grid2 size={12} key={categoryId}>
                <h2>{getCategoryName(Number(categoryId))}</h2>

                <Grid2 container spacing={2}>
                  {(characteristics as { id: string; name: string }[])?.map((characteristic, index) => (
                    <Grid2 size={{ xs: 12, md: 6, lg: 4, xl: 3 }} key={index}>
                      <Grid2 container spacing={0}>
                        <Grid2 size={12}>
                          <Checkbox
                            sx={{ position: 'relative', left: '0px', bottom: '10px' }}
                            checked={areCharacteristicsChecked.includes(characteristic.id)}
                            onChange={(event) => handleChange(characteristic.id, event)}
                          ></Checkbox>
                          <span>{characteristicsIcons(32, characteristic.id, characteristic.name)} </span>
                          <span style={{ fontSize: '1em', position: 'relative', left: '10px', bottom: '10px' }}>
                            {characteristic.name}
                          </span>
                        </Grid2>
                      </Grid2>
                    </Grid2>
                  ))}
                </Grid2>
              </Grid2>
            ))}
        </Grid2>

        {/* Sticky Buttons */}
        <Grid2
          size={12}
          style={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: '#fff',
            padding: '16px 0',
            // boxShadow: '0 -2px 8px rgba(0, 0, 0, 0.1)',
            zIndex: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Button
            variant="outlined"
            style={{ marginRight: '16px' }}
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              api.product.update(productDatas.uuid, { characteristics: areCharacteristicsChecked }).then((response) => {
                setPropertyData(response);
                setModalOpen(false);
              });
            }}
          >
            Valider
          </Button>
        </Grid2>
      </Grid2>
    </BasicModal>
  );
};
