import { Grid2, Typography, FormControl, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { api } from '~/api';
import auth from '../../Auth/AuthApi';
import DeleteIcon from '@mui/icons-material/Delete';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

const CheckSameAddressExist = ({
  setCurrentStep,
  setTitle,
  address,
  setAdemeDatas,
  setGooglePlaceDatas,
  setAddressToAttach,
  setProductDatas,
  setSameBuildingsList
}) => {
  const [sameAddressFounded, setSameAddressFounded] = useState(null);
  const [attachedAddressIndex, setAttachedAddressIndex] = useState(null);
  const [attachedAddressDatas, setAttachedAddressDatas] = useState(null);
  const mediaQueries = useContext(MediaQueryContext);
  const checkIfElementSameAddress = async (street, city, zip_code, country, lat = null, long = null) => {
    try {
      const sameAdress = await api.address.buildingsProducts({
        street: street,
        city: city,
        zip_code: zip_code,
        country: country
      });
      setSameAddressFounded(sameAdress.addresses ? sameAdress.addresses : []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (attachedAddressDatas) {
      setAddressToAttach(attachedAddressDatas);
    } else {
      setAddressToAttach(null);
    }
  }, [attachedAddressDatas]);

  useEffect(() => {
    setSameBuildingsList(sameAddressFounded);
  }, [sameAddressFounded]);

  useEffect(() => {
    checkIfElementSameAddress(
      address.street,
      address.city,
      address.zip_code,
      address.country,
      address.lat,
      address.long
    );
  }, [address]);

  const selectAddressToAttach = (event: React.ChangeEvent<HTMLInputElement>) => {
    const split = (event.target as HTMLInputElement).value.split('-');
    if (split[0] === 'address') {
      setAttachedAddressDatas(sameAddressFounded[split[1]]);
    } else {
      setAttachedAddressDatas(null);
    }
    setAttachedAddressIndex((event.target as HTMLInputElement).value);
  };
  return (
    <>
      <Grid2 container spacing={3} justifyContent={'center'}>
        <Grid2 size={12} sx={{ textAlign: 'center' }}>
          {/* <Box sx={{ width: '100%', maxWidth: 400 }}> */}
          <Typography variant="h5" sx={{ mt: 1, textAlign: 'center', color: '#999999' }}>
            {address.street} {address.zip_code} {address.city}
          </Typography>
          {/* </Box> */}
        </Grid2>

        {sameAddressFounded && sameAddressFounded.length > 0 && (
          <Grid2 size={12} sx={{ textAlign: 'center' }}>
            <FormControl>
              <Typography sx={{ pt: '15px' }}>
                {`Nous avons trouvé ce${sameAddressFounded.length > 1 ? 's' : ''} bâtiment${
                  sameAddressFounded.length > 1 ? 's' : ''
                } à cette adresse, ${
                  sameAddressFounded.length > 1
                    ? "souhaitez vous y rattacher le nouveau bien à l'un d'entre eux ?"
                    : 'souhaitez vous y rattacher le nouveau bien ?'
                } `}
              </Typography>
              {auth.isSuperAdmin() && attachedAddressDatas && (
                <span>
                  <DeleteIcon
                    onClick={() => {
                      api.address.delete(attachedAddressDatas.uuid).then((res) => {
                        setAttachedAddressDatas(null);
                        setAttachedAddressIndex(null);
                        setSameAddressFounded((prev) => {
                          return prev.filter((address) => address.uuid !== attachedAddressDatas.uuid);
                        });
                      });
                    }}
                  />{' '}
                  Supprimer le bâtiment selectionné
                </span>
              )}
              <RadioGroup
                value={attachedAddressIndex}
                onChange={selectAddressToAttach}
                aria-labelledby="attachment-proposition"
                name="radio-buttons-group"
              >
                {sameAddressFounded.map((address, index) => {
                  return (
                    <FormControlLabel
                      key={`address-${index}`}
                      value={`address-${index}`}
                      control={<Radio size="small"></Radio>}
                      label={`${address?.building_name} - ${address.street} ${address.zip_code} ${address.city}`}
                      data-obj={address}
                      data-obj-type="address"
                    />
                  );
                })}
                <FormControlLabel
                  key={`New`}
                  value={`none`}
                  control={<Radio size="small" />}
                  label={`Non, je souhaite créer un nouveau bâtiment`}
                />
              </RadioGroup>
            </FormControl>
          </Grid2>
        )}
      </Grid2>
      <Grid2
        container
        spacing={3}
        justifyContent={'center'}
        sx={{
          mt: '20px',
          position: 'sticky', // Rend les boutons "collants"
          bottom: 0, // Position en bas de la fenêtre si sticky s'applique
          bgcolor: 'white', // Fond blanc pour couvrir le contenu en dessous
          zIndex: 10, // S'assure que les boutons sont au premier plan
          boxShadow: '0 -2px 5px rgba(0,0,0,0)', // Ajoute une ombre douce si besoin
          pb: 2 // Ajoute du padding vertical pour éviter une hauteur trop compacte
        }}
      >
        <Grid2 container spacing={3} justifyContent={'center'} sx={{ mt: '0px' }}>
          <Grid2 size={6} sx={{ textAlign: 'right' }}>
            <Button
              fullWidth={mediaQueries.matchesMd ? false : true}
              color={'primary'}
              sx={{ textDecoration: 'underline' }}
              onClick={() => {
                setAdemeDatas(null);
                setGooglePlaceDatas(null);
                setCurrentStep(3);
              }}
            >
              {'Retour'}
            </Button>
          </Grid2>
          <Grid2 size={6} sx={{ textAlign: 'left' }}>
            <Button
              disabled={
                sameAddressFounded && sameAddressFounded.length === 0 ? false : attachedAddressIndex ? false : true
              }
              fullWidth={mediaQueries.matchesMd ? false : true}
              variant={'contained'}
              color={'primary'}
              sx={{ color: 'white' }}
              onClick={() => {
                if (attachedAddressDatas) {
                  const address = JSON.parse(JSON.stringify(attachedAddressDatas));

                  if (address.building_name === null) {
                    address.building_name = 'Bâtiment unique';
                  }
                  setProductDatas((prev) => {
                    return {
                      ...prev,
                      uuid_address: attachedAddressDatas.uuid,
                      address: address ? address : ''
                    };
                  });
                } else {
                  setProductDatas((prev) => {
                    return {
                      ...prev,
                      address: {
                        street: address.street,
                        zip_code: address.zip_code,
                        city: address.city,
                        country: address.country,
                        building_name: address.building_name || 'Bâtiment unique',
                        building_access: address.building_access || ''
                      }
                    };
                  });
                }
                setCurrentStep(5);
              }}
            >
              suivant
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </>
  );
};

export default CheckSameAddressExist;
