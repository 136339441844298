import { Grid2, Typography, InputBase, Button, Box, Link, IconButton } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { ConfirmationDialog } from '~/app/components/ConfirmationDialog/ConfirmationDialog';
import { ContactRelKind, ContactRelKindToString } from '~/api/contact.models';
import { api } from '~/api';
import { WizzardContact } from '~/app/components/WizzardContact/WizzardContact';
import { ContactBase } from '~/api/contact.models';
import { useState, useEffect, useContext } from 'react';
import { appTheme } from '~/utils/theme';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

interface Contact extends ContactBase {
  kind: ContactRelKind;
}

const ProductContact: React.FC<{
  contacts: Contact[];
  productId: string;
  allContacts: ContactBase[];
  setUpdateContact: (updateContact: boolean) => void;
  setAllContacts: (allContacts: ContactBase[]) => void;
  propertyCreationContext?: boolean;
}> = ({ contacts, productId, allContacts, setUpdateContact, setAllContacts, propertyCreationContext = false }) => {
  const [filterValue, setFilterValue] = useState('');
  const [associateContacts, setAssociateContacts] = useState(false);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [contactToEdit, setContactToEdit] = useState<Contact | null>(null);
  const [editContact, setEditContact] = useState(false);
  const [pathContext, setPathContext] = useState('');
  const contactsPerRow = 2;
  const mediaQueries = useContext(MediaQueryContext);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleAssociateContact = () => {
    setAssociateContacts(!associateContacts);
  };

  const handleUnassignContact = (contact: Contact) => {
    api[pathContext].contact.unlinkTo(productId, contact.uuid, contact.kind).finally(() => {
      setUpdateContact(true);
    });
    setSelectedContact(null);
  };

  const filteredContacts =
    contacts && contacts.length > 0 && filterValue
      ? contacts.filter(
          (contact) =>
            contact.name.toLowerCase().includes(filterValue.toLowerCase()) ||
            ContactRelKindToString(contact.kind).toLowerCase().includes(filterValue.toLowerCase())
        )
      : contacts;

  const sortedContacts = filteredContacts
    ? filteredContacts.sort((a, b) => {
        const kindA = a.kind;
        const kindB = b.kind;
        if (kindA === ContactRelKind.Other || kindB === ContactRelKind.Other) {
          return kindA === ContactRelKind.Other ? 1 : -1;
        }
        return kindA - kindB;
      })
    : [];

  const contactRows = sortedContacts.reduce<Contact[][]>((rows, contact, index) => {
    if (index % contactsPerRow === 0) {
      rows.push([]);
    }
    rows[rows.length - 1].push(contact);
    return rows;
  }, []);

  useEffect(() => {
    setPathContext('product');
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {!propertyCreationContext && (
          <InputBase
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            placeholder="Rechercher un contact"
            autoComplete="off"
            inputProps={{
              sx: {
                '&::placeholder': {}
              }
            }}
            sx={{ flex: 1, visibility: contacts && contacts.length > 0 ? 'visible' : 'hidden' }}
          />
        )}
        {!mediaQueries.matchesMd ? (
          <Button
            color="secondary"
            variant="outlined"
            sx={{ color: 'primary', borderRadius: '20px', borderColor: 'secondary.main' }}
            onClick={handleAssociateContact}
          >
            <AddOutlinedIcon fontSize="small" />
            &nbsp;Contacts
          </Button>
        ) : (
          <Button
            color="secondary"
            variant="outlined"
            sx={{ color: 'primary', borderRadius: '20px', borderColor: 'secondary.main' }}
            onClick={handleAssociateContact}
          >
            <AddOutlinedIcon fontSize="small" />
            &nbsp; Associer un contact
          </Button>
        )}
      </div>
      <br />
      {contactRows.map((rowContacts, rowIndex) => (
        <Grid2 container spacing={2} key={rowIndex}>
          {rowContacts.map((contact) => (
            <Grid2 key={contact.uuid}>
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <span
                  onClick={() => {
                    setEditContact(true);
                    setContactToEdit(contact);
                  }}
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  <AccountCircleIcon
                    fontSize="large"
                    sx={{
                      color:
                        contact.kind === ContactRelKind.Owner
                          ? 'error.main'
                          : contact.kind === ContactRelKind.Tenant
                            ? 'info.main'
                            : contact.kind === ContactRelKind.Ocuppant
                              ? 'warning.main'
                              : contact.kind === ContactRelKind.SeviceProvider
                                ? 'success.main'
                                : 'secondary.main'
                    }}
                  />
                </span>
                <Box
                  sx={{
                    ml: 1,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    width: 'calc(100%)'
                  }}
                >
                  <div>
                    <Typography sx={{ color: 'primary.main', fontSize: '13px' }}>
                      {`${ContactRelKindToString(contact.kind)} : `}
                    </Typography>
                  </div>
                  <span>&nbsp;</span>
                  <div>
                    <Link
                      component="p"
                      variant="body2"
                      onClick={() => {
                        setEditContact(true);
                        setContactToEdit(contact);
                      }}
                      sx={{
                        color: 'primary.main',
                        justifyContent: 'flex-start',
                        wordBreak: 'break-word',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                        fontSize: '13px'
                      }}
                    >
                      {`${contact.name}`}
                    </Link>
                  </div>
                </Box>
                {isHovered ? (
                  <IconButton
                    onClick={() => {
                      setSelectedContact(contact);
                    }}
                  >
                    <LinkOffIcon
                      fontSize="small"
                      sx={{
                        position: 'absolute',
                        right: '0px',
                        '&:hover': { color: appTheme.palette.secondary.main },
                        paddingRight: '0px'
                      }}
                    />
                  </IconButton>
                ) : (
                  <IconButton>
                    <LinkOffIcon fontSize="small" sx={{ display: 'none' }} />
                  </IconButton>
                )}
              </div>
              <br />
            </Grid2>
          ))}
        </Grid2>
      ))}
      {editContact && (
        <WizzardContact
          open={editContact}
          onClose={() => {
            setEditContact(false);
            setUpdateContact(true);
          }}
          contact={contactToEdit}
          isProductPage={false}
          contacts={allContacts}
          setContacts={setAllContacts}
        />
      )}

      {associateContacts && (
        <WizzardContact
          open={associateContacts}
          contactsAlreadyAssociated={contacts}
          onClose={() => {
            setAssociateContacts(false);
          }}
          setUpdateContact={setUpdateContact}
          productId={productId}
          contacts={allContacts}
          isProductPage={true}
          setContacts={setAllContacts}
        />
      )}
      <ConfirmationDialog
        message={'Etes-vous certain de vouloir dé-associer ce contact du bien ?'}
        onCancel={() => setSelectedContact(null)}
        onConfirm={() => {
          handleUnassignContact(selectedContact);
        }}
        open={selectedContact !== null}
        title={'Dé-Association de contact'}
        variant={'error'}
      />
    </div>
  );
};

export default ProductContact;
