import { axiosPost, axiosGet, axiosPatch, axiosDelete } from '.';

export const createLease = (data: any) => {
  return axiosPost('/leases', data);
};

export const deleteLease = (id: string) => {
  return axiosDelete(`/leases/${id}`);
};

export const getLeases = (id: string) => {
  return axiosGet(`/leases/${id}`);
};

export const updateLease = (id: string, data: any) => {
  return axiosPatch(`/leases/${id}`, data);
};
