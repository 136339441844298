import { useContext, useEffect, useState } from 'react';
import { Grid2, Typography } from '@mui/material';
import { auth } from '~/app/components/Auth/AuthApi';
import { api } from '~/api';

import { DoughnutChart } from '../../components/DoughnutChart/DoughnutChart';
import { PiesChart } from '~/app/components/PiesChart/PiesChart';
import { ListStats } from '~/app/components/ListStats/ListStats';
import { DynamicDataGrid } from '~/app/components/DynamicDataGrid/DynamicDataGrid';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

export const Dashboard = ({ setSpinnerOpen, spinnerOpen }) => {
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [dpeStats, setDpeStats] = useState({});
  const [productsKindStats, setProductsKindStats] = useState({});
  const [usersEvents, setUsersEvents] = useState([]);
  const [allStats, setAllStats] = useState([]);

  const mediaQueries = useContext(MediaQueryContext);
  useEffect(() => {
    setIsSuperAdmin(auth.isSuperAdmin());
  }, []);

  useEffect(() => {
    api.stats.productsNumber().then((res) => {
      console.log('productNumber', res);
    });
    api.stats.productsDpe().then((res) => {
      const keys = Object.keys(res);
      const lastKey = keys[keys.length - 1];
      setDpeStats(res[lastKey]);
      console.log('productsDpe', res);
    });
    api.stats.productsKind().then((res) => {
      const keys = Object.keys(res);
      const lastKey = keys[keys.length - 1];
      setProductsKindStats(res[lastKey]);
      console.log('productsKind', res);
    });
    api.stats.usersEvents().then((res) => {
      const keys = Object.keys(res);
      const lastKey = keys[keys.length - 1];
      setUsersEvents(res[lastKey]);
      console.log('usersEvents', res[lastKey]);
    });
    api.stats.allStats().then((res) => {
      setAllStats(res);
      console.log('allStats', res);
      console.log('last stats', res[res.length - 1]);
    });
  }, []);

  const calculatePercentage = (value, total) => {
    const result = (value / total) * 100;
    return result.toFixed(0);
  };

  const labels = ['Jan', 'Fév', 'Mars', 'Avr', 'Mai', 'Jui', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'];
  const data1 = [30, 45, 28, 50, 66, 75, 80, 70, 60, 55, 40, 45];
  const data2 = [40, 30, 35, 45, 50, 55, 60, 65, 70, 75, 80, 85];
  const data3 = [70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120, 125];

  // const sampleData = [
  //   { id: 1, Collaborateurs: 'John', Portefeuille: 58, ['Tickets à traiter']: 2, ['Tickets en cours']: 3 },
  //   { id: 2, Collaborateurs: 'Pierre', Portefeuille: 58, ['Tickets à traiter']: 2, ['Tickets en cours']: 3 },
  //   { id: 3, Collaborateurs: 'Marc', Portefeuille: 58, ['Tickets à traiter']: 2, ['Tickets en cours']: 3 },
  //   { id: 4, Collaborateurs: 'Johnny', Portefeuille: 58, ['Tickets à traiter']: 2, ['Tickets en cours']: 3 },
  //   { id: 5, Collaborateurs: 'Test', Portefeuille: 58, ['Tickets à traiter']: 2, ['Tickets en cours']: 3 },
  //   { id: 6, Collaborateurs: 'Blabla', Portefeuille: 58, ['Tickets à traiter']: 2, ['Tickets en cours']: 3 },
  //   { id: 7, Collaborateurs: 'Jack', Portefeuille: 58, ['Tickets à traiter']: 2, ['Tickets en cours']: 3 },
  //   { id: 8, Collaborateurs: 'Jean', Portefeuille: 58, ['Tickets à traiter']: 2, ['Tickets en cours']: 3 }
  // ];

  useEffect(() => {
    setSpinnerOpen(false);
  }, []);

  return (
    <>
      {/* {isSuperAdmin && ( */}
      <Grid2 container spacing={2}>
        <Grid2 size={12}>
          <Typography
            variant={
              mediaQueries.matchesXl ? 'h1' : mediaQueries.matchesLg ? 'h2' : mediaQueries.matchesMd ? 'h3' : 'h4'
            }
            sx={{ color: 'primary.main', paddingTop: '15px' }}
          >
            Tableau de bord
          </Typography>
        </Grid2>
        {/* <Grid2 size={{xs:6, sm:4, lg:2}}>
            <Stats
              title={'Nombre de biens'}
              amount={10}
              text={'Lots'}
              percentageColor={theme.palette.success.main}
              chipText={'+10%'}
              info={'ce mois-ci'}
            />
          </Grid2> */}
        {/* <Grid2 size={{xs:6, sm:4, lg:2}}>
            <Stats
              title={'Nombre de biens'}
              amount={10}
              text={'Lots'}
              percentageColor={theme.palette.success.main}
              chipText={'+10%'}
              info={'ce mois-ci'}
            />
          </Grid2> */}
        {/* <Grid2 size={{xs:6, sm:4, lg:2}}>
            <Stats
              title={'Nombre de biens'}
              amount={10}
              text={'Lots'}
              percentageColor={theme.palette.success.main}
              chipText={'+10%'}
              info={'ce mois-ci'}
            />
          </Grid2>
          <Grid2 size={{xs:6, sm:4, lg:2}}>
            <Stats
              title={'Nombre de biens'}
              amount={10}
              text={'Lots'}
              percentageColor={theme.palette.success.main}
              chipText={'+10%'}
              info={'ce mois-ci'}
            />
          </Grid2> */}
        {allStats.length > 0 && (
          <Grid2 size={{ xs: 6, sm: 4, lg: 2 }}>
            <DoughnutChart
              value={calculatePercentage(
                allStats[allStats.length - 1].data.products.with_scan,
                allStats[allStats.length - 1].data.products.with_scan +
                  allStats[allStats.length - 1].data.products.without_scan
              )}
              title={'Numérisation des biens'}
              text={'Scanné'}
              info1={'Scanné'}
              info2={'Non Scanné'}
            />
          </Grid2>
        )}
        {allStats.length > 0 && (
          <Grid2 size={{ xs: 6, sm: 4, lg: 2 }}>
            <DoughnutChart
              value={calculatePercentage(
                allStats[allStats.length - 1].data.mission_order.closed,
                allStats[allStats.length - 1].data.mission_order.closed +
                  allStats[allStats.length - 1].data.mission_order.in_progress
              )}
              title={'Ordre de missions'}
              text={'Terminés'}
              info1={'Terminés'}
              info2={'En cours'}
            />
          </Grid2>
        )}

        <Grid2 size={{ xs: 4, sm: 2, lg: 2 }}>
          <ListStats title={'Type de biens'} stats={productsKindStats} />
        </Grid2>
        <Grid2 size={{ xs: 4, sm: 2, lg: 2 }}>
          <ListStats title={'DPE'} stats={dpeStats} />
        </Grid2>

        {allStats.length > 0 && (
          <Grid2 size={{ xs: 12, sm: 6, lg: 4 }}>
            <PiesChart title={'Tickets'} data={allStats[allStats.length - 1].data.tickets} />
          </Grid2>
        )}

        {/* <Grid2 item xs={12} sm={6} lg={6}>
            <LinesChart title={'Evolution du Portefeuille'} labels={labels} data={data3} info={'Cumul'} />
          </Grid2> */}

        <Grid2 size={{ xs: 12, sm: 12, lg: 6 }}>
          <DynamicDataGrid data={usersEvents} />
        </Grid2>
        {/* 
          <Grid2 item xs={12} sm={12} lg={12}>
            <BarsChart
              title={'Evolution du Portefeuille'}
              labels={labels}
              info1={'Entrée'}
              data1={data1}
              info2={'Sortie'}
              data2={data2}
            />
          </Grid2> */}

        <Grid2 size={{ xs: 12, sm: 12, lg: 8 }}></Grid2>
      </Grid2>
      {/* )} */}
    </>
  );
};
