import { useEffect, useState } from 'react';
import LeaseLine from './LeaseLine';
import { ILease } from '~/interfaces';
import { Grid2 } from '@mui/material';
import { appTheme } from '~/utils/theme';

export const LeaseList = ({ isMobile, productDatas }) => {
  const [leaseList, setLeaseList] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [showMore, setShowMore] = useState(true);

  useEffect(() => {
    console.log(productDatas.leases);

    setLeaseList(productDatas.leases);
  }, [productDatas.leases]);

  useEffect(() => {
    if (leaseList.length === 0) return;
    setHasMore(leaseList.length > 2);
  }, [leaseList]);

  return (
    <>
      {leaseList.map((lease: ILease, index) => (
        <div key={index}>
          {(index < 1 || !showMore) && (
            <LeaseLine
              key={index}
              status={lease.status}
              startDate={lease.start_at}
              endDate={lease.end_at}
              regime={lease.kind}
              leaseType={lease.duration}
              tenants={lease.contacts}
              uuid={lease.uuid}
              productDatas={productDatas}
              exitDate={lease.exit_at}
            />
          )}
        </div>
      ))}
      {hasMore && (
        <Grid2
          size={12}
          style={{ cursor: 'pointer', marginTop: '10px', textAlign: 'right' }}
          onClick={() => {
            setShowMore(!showMore);
          }}
          sx={{
            '&:hover': {
              color: appTheme.palette.secondary.main // Change this to your desired color
            }
          }}
        >
          {showMore ? 'Voir plus' : 'Voir moins'}
        </Grid2>
      )}
    </>
  );
};
