import { useContext, useEffect, useState } from 'react';
import BasicModal from '../BasicModal/BasicModal';
import { Grid2, Button, Typography, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { api } from '~/api';
import { ProductTitle } from '../ProductTitle/ProductTitle';
import { AppContext } from '~/utils/context/AppContext';
import { ILotCreate } from '~/interfaces';
import { set } from 'date-fns';

export const LotsAdd = ({ productDatas, setLotsAddOpen, setPropertyData }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(true);
  const { referencial } = useContext(AppContext);
  const [lotsKind, setLotsKind] = useState([]);
  const [selectedKind, setSelectedKind] = useState(null);
  const [lotData, setLotData] = useState<ILotCreate>();

  const modalClosed = () => {
    setLotsAddOpen(false);
  };

  useEffect(() => {
    setLotData({
      ...lotData,
      uuid_product: productDatas.uuid
    });
  }, [productDatas]);

  useEffect(() => {
    if (!selectedKind) return;
    setLotData({
      ...lotData,
      kind: selectedKind
    });
  }, [selectedKind]);

  useEffect(() => {
    console.log('lotData', lotData);
  }, [lotData]);

  useEffect(() => {
    // console.log(referencial['commodity.kind']);
    setLotsKind(
      referencial['lots.kind']
        .filter((item) => item.name !== 'Inconnu')
        .sort((a, b) => {
          if (a.name === 'Autre') return 1;
          if (b.name === 'Autre') return -1;
          return a.name.localeCompare(b.name);
        })
    );
  }, [referencial]);

  return (
    <BasicModal
      modalOpen={modalOpen}
      onModalOpen={(open: boolean) => setModalOpen(open)}
      onModalClose={modalClosed}
      confirmClose={false}
    >
      <Grid2 container spacing={2} justifyContent={'center'} alignItems={'center'}>
        <Grid2
          size={12}
          style={{
            backgroundColor: '#fff7f3',
            width: '100vw',
            paddingLeft: '15px',
            position: 'sticky',
            top: 0,
            zIndex: 1
          }}
        >
          <ProductTitle data={productDatas} />
        </Grid2>

        <Grid2 size={12} style={{ padding: '16px' }}>
          <Grid2 container spacing={2} justifyContent={'center'} alignItems={'center'}>
            <Grid2 size={12} sx={{ textAlign: 'center' }}>
              <Typography variant="h4">Ajouter un lot</Typography>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }} sx={{ padding: '16px' }}>
              <Grid2 container spacing={2}>
                <Grid2 size={12}>
                  <FormControl fullWidth required>
                    <InputLabel size="small" id="demo-simple-select-label">
                      Type de lot
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedKind ? selectedKind : ''}
                      label="Type de lot"
                      onChange={(e) => {
                        setSelectedKind(e.target.value);
                      }}
                      size="small"
                    >
                      {lotsKind.map((lot, index) => {
                        return (
                          <MenuItem key={index} value={lot.id}>
                            {lot.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid2>
                {selectedKind && selectedKind === 1 && (
                  <Grid2 size={12}>
                    <FormControl fullWidth>
                      <TextField
                        disabled={!selectedKind}
                        value={lotData?.name}
                        size="small"
                        label="Nom du lot"
                        autoComplete="off"
                        onChange={(e) => {
                          setLotData({ ...lotData, name: e.target.value });
                        }}
                      ></TextField>
                    </FormControl>
                  </Grid2>
                )}
                <Grid2 size={12}>
                  <FormControl fullWidth>
                    <TextField
                      disabled={!selectedKind}
                      size="small"
                      label="Description du lot"
                      autoComplete="off"
                      value={lotData?.desc}
                      onChange={(e) => {
                        setLotData({ ...lotData, desc: e.target.value });
                      }}
                    ></TextField>
                  </FormControl>
                </Grid2>
              </Grid2>
            </Grid2>

            <Grid2 size={12}></Grid2>
          </Grid2>
        </Grid2>

        {/* Sticky Buttons */}
        <Grid2
          size={12}
          style={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: '#fff',
            padding: '16px 0',
            zIndex: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Button
            variant="outlined"
            style={{ marginRight: '16px' }}
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              api.lot.create(lotData).then((res) => {
                setModalOpen(false);
                setPropertyData((prev) => {
                  return { ...prev, lots: [...prev.lots, res] };
                });
              });
            }}
          >
            Valider
          </Button>
        </Grid2>
      </Grid2>
    </BasicModal>
  );
};
