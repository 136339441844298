import { Grid2, Typography } from '@mui/material';
import { useContext } from 'react';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

const Header = ({ title }) => {
  const mediaQueries = useContext(MediaQueryContext);
  return (
    <>
      <Grid2 size={12} sx={{ textAlign: 'center' }}>
        <img
          src="./Regis.jpg"
          alt="Regis"
          width={mediaQueries.matchesLg ? 300 : mediaQueries.matchesMd ? 200 : mediaQueries.matchesSm ? 150 : 100}
        />
      </Grid2>
      <Grid2 size={12} sx={{ textAlign: 'center' }}>
        <Typography variant={mediaQueries.matchesSm ? 'h3' : 'h4'} sx={{ pt: '20px', pb: '40px', lineHeight: '1.3' }}>
          {title}
        </Typography>
      </Grid2>
    </>
  );
};

export default Header;
