import { Grid2, Typography, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { useContext, useEffect } from 'react';
import { api } from '~/api';
import { AppContext } from '~/utils/context/AppContext';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

const MoreInformations = ({ setTitle, setCurrentStep, productDatas, setProductDatas }) => {
  const mediaQueries = useContext(MediaQueryContext);
  const { referencial } = useContext(AppContext);
  useEffect(() => {
    setTitle('Quelques caractéristiques utiles supplémentaires');
  }, []);
  return (
    <>
      <Grid2 size={12} sx={{ textAlign: 'center' }}>
        <Typography variant={mediaQueries.matchesSm ? 'h5' : 'h6'} sx={{ lineHeight: '1.3' }}>
          {'Vous pourrez compléter ou ajouter d’autres informations plus tard, depuis la fiche du bien. '}
        </Typography>
      </Grid2>
      <Grid2 container spacing={0} justifyContent={'center'} sx={{ mt: '50px' }}>
        {mediaQueries.matchesMd && <Grid2 size={3} sx={{ textAlign: 'center' }}></Grid2>}
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Grid2 container spacing={2}>
            <Grid2 size={12}>
              <FormControl fullWidth>
                <InputLabel size="small" id="demo-simple-select-label">
                  Type de chauffage individuel
                </InputLabel>
                <Select
                  defaultValue=""
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    productDatas.heating?.individual?.heating_energy_type !== null &&
                    productDatas.heating?.individual?.heating_energy_type !== undefined
                      ? productDatas.heating?.individual?.heating_energy_type
                      : ''
                  }
                  label="Type de chauffage individuel"
                  onChange={(e) => {
                    setProductDatas({
                      ...productDatas,
                      heating: {
                        ...productDatas.heating,
                        individual: {
                          ...productDatas?.heating?.individual,
                          heating_energy_type:
                            referencial['energy_heat.type'].find(
                              (energyType) => energyType.id === Number(e.target.value)
                            ).name === 'Inconnu'
                              ? null
                              : Number(e.target.value)
                        }
                      }
                    });
                  }}
                  size="small"
                >
                  {referencial['energy_heat.type']
                    .map((type) => {
                      if (type.name === 'Inconnu') {
                        return { ...type, name: 'Aucun' };
                      }
                      return type;
                    })
                    .sort((a, b) => {
                      if (a.name === 'Autre') return 1;
                      if (b.name === 'Autre') return -1;
                      return a.name.localeCompare(b.name);
                    })
                    .map((type, index) => {
                      return (
                        <MenuItem key={index} value={type.id}>
                          {type.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid2>
            <Grid2 size={12}>
              <FormControl fullWidth>
                <InputLabel size="small" id="demo-simple-select-label">
                  Type de production d'eau chaude individuelle
                </InputLabel>
                <Select
                  defaultValue=""
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    productDatas.heating?.individual?.ecs_energy_type !== null &&
                    productDatas.heating?.individual?.ecs_energy_type !== undefined
                      ? productDatas.heating?.individual?.ecs_energy_type
                      : ''
                  }
                  label="Type de production d'eau chaude individuelle"
                  onChange={(e) => {
                    setProductDatas({
                      ...productDatas,
                      heating: {
                        ...productDatas?.heating,
                        individual: {
                          ...productDatas?.heating?.individual,
                          ecs_energy_type:
                            referencial['energy_water.type'].find(
                              (energyType) => energyType.id === Number(e.target.value)
                            ).name === 'Inconnu'
                              ? null
                              : Number(e.target.value)
                        }
                      }
                    });
                  }}
                  size="small"
                >
                  {referencial['energy_water.type']
                    .map((type) => {
                      if (type.name === 'Inconnu') {
                        return { ...type, name: 'Aucun' };
                      }
                      return type;
                    })
                    .sort((a, b) => {
                      if (a.name === 'Autre') return 1;
                      if (b.name === 'Autre') return -1;
                      return a.name.localeCompare(b.name);
                    })
                    .map((type, index) => {
                      return (
                        <MenuItem key={index} value={type.id}>
                          {type.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid2>
            <Grid2 size={12}>
              <FormControl fullWidth>
                <InputLabel size="small" id="demo-simple-select-label">
                  Type de refroidissment individuel
                </InputLabel>
                <Select
                  defaultValue=""
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    productDatas.heating?.individual?.cooling_energy_type !== null &&
                    productDatas.heating?.individual?.cooling_energy_type !== undefined
                      ? productDatas.heating?.individual?.cooling_energy_type
                      : ''
                  }
                  label="Type de refroidissment individuel"
                  onChange={(e) => {
                    setProductDatas({
                      ...productDatas,
                      heating: {
                        ...productDatas.heating,
                        individual: {
                          ...productDatas?.heating?.individual,
                          cooling_energy_type:
                            referencial['energy_cooling.type'].find(
                              (energyType) => energyType.id === Number(e.target.value)
                            ).name === 'Inconnu'
                              ? null
                              : Number(e.target.value)
                        }
                      }
                    });
                  }}
                  size="small"
                >
                  {referencial['energy_cooling.type']
                    .map((type) => {
                      if (type.name === 'Inconnu') {
                        return { ...type, name: 'Aucun' };
                      }
                      return type;
                    })
                    .sort((a, b) => {
                      if (a.name === 'Autre') return 1;
                      if (b.name === 'Autre') return -1;
                      return a.name.localeCompare(b.name);
                    })
                    .map((type, index) => {
                      return (
                        <MenuItem key={index} value={type.id}>
                          {type.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid2>

            {/* \ */}

            <Grid2 size={12} sx={{ mt: '20px' }}>
              <FormControl fullWidth>
                <InputLabel size="small" id="demo-simple-select-label">
                  Type de chauffage collectif
                </InputLabel>
                <Select
                  defaultValue=""
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    productDatas.heating?.communal?.heating_energy_type !== null &&
                    productDatas.heating?.communal?.heating_energy_type !== undefined
                      ? productDatas.heating?.communal?.heating_energy_type
                      : ''
                  }
                  label="Type de chauffage collectif"
                  onChange={(e) => {
                    setProductDatas({
                      ...productDatas,
                      heating: {
                        ...productDatas.heating,
                        communal: {
                          ...productDatas?.heating?.communal,
                          heating_energy_type:
                            referencial['energy_heat.type'].find(
                              (energyType) => energyType.id === Number(e.target.value)
                            ).name === 'Inconnu'
                              ? null
                              : Number(e.target.value)
                        }
                      }
                    });
                  }}
                  size="small"
                >
                  {referencial['energy_heat.type']
                    .map((type) => {
                      if (type.name === 'Inconnu') {
                        return { ...type, name: 'Aucun' };
                      }
                      return type;
                    })
                    .sort((a, b) => {
                      if (a.name === 'Autre') return 1;
                      if (b.name === 'Autre') return -1;
                      return a.name.localeCompare(b.name);
                    })
                    .map((type, index) => {
                      return (
                        <MenuItem key={index} value={type.id}>
                          {type.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid2>
            <Grid2 size={12}>
              <FormControl fullWidth>
                <InputLabel size="small" id="demo-simple-select-label">
                  Type de production d'eau chaude collectif
                </InputLabel>
                <Select
                  defaultValue=""
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    productDatas.heating?.communal?.ecs_energy_type !== null &&
                    productDatas.heating?.communal?.ecs_energy_type !== undefined
                      ? productDatas.heating?.communal?.ecs_energy_type
                      : ''
                  }
                  label="Type de production d'eau chaude collectif"
                  onChange={(e) => {
                    setProductDatas({
                      ...productDatas,
                      heating: {
                        ...productDatas.heating,
                        communal: {
                          ...productDatas?.heating?.communal,
                          ecs_energy_type:
                            referencial['energy_water.type'].find(
                              (energyType) => energyType.id === Number(e.target.value)
                            ).name === 'Inconnu'
                              ? null
                              : Number(e.target.value)
                        }
                      }
                    });
                  }}
                  size="small"
                >
                  {referencial['energy_water.type']
                    .map((type) => {
                      if (type.name === 'Inconnu') {
                        return { ...type, name: 'Aucun' };
                      }
                      return type;
                    })
                    .sort((a, b) => {
                      if (a.name === 'Autre') return 1;
                      if (b.name === 'Autre') return -1;
                      return a.name.localeCompare(b.name);
                    })
                    .map((type, index) => {
                      return (
                        <MenuItem key={index} value={type.id}>
                          {type.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid2>
            <Grid2 size={12}>
              <FormControl fullWidth>
                <InputLabel size="small" id="demo-simple-select-label">
                  Type de refroidissment collectif
                </InputLabel>
                <Select
                  defaultValue=""
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={
                    productDatas.heating?.communal?.cooling_energy_type !== null &&
                    productDatas.heating?.communal?.cooling_energy_type !== undefined
                      ? productDatas.heating?.communal?.cooling_energy_type
                      : ''
                  }
                  label="Type de refroidissment collectif"
                  onChange={(e) => {
                    setProductDatas({
                      ...productDatas,
                      heating: {
                        ...productDatas.heating,
                        communal: {
                          ...productDatas?.heating?.communal,
                          cooling_energy_type:
                            referencial['energy_cooling.type'].find(
                              (energyType) => energyType.id === Number(e.target.value)
                            ).name === 'Inconnu'
                              ? null
                              : Number(e.target.value)
                        }
                      }
                    });
                  }}
                  size="small"
                >
                  {referencial['energy_cooling.type']
                    .map((type) => {
                      if (type.name === 'Inconnu') {
                        return { ...type, name: 'Aucun' };
                      }
                      return type;
                    })
                    .sort((a, b) => {
                      if (a.name === 'Autre') return 1;
                      if (b.name === 'Autre') return -1;
                      return a.name.localeCompare(b.name);
                    })
                    .map((type, index) => {
                      return (
                        <MenuItem key={index} value={type.id}>
                          {type.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid2>
          </Grid2>
        </Grid2>
        {mediaQueries.matchesMd && <Grid2 size={3} sx={{ textAlign: 'center' }}></Grid2>}
      </Grid2>
      <Grid2
        container
        spacing={3}
        justifyContent={'center'}
        sx={{
          mt: '20px',
          position: 'sticky', // Rend les boutons "collants"
          bottom: 0, // Position en bas de la fenêtre si sticky s'applique
          bgcolor: 'white', // Fond blanc pour couvrir le contenu en dessous
          zIndex: 10, // S'assure que les boutons sont au premier plan
          boxShadow: '0 -2px 5px rgba(0,0,0,0)', // Ajoute une ombre douce si besoin
          pb: 2 // Ajoute du padding vertical pour éviter une hauteur trop compacte
        }}
      >
        <Grid2 size={6} sx={{ pt: '30px', textAlign: 'right' }}>
          <Button
            fullWidth={mediaQueries.matchesMd ? false : true}
            color={'primary'}
            sx={{ textDecoration: 'underline' }}
            onClick={() => {
              setCurrentStep(6);
            }}
          >
            {'Retour'}
          </Button>
        </Grid2>
        <Grid2 size={6} sx={{ pt: '30px', textAlign: 'left' }}>
          <Button
            fullWidth={mediaQueries.matchesMd ? false : true}
            variant={'contained'}
            color={'primary'}
            sx={{ color: 'white' }}
            onClick={async () => {
              try {
                if (productDatas.uuid === null || productDatas.uuid === undefined || productDatas.uuid === '') {
                  const product = await api.product.create(productDatas);
                  setProductDatas({ ...productDatas, uuid: product.uuid });
                }
                setCurrentStep(8);
              } catch (error) {
                console.log(error);
              }
            }}
          >
            suivant
          </Button>
        </Grid2>
      </Grid2>
    </>
  );
};

export default MoreInformations;
