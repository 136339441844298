import React from 'react';
import { Box, FormControl, Grid2, InputLabel, MenuItem, Select } from '@mui/material';
import { ConditionReference } from '~/utils/references';

export const ConditionForm = ({ documentData, setDocumentData, documentTypeLayout }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...documentData.data };
    data.condition = event.target.value;
    setDocumentData({ ...documentData, data: data });
  };
  return (
    <>
      {documentData && documentTypeLayout && documentTypeLayout.needCondition && (
        <>
          <Grid2 size={12}>
            <Box sx={{ mb: 3 }}>
              <FormControl fullWidth>
                <InputLabel id="condition">Etat</InputLabel>
                <Select
                  labelId="condition"
                  label="Etat"
                  value={documentData.data.condition ? documentData.data.condition : ''}
                  onChange={handleChange}
                >
                  {ConditionReference &&
                    ConditionReference.filter((Condition) => Condition.name !== '').map((Condition, index) => (
                      <MenuItem key={index + 1} value={Condition.name}>
                        {Condition.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </Grid2>
        </>
      )}
    </>
  );
};
