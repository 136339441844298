import { Grid2 } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { IDpe, IProduct, ModalContentType } from '~/interfaces';
import { AppContext } from '~/utils/context/AppContext';
import _ from 'lodash';
import '~/app/pages/Product/Product.scss';
import dayjs from 'dayjs';

interface PropertyInfoProps {
  onOpen: (open: boolean) => void;
  onModalType: (type: ModalContentType) => void;
  data: IProduct;
  lastDpe: IDpe;
}

const BuildingInfo = ({ onOpen, onModalType, data, lastDpe }: PropertyInfoProps) => {
  const { referencial } = useContext(AppContext);
  const [propertyKindName, setPropertyKindName] = useState('');
  const [constructionPeriode, setConstructionPeriode] = useState(null);

  useEffect(() => {
    if (referencial) {
      const kind = data.kind ? _.find(referencial['product.kind'], { id: data.kind.toString() }) : null;
      setPropertyKindName(kind ? kind.name : 'Autre');

      setConstructionPeriode(_.sortBy(referencial['building.construction_period'], ['id']));
    }
  }, [referencial, data]);

  const BuildingDataValues = {
    building_name: { name: 'Nom du bâtiment', type: 'string' },
    building_access: { name: 'Acces au bâtiment', type: 'string' }
  };

  const convertValue = (key, object) => {
    if (object === null) {
      return null;
    }
    if (object.type === 'number') {
      if (object.value === '' || object.value === null || object.value === undefined || object.value === 0) {
        return null;
      }
      return Math.floor(object.value * 100) / 100 + ' ' + (object.suffixe ? object.suffixe : '');
    }

    if (object.type === 'date') {
      return new Date(String(object.value)).toLocaleDateString();
    }

    if (object.type === 'boolean') {
      if (object.value === true || object.value === 'true') {
        return 'Oui';
      }
      if (object.value === false || object.value === 'false') {
        return 'Non';
      }
      return object.value;
    }

    if (object.type === 'string') {
      return object.value;
    }
  };

  const SortedProductData = {};
  const productDataEntries = Object.entries(BuildingDataValues);
  productDataEntries.map(([key, value]) => {
    if (data.address[key] !== null && data.address[key] !== undefined && data.address[key] !== '') {
      SortedProductData[value.name] = {
        type: value.type,
        value: data.address[key],
        suffixe: value['suffixe'],
        reference: value['reference']
      };
    }
  });

  return (
    <>
      <div className="Product_infos">
        <div className="Product__card__moreInfo">
          <Grid2 container spacing={0}>
            {Object.entries(SortedProductData).map(([key, object]) => (
              <Grid2 key={key} size={6} sx={{ paddingBottom: '10px' }}>
                <div className={key}>
                  <span style={{ fontSize: '12px', color: '#929292' }}>{key}</span>
                  <br />
                  <span>{convertValue(key, object)}</span>
                </div>
              </Grid2>
            ))}
            {data &&
              data.address &&
              data.address.data &&
              data.address?.data['construction_year'] &&
              data.address?.data['construction_year'] !== '' && (
                <Grid2 size={6} sx={{ paddingBottom: '10px' }}>
                  <div>
                    <span style={{ fontSize: '12px', color: '#929292' }}>Année de construction </span>
                    <br />
                    <span>{`${dayjs(data.address?.data['construction_year']).year()}`}</span>
                  </div>
                </Grid2>
              )}

            {data &&
              data.address &&
              data.address.data &&
              data.address?.data['construction_period'] !== 0 &&
              data.address?.data['construction_period'] &&
              constructionPeriode &&
              data.address?.data['construction_period'] !== null &&
              data.address?.data['construction_period'] !== '' && (
                <Grid2 size={6} sx={{ paddingBottom: '10px' }}>
                  <div>
                    <span style={{ fontSize: '12px', color: '#929292' }}>Période de construction </span>
                    <br />
                    <span>{`${constructionPeriode[data.address?.data['construction_period']].name}`}</span>
                  </div>
                </Grid2>
              )}
          </Grid2>
        </div>
      </div>
    </>
  );
};

export default BuildingInfo;
