import { Grid2, Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

import './StepsViewer.scss';
import { useContext } from 'react';

export const Step = ({ title, index, status }) => {
  const mediaQueries = useContext(MediaQueryContext);

  return (
    <>
      {mediaQueries?.matchesMd ? (
        <>
          <Grid2 size={12}>
            <Grid2 container>
              <Grid2 size="grow">
                <div></div>
              </Grid2>
              <Grid2 sx={{ minWidth: '70px' }}>
                <div className="line"></div>
              </Grid2>
            </Grid2>
          </Grid2>
          <Grid2 size={12}>
            <Grid2
              container
              sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
            >
              <Grid2 size="grow" textAlign={'center'}>
                <div className="step-title">{title}</div>
              </Grid2>
              <Grid2
                sx={{
                  minWidth: !mediaQueries?.matchesMd ? '30px' : '70px',
                  ml: !mediaQueries?.matchesMd ? '1.5vw' : 0,
                  flexDirection: 'row'
                }}
              >
                <div className={status === 'current' || status === 'done' ? 'current-circle' : 'todo-circle'}>
                  {status === 'done' && <CheckIcon sx={{ color: 'white' }} />}
                  {status !== 'done' && index + 1}
                </div>
              </Grid2>
            </Grid2>
          </Grid2>
        </>
      ) : (
        <Grid2
          container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 20px',
            marginBottom: '0.5vh'
          }}
        >
          <Tooltip title={title} arrow placement="bottom" enterTouchDelay={1} leaveTouchDelay={2000}>
            <div
              className={status === 'current' || status === 'done' ? 'current-circle' : 'todo-circle'}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {status === 'done' && <CheckIcon sx={{ color: 'white' }} />}
              {status !== 'done' && index + 1}
            </div>
          </Tooltip>
        </Grid2>
      )}
    </>
  );
};
