import { Button, FormControl, Grid2, InputLabel, MenuItem, Select, TextField, Paper } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useEffect, useState, useContext, useMemo, useRef } from 'react';
import { api } from '~/api';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { DateValidationError } from '@mui/x-date-pickers/models';
import { AppContext } from '~/utils/context/AppContext';
import _ from 'lodash';
import './EditPropertyInfos.scss';
import { ProductTitle } from '../ProductTitle/ProductTitle';

export const EditBuildingInfos = ({
  setSpinnerOpen,
  spinnerOpen,
  setModalOpen,
  refreshData,
  propertyData,
  setPropertyData,
  propertyKindName = '',
  lastDpe,
  setLastDpe
}) => {
  type TuseParams = {
    id: string;
  };

  const { referencial } = useContext(AppContext);
  const [yearToPeriodeArr, setYearToPeriodeArr] = useState(null);
  const [constructionPeriode, setConstructionPeriode] = useState(null);
  const [periode, setPeriode] = useState(0);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [tmpPropertyData, setTmpPropertyData] = useState(null);
  const [annee, setAnnee] = useState<any>(null);
  const [error, setError] = useState<DateValidationError | null>(null);
  const [contentHeight, setContentHeight] = useState(0);

  const disableKeyboardEntry = (e: any) => {
    if (e?.keyCode === 40 || e?.keyCode === 38) {
      if (e?.keyCode === 38 && Number(e.target.value) >= dayjs().year()) {
        e?.preventDefault();
        e?.stopPropagation();
      }
    }
  };

  const headerRef = useRef(null);
  const footerRef = useRef(null);

  const defaultMinDate = dayjs('0100');
  const defaultMaxDate = dayjs(new Date().getFullYear().toString());

  useEffect(() => {
    setMinDate(defaultMinDate);
    setMaxDate(defaultMaxDate);
  }, []);

  const errorMessage = useMemo(() => {
    switch (error) {
      case 'maxDate': {
        return 'Date supérieure à la date maximale';
      }
      case 'minDate': {
        return 'Date inférieure à la date minimale';
      }

      case 'invalidDate': {
        return "La date saisie n'est pas valide";
      }

      default: {
        return '';
      }
    }
  }, [error]);

  const setPeriodFromYear = (year) => {
    let periodToSet = null;
    yearToPeriodeArr.forEach((periode, index) => {
      if (year <= periode[1] && year >= periode[0]) {
        setPeriode(index);
        periodToSet = index;
        setMinDate(dayjs(periode[0].toString()));
        setMaxDate(dayjs(periode[1].toString()));
      }
      if (periode[0] === 'Après' && year >= periode[1]) {
        setMinDate(dayjs(periode[1].toString()));
        setMaxDate(defaultMaxDate);
        setPeriode(index);
        periodToSet = index;
      }
      if (periode[0] === 'Avant' && year < periode[1]) {
        setMinDate(defaultMinDate);
        setMaxDate(dayjs(periode[1].toString()));
        setPeriode(index);
        periodToSet = index;
      }
    });
    return periodToSet;
  };

  useEffect(() => {
    if (headerRef.current && footerRef.current) {
      setContentHeight(window.innerHeight - headerRef.current.offsetHeight - footerRef.current.offsetHeight - 60);
    }
  });

  useEffect(() => {
    setConstructionPeriode(_.sortBy(referencial['building.construction_period'], ['id']));
  }, [referencial]);

  useEffect(() => {
    const tmpDatas = {
      address: { ...propertyData.address }
    };

    setTmpPropertyData(tmpDatas);
  }, [propertyData]);

  useEffect(() => {
    if (constructionPeriode) {
      setYearToPeriodeArr(
        constructionPeriode.map((periode, index) => {
          if (periode.name.indexOf('Après') !== -1) {
            return [periode.name.split(' ')[0], Number(periode.name.split(' ')[1])];
          } else if (periode.name.indexOf('Avant') !== -1) {
            return [periode.name.split(' ')[0], Number(periode.name.split(' ')[1])];
          } else {
            return periode.name.split('-').map((x) => {
              return Number(x);
            });
          }
        })
      );
    }
  }, [constructionPeriode]);

  useEffect(() => {
    if (constructionPeriode) {
      if (constructionPeriode[Number(propertyData.address.data.construction_period)])
        setPeriode(constructionPeriode[Number(propertyData.address.data.construction_period)]['id']);
    }
  }, [constructionPeriode]);

  if (!tmpPropertyData) {
    return null;
  }

  return (
    <Grid2
      container
      spacing={1}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      alignContent="stretch"
      wrap="wrap"
    >
      {/* HEADER */}

      <Grid2 size={12} ref={headerRef}>
        <ProductTitle data={propertyData} />
        <Divider sx={{ mb: 5 }} />
      </Grid2>

      <Grid2
        size={12}
        style={{
          maxHeight: contentHeight + 'px',
          overflow: 'auto',
          paddingBottom: '10px',
          paddingTop: '10px',
          paddingRight: '10px'
        }}
      >
        <Grid2
          container
          spacing={5}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          alignContent="stretch"
          wrap="wrap"
        >
          {/* GENERAL */}

          <Grid2 size={12}>
            <Paper elevation={2} sx={{ p: 2 }}>
              <Grid2
                container
                spacing={3}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="stretch"
                wrap="wrap"
              >
                <Grid2 size={12}>
                  <h3 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Information sur la bâtiment</h3>
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <TextField
                    label="Nom du bâtiment"
                    size="small"
                    type={'text'}
                    fullWidth
                    placeholder='Ex: "Bâtiment A"'
                    value={tmpPropertyData.address?.building_name}
                    onChange={(e) => {
                      setTmpPropertyData({
                        ...tmpPropertyData,
                        address: { ...tmpPropertyData.address, building_name: e.target.value }
                      });
                    }}
                    autoComplete="off"
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <TextField
                    label="Accès bâtiment / code"
                    size="small"
                    type={'text'}
                    placeholder='Ex: "Bâtiment au fond à droite, code: A2351"'
                    fullWidth
                    value={tmpPropertyData.address?.building_access}
                    autoComplete="off"
                    onChange={(e) => {
                      setTmpPropertyData({
                        ...tmpPropertyData,
                        address: { ...tmpPropertyData.address, building_access: e.target.value }
                      });
                    }}
                  />
                </Grid2>
                <>
                  <Grid2 size={12}>
                    <h3 style={{ paddingBottom: '0px', marginBottom: '0px', paddingTop: '0px', marginTop: '10px' }}>
                      Construction
                    </h3>
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <FormControl fullWidth>
                      <DatePicker
                        label="Année de construction"
                        views={['year']}
                        value={
                          tmpPropertyData.address?.data?.construction_year
                            ? dayjs(tmpPropertyData.address?.data?.construction_year)
                            : null
                        }
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={(e) => {
                          if (dayjs(e).format() !== 'Invalid Date') {
                            setAnnee(dayjs(e).toDate());
                            const period = setPeriodFromYear(dayjs(e).year());
                            setTmpPropertyData({
                              ...tmpPropertyData,
                              address: {
                                ...tmpPropertyData.address,
                                data: {
                                  ...tmpPropertyData.address.data,
                                  construction_year: dayjs(e).toISOString(),
                                  construction_period: period
                                }
                              }
                            });
                          }
                        }}
                        slotProps={{
                          textField: {
                            size: 'small',
                            onKeyDownCapture: disableKeyboardEntry,
                            helperText: errorMessage
                          }
                        }}
                        disableFuture
                      />
                    </FormControl>
                  </Grid2>
                  {
                    <Grid2 size={{ xs: 12, md: 6 }}>
                      <FormControl fullWidth>
                        <InputLabel size="small" id="construction_period">
                          Periode de construction
                        </InputLabel>
                        <Select
                          defaultValue=""
                          // disabled={dpeDatas?.body?.building_construction_period_kind ? true : false}
                          fullWidth
                          labelId="construction_period"
                          id="demo-simple-select"
                          value={tmpPropertyData ? tmpPropertyData?.address?.data?.construction_period : null}
                          label="Période de construction"
                          onChange={(e) => {
                            setPeriode(Number(e.target.value));
                            setTmpPropertyData({
                              ...tmpPropertyData,
                              address: {
                                ...tmpPropertyData.address,
                                data: {
                                  ...tmpPropertyData.address.data,
                                  construction_period: Number(e.target.value)
                                }
                              }
                            });
                            const periodRange = yearToPeriodeArr[Number(e.target.value)];
                            if (periodRange[0] === 'Après') {
                              setMinDate(dayjs(periodRange[1].toString()));
                              setMaxDate(defaultMaxDate);
                            } else if (periodRange[0] === 'Avant') {
                              setMinDate(defaultMinDate);
                              setMaxDate(dayjs(periodRange[1].toString()));
                            } else if (periodRange[0] === isNaN || periodRange[1] === undefined) {
                            } else {
                              setMinDate(dayjs(periodRange[0].toString()));
                              setMaxDate(dayjs(periodRange[1].toString()));
                            }
                          }}
                          size="small"
                        >
                          {constructionPeriode.map((periode) => {
                            return (
                              <MenuItem key={periode.id} value={periode.id}>
                                {periode.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid2>
                  }
                </>
              </Grid2>
            </Paper>
          </Grid2>
        </Grid2>
      </Grid2>

      {/* FOOTER */}

      <Grid2 ref={footerRef} size={12} sx={{ mt: '15px' }}>
        <Grid2 container spacing={2} justifyContent={'center'} alignItems={'center'}>
          <Grid2>
            <Button
              variant="outlined"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Annuler
            </Button>
          </Grid2>
          <Grid2>
            <Button
              variant="contained"
              onClick={() => {
                api.address
                  .update(propertyData.address.uuid, tmpPropertyData.address)
                  .then((res) => {
                    setModalOpen(false);
                    refreshData();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              Valider
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default EditBuildingInfos;
