import { useMediaQuery, useTheme } from '@mui/material';
import { useState, useEffect, createContext } from 'react';

interface MediaQueryContextProps {
  matchesXs: boolean;
  matchesSm: boolean;
  matchesMd: boolean;
  matchesLg: boolean;
  matchesXl: boolean;
}

export const MediaQueryContext = createContext<MediaQueryContextProps>({
  matchesXs: false,
  matchesSm: false,
  matchesMd: false,
  matchesLg: false,
  matchesXl: false
});

export const MediaQueryProvider = ({ children }) => {
  const theme = useTheme();

  // Détection des media queries
  const matchesXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
  const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));

  const [mediaQueries, setMediaQueries] = useState({
    matchesXs,
    matchesSm,
    matchesMd,
    matchesLg,
    matchesXl
  });

  // Mettre à jour l'état en fonction des changements de media queries
  useEffect(() => {
    setMediaQueries({ matchesXs, matchesSm, matchesMd, matchesLg, matchesXl });
  }, [matchesXs, matchesSm, matchesMd, matchesLg, matchesXl]);

  return <MediaQueryContext.Provider value={mediaQueries}>{children}</MediaQueryContext.Provider>;
};
