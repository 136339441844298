import {
  Button,
  Checkbox,
  FormControl,
  FormGroup,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Paper
} from '@mui/material';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useState, useContext, useRef } from 'react';
import { api } from '~/api';
import { isSuperAdmin } from '~/app/components/Auth/AuthApi';
import dayjs from 'dayjs';
import { AppContext } from '~/utils/context/AppContext';
import _ from 'lodash';
import { ProductTitle } from '../ProductTitle/ProductTitle';
import './EditPropertyInfos.scss';

export const EditPropertyInfos = ({
  setSpinnerOpen,
  spinnerOpen,
  setModalOpen,
  refreshData,
  propertyData,
  setPropertyData,
  propertyKindName = '',
  lastDpe,
  setLastDpe
}) => {
  type TuseParams = {
    id: string;
  };

  const [kindIndex, setKindIndex] = useState<string>('0');
  const [dpeNumber, setDpeNumber] = useState('');
  const [dpeNumberSearched, setDpeNumberSearched] = useState('');
  const [dpeSearchStatus, setDpeSearchStatus] = useState('');
  const [dpeDatas, setDpeDatas] = useState(null);
  const [editDpe, setEditDpe] = useState(false);
  const [matterportID, setMatterportID] = useState('');
  const { referencial } = useContext(AppContext);
  const [yearToPeriodeArr, setYearToPeriodeArr] = useState(null);
  const [constructionPeriode, setConstructionPeriode] = useState(null);
  const [periode, setPeriode] = useState(0);
  const [tmpPropertyData, setTmpPropertyData] = useState(null);
  const [contentHeight, setContentHeight] = useState(0);

  const headerRef = useRef(null);
  const footerRef = useRef(null);

  const getPropertyKindIndex = () => {
    referencial['product.kind'].map((kind, index) => {
      if (kind.id === tmpPropertyData?.kind) {
        setKindIndex(index.toString());
        return index.toString();
      }
    });
  };

  const keyPress = (e) => {
    if (e.keyCode == 13) {
      handleSearchDPE();
    }
  };

  const heatingList = (title, referentialSection, key, kind, marginTop) => {
    return (
      <FormControl fullWidth sx={{ marginTop: marginTop }}>
        <InputLabel size="small" id="demo-simple-select-label">
          {title}
        </InputLabel>
        <Select
          defaultValue=""
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={
            tmpPropertyData.heating?.[kind]?.[key] !== null && tmpPropertyData.heating?.[kind]?.[key] !== undefined
              ? tmpPropertyData.heating?.[kind]?.[key]
              : ''
          }
          label={title}
          onChange={(e) => {
            setTmpPropertyData({
              ...tmpPropertyData,
              heating: {
                ...tmpPropertyData.heating,
                [kind]: {
                  ...tmpPropertyData?.heating?.[kind],
                  [key]:
                    referencial[referentialSection].find((energyType) => energyType.id === Number(e.target.value))
                      .name === 'Inconnu'
                      ? null
                      : Number(e.target.value)
                }
              }
            });
          }}
          size="small"
        >
          {referencial[referentialSection]
            .map((type) => {
              if (type.name === 'Inconnu') {
                return { ...type, name: 'Aucun' };
              }
              return type;
            })
            .sort((a, b) => {
              if (a.name === 'Autre') return 1;
              if (b.name === 'Autre') return -1;
              return a.name.localeCompare(b.name);
            })
            .map((type, index) => {
              return (
                <MenuItem key={index} value={type.id}>
                  {type.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    );
  };

  useEffect(() => {
    if (headerRef.current && footerRef.current) {
      setContentHeight(window.innerHeight - headerRef.current.offsetHeight - footerRef.current.offsetHeight - 60);
    }
  });

  const resetDpeSearch = () => {
    setDpeDatas(null);
    setDpeNumber('');
    setDpeSearchStatus('');
    setEditDpe(true);
  };

  const handleSearchDPE = () => {
    setDpeDatas(null);
    setDpeNumberSearched(dpeNumber);
    setDpeSearchStatus('searching');
    api.ademe
      .get(dpeNumber)
      .then((res) => {
        setLastDpe(res.body);
        api.ademe.assign(propertyData.uuid, res.body.uuid, {}).then((res) => console.log('res assignedDpe', res));
      })
      .catch((e) => {
        setDpeDatas(null);
        setDpeSearchStatus('searched');
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (propertyData.matterport_scan) {
      setMatterportID(propertyData.matterport_scan.model_id);
    }
    setPeriode(propertyData.construction_period);
  }, []);

  useEffect(() => {
    setConstructionPeriode(_.sortBy(referencial['building.construction_period'], ['id']));
  }, [referencial]);

  useEffect(() => {
    const tmpDatas = {
      id_internal: propertyData.id_internal,
      kind: propertyData.kind,
      data: { ...propertyData.data },
      address_complement: propertyData.address_complement,
      invariant_tax: propertyData.invariant_tax,
      surface: { ...propertyData.surface },
      identification: { ...propertyData.identification },
      dpes: [{ ...lastDpe }],
      heating: { ...propertyData.heating }
    };

    setTmpPropertyData(tmpDatas);
  }, [propertyData]);

  useEffect(() => {
    if (constructionPeriode) {
      setYearToPeriodeArr(
        constructionPeriode.map((periode, index) => {
          if (periode.name.indexOf('Après') !== -1) {
            return [periode.name.split(' ')[0], Number(periode.name.split(' ')[1])];
          } else if (periode.name.indexOf('Avant') !== -1) {
            return [periode.name.split(' ')[0], Number(periode.name.split(' ')[1])];
          } else {
            return periode.name.split('-').map((x) => {
              return Number(x);
            });
          }
        })
      );
    }
  }, [constructionPeriode]);

  useEffect(() => {
    if (constructionPeriode) {
      if (constructionPeriode[Number(tmpPropertyData.construction_period)])
        setPeriode(constructionPeriode[Number(tmpPropertyData.construction_period)]['id']);
    }
  }, [constructionPeriode]);

  useEffect(() => {
    if (lastDpe) {
      if (lastDpe.dpe_number && lastDpe.dpe_number.length > 0) {
        setEditDpe(false);
      } else {
        setEditDpe(true);
      }
      getPropertyKindIndex();
    }
  }, [tmpPropertyData]);

  if (!tmpPropertyData) {
    return null;
  }

  return (
    <Grid2
      container
      spacing={1}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      alignContent="stretch"
      wrap="wrap"
    >
      {/* HEADER */}
      <Grid2 size={12} ref={headerRef}>
        <ProductTitle data={propertyData} />
        <Divider sx={{ mb: 1 }} />
      </Grid2>

      <Grid2
        size={12}
        style={{
          maxHeight: contentHeight + 'px',
          overflow: 'auto',
          paddingBottom: '10px',
          paddingTop: '10px',
          paddingRight: '10px'
        }}
      >
        <Grid2
          container
          spacing={5}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          alignContent="stretch"
          wrap="wrap"
        >
          {/* GENERAL */}
          <Grid2 size={{ xs: 12, md: 6, lg: 4, xl: 3 }}>
            <Paper elevation={2} sx={{ p: 2 }}>
              <Grid2
                container
                spacing={3}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="stretch"
                wrap="wrap"
              >
                <Grid2 size={12}>
                  <h3 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Général</h3>
                </Grid2>
                <Grid2 size={12}>
                  <TextField
                    label="Complément d'adresse"
                    size="small"
                    type={'text'}
                    fullWidth
                    placeholder='Ex: "étage: 2, porte: 3"'
                    value={tmpPropertyData.address_complement ? tmpPropertyData.address_complement : ''}
                    onChange={(e) => {
                      setTmpPropertyData({ ...tmpPropertyData, address_complement: e.target.value });
                    }}
                    autoComplete="off"
                  />
                </Grid2>
                <Grid2 size={12}>
                  <TextField
                    label="Référence interne"
                    size="small"
                    type={'text'}
                    fullWidth
                    value={
                      tmpPropertyData.identification?.id_internal ? tmpPropertyData.identification?.id_internal : ''
                    }
                    autoComplete="off"
                    onChange={(e) => {
                      setTmpPropertyData({
                        ...tmpPropertyData,
                        identification: { ...tmpPropertyData.identification, id_internal: e.target.value }
                      });
                    }}
                  />
                </Grid2>
                {isSuperAdmin() && (
                  <Grid2 size={12}>
                    <TextField
                      label="UID modèle MTP"
                      size="small"
                      type={'text'}
                      fullWidth
                      value={matterportID}
                      autoComplete="off"
                      onChange={(e) => {
                        setMatterportID(e.target.value);
                      }}
                    />
                  </Grid2>
                )}
                <Grid2 size={12}>
                  <FormControl fullWidth>
                    <InputLabel size="small" id="demo-simple-select-label">
                      Nature du bien
                    </InputLabel>
                    <Select
                      defaultValue=""
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={tmpPropertyData.kind ? tmpPropertyData.kind : ''}
                      label="Nature du bien"
                      onChange={(e) => {
                        let id = null;
                        id = referencial['product.kind'].find((kind) => kind.id === Number(e.target.value)).id;
                        setTmpPropertyData({ ...tmpPropertyData, kind: id });
                      }}
                      size="small"
                    >
                      {referencial['product.kind']
                        .filter((kind) => kind.name !== 'inconnu')
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((kind, index) => {
                          return (
                            <MenuItem key={index} value={kind.id}>
                              {kind.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Grid2>
              </Grid2>
            </Paper>
          </Grid2>
          {/* SURFACE */}
          <Grid2 size={{ xs: 12, md: 6, lg: 4, xl: 3 }}>
            <Paper elevation={2} sx={{ p: 2 }}>
              <Grid2
                container
                spacing={3}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="stretch"
                wrap="wrap"
              >
                <Grid2 size={12}>
                  <h3 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Surface - composition</h3>
                </Grid2>
                <Grid2 size={6}>
                  <TextField
                    type="number"
                    label="Surface habitable"
                    size="small"
                    fullWidth
                    autoComplete="off"
                    value={
                      tmpPropertyData?.surface?.living_space !== null &&
                      tmpPropertyData?.surface?.living_space !== undefined
                        ? tmpPropertyData?.surface?.living_space
                        : ''
                    }
                    onChange={(e) => {
                      setTmpPropertyData({
                        ...tmpPropertyData,
                        surface: {
                          ...tmpPropertyData.surface,
                          living_space:
                            Number(e.target.value) && Number(e.target.value) !== 0 ? Number(e.target.value) : null
                        }
                      });
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m²</InputAdornment>
                    }}
                  ></TextField>
                </Grid2>
                <Grid2 size={6}>
                  <TextField
                    type="number"
                    label="Surface Carrez"
                    size="small"
                    fullWidth
                    autoComplete="off"
                    value={
                      tmpPropertyData?.surface?.carrez !== null && tmpPropertyData?.surface?.carrez !== undefined
                        ? tmpPropertyData?.surface?.carrez
                        : ''
                    }
                    onChange={(e) => {
                      setTmpPropertyData({
                        ...tmpPropertyData,
                        surface: {
                          ...tmpPropertyData.surface,
                          carrez: Number(e.target.value) && Number(e.target.value) !== 0 ? Number(e.target.value) : null
                        }
                      });
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m²</InputAdornment>
                    }}
                  ></TextField>
                </Grid2>
                <Grid2 size={6}>
                  <TextField
                    type="number"
                    label="Surface Boutin"
                    size="small"
                    fullWidth
                    autoComplete="off"
                    value={
                      tmpPropertyData?.surface?.boutin !== null && tmpPropertyData?.surface?.boutin !== undefined
                        ? tmpPropertyData?.surface?.boutin
                        : ''
                    }
                    onChange={(e) => {
                      setTmpPropertyData({
                        ...tmpPropertyData,
                        surface: {
                          ...tmpPropertyData.surface,
                          boutin: Number(e.target.value) && Number(e.target.value) !== 0 ? Number(e.target.value) : null
                        }
                      });
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m²</InputAdornment>
                    }}
                  ></TextField>
                </Grid2>
                <Grid2 size={6}>
                  <TextField
                    type="number"
                    label="Surface au sol"
                    size="small"
                    fullWidth
                    autoComplete="off"
                    value={
                      tmpPropertyData?.surface?.ground !== null && tmpPropertyData?.surface?.ground !== undefined
                        ? tmpPropertyData?.surface?.ground
                        : ''
                    }
                    onChange={(e) => {
                      setTmpPropertyData({
                        ...tmpPropertyData,
                        surface: {
                          ...tmpPropertyData.surface,
                          ground: Number(e.target.value) && Number(e.target.value) !== 0 ? Number(e.target.value) : null
                        }
                      });
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m²</InputAdornment>
                    }}
                  ></TextField>
                </Grid2>
                <Grid2 size={6}>
                  <TextField
                    type="number"
                    label="Hauteur sous plafond"
                    size="small"
                    fullWidth
                    autoComplete="off"
                    value={
                      tmpPropertyData?.surface?.ceiling_height !== null &&
                      tmpPropertyData?.surface?.ceiling_height !== undefined
                        ? tmpPropertyData?.surface?.ceiling_height
                        : ''
                    }
                    onChange={(e) => {
                      setTmpPropertyData({
                        ...tmpPropertyData,
                        surface: { ...tmpPropertyData.surface, ceiling_height: Number(e.target.value) }
                      });
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">m</InputAdornment>
                    }}
                  ></TextField>
                </Grid2>
                {referencial['product.kind'][kindIndex].id === 1 && (
                  <Grid2 size={6}>
                    <FormControl fullWidth>
                      <InputLabel size="small" id="demo-simple-select-label">
                        Type de logement
                      </InputLabel>
                      <Select
                        defaultValue=""
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={
                          tmpPropertyData.surface?.logement_type !== null &&
                          tmpPropertyData.surface?.logement_type !== undefined
                            ? tmpPropertyData.surface?.logement_type
                            : ''
                        }
                        label="Type de logement"
                        onChange={(e) => {
                          setTmpPropertyData({
                            ...tmpPropertyData,
                            surface: { ...tmpPropertyData.surface, logement_type: Number(e.target.value) }
                          });
                        }}
                        size="small"
                      >
                        {referencial['surface.kind'].map((type, index) => {
                          return (
                            <MenuItem key={index} value={index}>
                              {type}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid2>
                )}
              </Grid2>
              <Grid2
                container
                spacing={3}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="stretch"
                wrap="wrap"
              >
                <Grid2 size={12}>
                  <h5 style={{ paddingBottom: '0px', marginBottom: '0px' }}></h5>
                </Grid2>

                <Grid2 size={12}>
                  <TextField
                    label="Nombre de cages d'esclalier"
                    size="small"
                    type={'number'}
                    autoComplete="off"
                    fullWidth
                    value={
                      tmpPropertyData.surface?.nb_stairs !== null && tmpPropertyData.surface?.nb_stairs !== undefined
                        ? tmpPropertyData.surface?.nb_stairs
                        : ''
                    }
                    onChange={(e) => {
                      setTmpPropertyData({
                        ...tmpPropertyData,
                        surface: { ...tmpPropertyData.surface, nb_stairs: Number(e.target.value) }
                      });
                    }}
                  ></TextField>
                </Grid2>
                <>
                  <Grid2 size={6}>
                    <TextField
                      label="Nombre d'étages"
                      size="small"
                      type={'number'}
                      fullWidth
                      autoComplete="off"
                      value={
                        tmpPropertyData.surface?.nb_floors !== null &&
                        tmpPropertyData.surface?.nb_floors !== undefined &&
                        tmpPropertyData.surface?.nb_floors !== 0
                          ? tmpPropertyData.surface?.nb_floors
                          : ''
                      }
                      onChange={(e) => {
                        setTmpPropertyData({
                          ...tmpPropertyData,
                          surface: { ...tmpPropertyData.surface, nb_floors: Number(e.target.value) }
                        });
                      }}
                    ></TextField>
                  </Grid2>
                  <Grid2 size={6}>
                    <TextField
                      label="Nombre de pièces"
                      size="small"
                      type={'number'}
                      fullWidth
                      autoComplete="off"
                      value={
                        tmpPropertyData.surface?.nb_rooms !== null &&
                        tmpPropertyData.surface?.nb_rooms !== undefined &&
                        tmpPropertyData.surface?.nb_rooms !== 0
                          ? tmpPropertyData.surface?.nb_rooms
                          : ''
                      }
                      onChange={(e) => {
                        setTmpPropertyData({
                          ...tmpPropertyData,
                          surface: { ...tmpPropertyData.surface, nb_rooms: Number(e.target.value) }
                        });
                      }}
                    ></TextField>
                  </Grid2>
                </>
              </Grid2>
            </Paper>
          </Grid2>
          {/* PERFORMANCE ENERGETIQUE */}
          <Grid2 size={{ xs: 12, md: 6, lg: 4, xl: 3 }}>
            <Paper elevation={2} sx={{ p: 2 }}>
              <Grid2
                container
                spacing={3}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="stretch"
                wrap="wrap"
              >
                <Grid2 size={12}>
                  <h3 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Performance énergétique</h3>
                </Grid2>
                <Grid2 size={12}>
                  <TextField
                    fullWidth
                    sx={{ pr: '120px' }}
                    style={{ paddingRight: '0px' }}
                    label="N° du DPE"
                    disabled={lastDpe?.dpe_number && !editDpe ? true : false}
                    id="search-input"
                    autoComplete="off"
                    placeholder="13 caractères"
                    size="small"
                    onKeyUp={keyPress}
                    value={lastDpe?.dpe_number && !editDpe ? lastDpe?.dpe_number : dpeNumber}
                    onChange={(e) => {
                      setDpeNumber(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          {!editDpe && <HighlightOffIcon onClick={resetDpeSearch}></HighlightOffIcon>}
                          {editDpe && <SearchIcon onClick={handleSearchDPE} />}
                        </InputAdornment>
                      ),
                      style: {
                        paddingRight: 0
                      }
                    }}
                  ></TextField>
                </Grid2>
                {lastDpe && (
                  <>
                    <Grid2 size={6}>
                      <TextField
                        label="Consommation énergétique"
                        size="small"
                        fullWidth
                        autoComplete="off"
                        disabled
                        value={lastDpe.energy_result ? Math.round(lastDpe.energy_result) : ''}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">kWh/m²</InputAdornment>
                        }}
                      ></TextField>
                    </Grid2>
                    <Grid2 size={6}>
                      <TextField
                        label="Etiquette énergétique"
                        size="small"
                        fullWidth
                        autoComplete="off"
                        disabled
                        value={lastDpe.energy_sticker ? lastDpe.energy_sticker : ''}
                      ></TextField>
                    </Grid2>
                    <Grid2 size={6}>
                      <TextField
                        label="GES"
                        size="small"
                        fullWidth
                        autoComplete="off"
                        disabled
                        value={lastDpe.ges_result ? Math.round(lastDpe.ges_result) : ''}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              kgCO
                              <sub>
                                <sub>2</sub>
                              </sub>
                              /m²
                            </InputAdornment>
                          )
                        }}
                      ></TextField>
                    </Grid2>
                    <Grid2 size={6}>
                      <TextField
                        label="Etiquette GES"
                        size="small"
                        fullWidth
                        autoComplete="off"
                        disabled
                        value={lastDpe.ges_sticker ? lastDpe.ges_sticker : ''}
                      ></TextField>
                    </Grid2>
                    <Grid2 size={6}>
                      <TextField
                        label="Date établissement DPE"
                        size="small"
                        fullWidth
                        autoComplete="off"
                        disabled
                        value={lastDpe?.done_at ? dayjs(lastDpe?.done_at).format('DD/MM/YYYY') : ''}
                      ></TextField>
                    </Grid2>
                    <Grid2 size={6}>
                      <TextField
                        label="Date expiration DPE"
                        size="small"
                        fullWidth
                        autoComplete="off"
                        disabled
                        value={lastDpe?.expire_at ? dayjs(lastDpe?.expire_at).format('DD/MM/YYYY') : ''}
                      ></TextField>
                    </Grid2>
                  </>
                )}
              </Grid2>
            </Paper>
          </Grid2>
          {/* IDENTIFICATION BLOC */}
          <Grid2 size={{ xs: 12, md: 6, lg: 4, xl: 3 }}>
            <Paper elevation={2} sx={{ p: 2 }}>
              <Grid2
                container
                spacing={3}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="stretch"
                wrap="wrap"
              >
                <Grid2 size={12}>
                  <h3 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Identification</h3>
                </Grid2>
                <Grid2 size={6}>
                  <TextField
                    label="Numéro de lot"
                    size="small"
                    autoComplete="off"
                    fullWidth
                    value={
                      tmpPropertyData.identification?.lot_number !== null &&
                      tmpPropertyData.identification?.lot_number !== undefined
                        ? tmpPropertyData.identification?.lot_number
                        : ''
                    }
                    onChange={(e) => {
                      setTmpPropertyData({
                        ...tmpPropertyData,
                        identification: { ...tmpPropertyData.identification, lot_number: e.target.value }
                      });
                    }}
                  ></TextField>
                </Grid2>
                <Grid2 size={6}>
                  <TextField
                    label="Lot(s) associé(s)"
                    size="small"
                    fullWidth
                    autoComplete="off"
                    value={
                      tmpPropertyData.data?.lot_associated !== null &&
                      tmpPropertyData.data?.lot_associated !== undefined
                        ? tmpPropertyData.data?.lot_associated
                        : ''
                    }
                    onChange={(e) => {
                      setTmpPropertyData({
                        ...tmpPropertyData,
                        data: { ...tmpPropertyData.data, lot_associated: e.target.value }
                      });
                    }}
                  ></TextField>
                </Grid2>
                <Grid2 size={6}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tmpPropertyData.data?.mono_property}
                          onChange={(e) => {
                            setTmpPropertyData({
                              ...tmpPropertyData,
                              data: { ...tmpPropertyData.data, mono_property: e.target.checked }
                            });
                          }}
                        />
                      }
                      label="Mono-propiété"
                    />
                  </FormGroup>
                </Grid2>
                <Grid2 size={6}></Grid2>
                <Grid2 size={4}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tmpPropertyData.data?.co_property}
                          onChange={(e) => {
                            setTmpPropertyData({
                              ...tmpPropertyData,
                              data: { ...tmpPropertyData.data, co_property: e.target.checked }
                            });
                          }}
                        />
                      }
                      label="Copropiété"
                    />
                  </FormGroup>
                </Grid2>
                <Grid2 size={8}>
                  <TextField
                    label="Numéro d'immatriculation"
                    size="small"
                    fullWidth
                    autoComplete="off"
                    value={
                      tmpPropertyData.identification?.immatriculation_number !== null &&
                      tmpPropertyData.identification?.immatriculation_number !== undefined
                        ? tmpPropertyData.identification?.immatriculation_number
                        : ''
                    }
                    onChange={(e) => {
                      setTmpPropertyData({
                        ...tmpPropertyData,
                        identification: {
                          ...tmpPropertyData.identification,
                          immatriculation_number: e.target.value.toString()
                        }
                      });
                    }}
                  ></TextField>
                </Grid2>
                <Grid2 size={12}>
                  <h3 style={{ paddingBottom: '0px', marginBottom: '0px', paddingTop: '0px', marginTop: '10px' }}>
                    Référence cadastrale
                  </h3>
                </Grid2>

                <Grid2 size={6}>
                  <TextField
                    autoComplete="off"
                    label="Section"
                    size="small"
                    fullWidth
                    value={
                      tmpPropertyData.identification?.cadastral_section !== null &&
                      tmpPropertyData.identification?.cadastral_section !== undefined
                        ? tmpPropertyData.identification?.cadastral_section
                        : ''
                    }
                    onChange={(e) => {
                      setTmpPropertyData({
                        ...tmpPropertyData,
                        identification: { ...tmpPropertyData.identification, cadastral_section: e.target.value }
                      });
                    }}
                  ></TextField>
                </Grid2>
                <Grid2 size={6}>
                  <TextField
                    autoComplete="off"
                    label="Parcelle"
                    size="small"
                    fullWidth
                    value={
                      tmpPropertyData.identification?.cadastral_parcel !== null &&
                      tmpPropertyData.identification?.cadastral_parcel !== undefined
                        ? tmpPropertyData.identification?.cadastral_parcel
                        : ''
                    }
                    onChange={(e) => {
                      setTmpPropertyData({
                        ...tmpPropertyData,
                        identification: { ...tmpPropertyData.identification, cadastral_parcel: e.target.value }
                      });
                    }}
                  ></TextField>
                </Grid2>
                <Grid2 size={12}>
                  <TextField
                    autoComplete="off"
                    label="Invariant fiscal"
                    size="small"
                    fullWidth
                    value={
                      tmpPropertyData.identification?.invariant_tax !== null &&
                      tmpPropertyData.identification?.invariant_tax !== undefined
                        ? tmpPropertyData.identification?.invariant_tax
                        : ''
                    }
                    onChange={(e) => {
                      setTmpPropertyData({
                        ...tmpPropertyData,
                        identification: { ...tmpPropertyData.identification, invariant_tax: e.target.value }
                      });
                    }}
                  ></TextField>
                </Grid2>
              </Grid2>
            </Paper>
          </Grid2>
          {/* CHAUFFAGE/ECS/REFROIDISSEMENT BLOC */}
          <Grid2 size={{ xs: 12, md: 6, lg: 4, xl: 3 }}>
            <Paper elevation={2} sx={{ p: 2 }}>
              <Grid2
                container
                spacing={3}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="stretch"
                wrap="wrap"
              >
                <Grid2 size={12}>
                  <h3 style={{ paddingBottom: '0px', marginBottom: '0px' }}>Chauffage / ecs / refroidissement</h3>
                </Grid2>

                {heatingList(
                  'Type de chauffage individuel',
                  'energy_heat.type',
                  'heating_energy_type',
                  'individual',
                  '20px'
                )}

                {heatingList(
                  "Type de production d'eau chaude individuelle",
                  'energy_water.type',
                  'ecs_energy_type',
                  'individual',
                  '0px'
                )}

                {heatingList(
                  'Type de refroidissment individuel',
                  'energy_cooling.type',
                  'cooling_energy_type',
                  'individual',
                  '0px'
                )}

                {heatingList(
                  'Type de chauffage collectif',
                  'energy_heat.type',
                  'heating_energy_type',
                  'communal',
                  '20px'
                )}
                {heatingList(
                  "Type de production d'eau chaude collectif",
                  'energy_water.type',
                  'ecs_energy_type',
                  'communal',
                  '0px'
                )}
                {heatingList(
                  'Type de refroidissment collectif',
                  'energy_cooling.type',
                  'cooling_energy_type',
                  'communal',
                  '0px'
                )}
              </Grid2>
            </Paper>
          </Grid2>
        </Grid2>
      </Grid2>
      {/* FOOTER */}
      <Grid2 ref={footerRef} size={12} sx={{ mt: '15px' }}>
        <Grid2 container spacing={2} justifyContent={'center'} alignItems={'center'}>
          <Grid2>
            <Button
              variant="outlined"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Annuler
            </Button>
          </Grid2>
          <Grid2>
            <Button
              variant="contained"
              onClick={() => {
                const promises = [];
                promises.push(api.product.update(propertyData.uuid, tmpPropertyData));
                const matterportData = {
                  uuid_owner: propertyData.uuid,
                  model_id: matterportID
                };
                if (matterportID) {
                  promises.push(api.matterport.create(matterportData));
                }
                Promise.all(promises)
                  .then((resp) => {
                    setModalOpen(false);
                    refreshData();
                    propertyData.matterport_scan = matterportData;
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }}
            >
              Valider
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default EditPropertyInfos;
