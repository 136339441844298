import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  TextField,
  Autocomplete,
  Chip
} from '@mui/material';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import * as Yup from 'yup';
import { api } from '~/api';
import { ContactBase, ContactCreate, ContactRelKind, ContactRelKindToString } from '~/api/contact.models';
import { AssociateContact } from '~/app/components/WizzardBlocks/AssociateContact';
import { MuiChipsInput } from 'mui-chips-input';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { MuiTelInput } from 'mui-tel-input';
import { CountryCode } from 'libphonenumber-js';

interface WizzardContactProps {
  contact?: ContactBase;
  contactsAlreadyAssociated?: ContactBase[];
  open: boolean;
  onClose: () => void;
  contacts?: ContactBase[];
  isNewContact?: boolean;
  isProductPage?: boolean;
  productId?: string;
  setContacts?: (contacts: ContactCreate[]) => void;
  setUpdateContact?: (updateContact: boolean) => void;
  isEdit?: boolean;
}
export const WizzardContact: FC<WizzardContactProps> = (props) => {
  const {
    contact,
    open,
    onClose,
    contacts,
    isNewContact,
    isProductPage,
    productId,
    setContacts,
    contactsAlreadyAssociated,
    setUpdateContact,
    ...other
  } = props;
  const [editMode, setEditMode] = useState<boolean>(false);
  const [createNewContact, setCreateNewContact] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<ContactBase>();
  const [editContact, setEditContact] = useState<boolean>(false);
  const [assignRelationType, setAssignRelationType] = useState<string>();
  const [pathContext, setPathContext] = useState<string>('');
  const relationTypes = Object.values(ContactRelKind);
  const [countryCode, setCountryCode] = useState<CountryCode>('FR');
  const [countryCode2, setCountryCode2] = useState<CountryCode>('FR');

  const restrictedOptions = [0, 1, 2, 7, 8]; // 0: Other, 1: Owner, 2: Tenant, 7 : SeviceProvider, 8 : Ocuppant (Fais référence à ContactRelKind)
  const relationTypesString = restrictedOptions.map((relationType) =>
    ContactRelKindToString(relationTypes[relationType] as ContactRelKind)
  );

  const handleKeywordsChange = (value) => {
    formik.setFieldValue('keywords', value);
  };

  const handleRemoveKeyword = (index) => {
    const keywords = [...formik.values.keywords];
    keywords.splice(index, 1);
    formik.setFieldValue('keywords', keywords);
  };

  useEffect(() => {
    const pathname = window.location.pathname.split('/');
    if (pathname[1] === 'product') {
      setPathContext('product');
    } else {
      setPathContext('product');
    }
  }, []);

  const handleAssignContact = async (id: string) => {
    await api[pathContext].contact
      .linkTo(productId, id, restrictedOptions[relationTypesString.indexOf(assignRelationType)] as ContactRelKind)
      .then((resp) => {
        setSelectedContact(null);
        setUpdateContact(true);
        onClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formik = useFormik<ContactCreate>({
    enableReinitialize: true,
    initialValues: {
      name: selectedContact?.name || '',
      phone1: selectedContact?.phone1 || '',
      phone2: selectedContact?.phone2 || '',
      email: selectedContact?.email || '',

      company_name: selectedContact?.company_name || '',
      job_title: selectedContact?.job_title || '',

      keywords: selectedContact?.keywords || [],

      data: selectedContact?.data || {}
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().max(255).required('Nom requis'),
      email: Yup.string().email('Doit être un email valide').max(255).required('Email requis'),
      phone1: Yup.string()
        .max(255)
        .matches(/^\+?\d+$/, 'Veuillez entrer des chiffres uniquement ou un "+" au début'),
      phone2: Yup.string()
        .max(255)
        .matches(/^\+?\d+$/, 'Veuillez entrer des chiffres uniquement ou un "+" au début'),
      keywords: Yup.array().of(Yup.string()),
      'data.website': Yup.string().matches(
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
        'Doit être une URL valide pour un site internet'
      )
    }),

    onSubmit: async (values, helpers) => {
      try {
        if (selectedContact) {
          api.contacts.update(selectedContact.uuid, values).then((resp) => {
            const updatedContacts = contacts.map((contact) => {
              if (contact.uuid === selectedContact.uuid) {
                return resp;
              } else {
                return contact;
              }
            });
            setContacts(updatedContacts);
            if (isProductPage) {
              handleAssignContact(selectedContact.uuid);
            } else {
              onClose();
            }
          });
        } else {
          await api.contacts.create(values).then((resp) => {
            if (contacts) {
              setContacts([...contacts, resp]);
            } else {
              setContacts([resp]);
            }
            if (isProductPage) {
              handleAssignContact(resp.uuid);
            } else {
              onClose();
            }
          });
        }
        toast.success(`Contact ${selectedContact ? 'mis à jour' : 'créé'}`);
        helpers.resetForm();
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);
        onClose();
      }
    }
  });

  useEffect(() => {
    isNewContact && setCreateNewContact(true);

    createNewContact && setEditMode(true);

    if (!isProductPage && !createNewContact) {
      setEditContact(true);
      setSelectedContact(contact);
    }

    props.isEdit && setEditMode(true);
  }, [contact, contacts, createNewContact, isNewContact, editContact, isProductPage]);

  return (
    <>
      <Dialog onClose={onClose} open={open} {...other} PaperProps={{ sx: { width: '500px' } }}>
        <DialogTitle>
          {createNewContact ? (
            'Créer un nouveau contact'
          ) : selectedContact && !editContact ? (
            <>
              <Button
                onClick={() => {
                  setEditContact(true);
                }}
              >
                {selectedContact.name} <EditOutlinedIcon />
              </Button>
            </>
          ) : editContact ? (
            selectedContact.name
          ) : (
            'Assigner un contact'
          )}
        </DialogTitle>
        <DialogContent>
          <br />
          {!createNewContact && !editContact && (
            <>
              <AssociateContact
                allContacts={contacts}
                setSelectedContact={setSelectedContact}
                productId={productId}
                isNewContact={isNewContact}
                contactsAlreadyAssociated={contactsAlreadyAssociated}
              />
              {selectedContact && (
                <Grid2 size={12}>
                  <Autocomplete
                    options={relationTypesString}
                    onChange={(event, newValue) => {
                      setAssignRelationType(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Type de relation avec le bien" fullWidth />}
                  />
                  <DialogActions>
                    <Button
                      color="primary"
                      onClick={() => {
                        setSelectedContact(null);
                        console.log('onClose');
                        onClose();
                      }}
                      variant="text"
                    >
                      Annuler
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={!assignRelationType}
                      onClick={() => {
                        handleAssignContact(selectedContact.uuid);
                      }}
                    >
                      Valider
                    </Button>
                  </DialogActions>
                </Grid2>
              )}
              {!selectedContact && (
                <Button
                  color="primary"
                  onClick={() => {
                    setCreateNewContact(true);
                  }}
                  variant="text"
                >
                  Créer un nouveau contact
                </Button>
              )}
            </>
          )}
          {createNewContact || editContact ? (
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <TextField
                  error={Boolean(formik.touched.name && formik.errors.name)}
                  fullWidth
                  helperText={formik.touched.name && formik.errors.name}
                  label="Nom du Contact"
                  name="name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.name}
                  disabled={!editMode}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <TextField
                  error={Boolean(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  label="Adresse email"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.email}
                  disabled={!editMode}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <MuiTelInput
                  error={Boolean(formik.touched.phone1 && formik.errors.phone1)}
                  fullWidth
                  helperText={formik.touched.phone1 && formik.errors.phone1}
                  label="Numéro de téléphone"
                  name="phone1"
                  onBlur={formik.handleBlur}
                  onChange={(value, info) => formik.setFieldValue('phone1', info.numberValue)}
                  value={formik.values.phone1}
                  disabled={!editMode}
                  defaultCountry={countryCode}
                  forceCallingCode
                  continents={['EU']}
                />
              </Grid2>
              <Grid2 size={12}>
                <MuiChipsInput
                  value={formik.values.keywords}
                  onChange={handleKeywordsChange}
                  onBlur={formik.handleBlur}
                  name="keywords"
                  label="Tags"
                  placeholder=""
                  fullWidth
                  disabled={!editMode}
                  renderChip={(value, index, chipProps) => (
                    <Chip key={index} {...chipProps} color="primary" onDelete={() => handleRemoveKeyword(index)} />
                  )}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <TextField
                  error={Boolean(formik.touched.company_name && formik.errors.company_name)}
                  fullWidth
                  helperText={formik.touched.company_name && formik.errors.company_name}
                  label="Nom de la société"
                  name="company_name"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.company_name}
                  disabled={!editMode}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <TextField
                  error={Boolean(formik.touched.job_title && formik.errors.job_title)}
                  fullWidth
                  helperText={formik.touched.job_title && formik.errors.job_title}
                  label="Fonction"
                  name="job_title"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.job_title}
                  disabled={!editMode || !formik.values.company_name}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <MuiTelInput
                  error={Boolean(formik.touched.phone2 && formik.errors.phone2)}
                  fullWidth
                  helperText={formik.touched.phone2 && formik.errors.phone2}
                  label="Numéro de téléphone"
                  name="phone2"
                  onBlur={formik.handleBlur}
                  onChange={(value, info) => formik.setFieldValue('phone2', info.numberValue)}
                  value={formik.values.phone2}
                  disabled={!editMode || !formik.values.company_name}
                  defaultCountry={countryCode2}
                  forceCallingCode
                  continents={['EU']}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, md: 6 }}>
                <TextField
                  label="Site web"
                  fullWidth
                  value={formik.values.data && formik.values.data.website ? formik.values.data.website : ''}
                  disabled={!editMode || !formik.values.company_name}
                  onChange={(e) => {
                    formik.setFieldValue('data', { ...formik.values.data, website: e.target.value });
                  }}
                />
              </Grid2>
              <Grid2 size={12}>
                <TextField
                  id="outlined-multiline-static"
                  label="Commentaire"
                  multiline
                  rows={1}
                  fullWidth
                  disabled={!editMode}
                  value={formik.values.data && formik.values.data.comment ? formik.values.data.comment : ''}
                  onChange={(e) => {
                    formik.setFieldValue('data', { ...formik.values.data, comment: e.target.value });
                  }}
                />
              </Grid2>
              {isProductPage ? (
                <Grid2 size={12}>
                  <Autocomplete
                    options={relationTypesString}
                    disabled={!editMode}
                    onChange={(event, newValue) => {
                      setAssignRelationType(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Type de relation avec le bien *" fullWidth />
                    )}
                  />
                </Grid2>
              ) : null}
              <DialogActions>
                <Button
                  color="primary"
                  onClick={() => {
                    onClose();
                    setEditMode(false);
                    setCreateNewContact(false);
                    setEditContact(false);
                  }}
                  variant="text"
                >
                  Annuler
                </Button>
                {!editMode ? (
                  <Button
                    color="primary"
                    disabled={formik.isSubmitting}
                    onClick={() => {
                      setEditMode(true);
                    }}
                    variant="contained"
                  >
                    Modifier
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    disabled={
                      formik.isSubmitting ||
                      (!assignRelationType && isProductPage) ||
                      !formik.values.name ||
                      !formik.values.email
                    }
                    onClick={() => {
                      if (isProductPage) {
                        setCreateNewContact(false);
                      }
                      formik.handleSubmit();
                    }}
                    variant="contained"
                  >
                    Enregistrer
                  </Button>
                )}
              </DialogActions>
            </Grid2>
          ) : null}
        </DialogContent>
      </Dialog>
      <Toaster />
    </>
  );
};
