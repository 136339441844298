import { Grid2, Button } from '@mui/material';
import PresentationBloc from '../SubComponents/PresentationBloc';
import { useContext, useEffect } from 'react';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

const PresentationStep = ({ setCurrentStep, setTitle }) => {
  const mediaQueries = useContext(MediaQueryContext); // Accès aux media queries depuis le contexte

  useEffect(() => {
    setTitle('Créer un nouveau produit sur Régir, c’est facile');
  }, []);

  return (
    <Grid2 container justifyContent={'center'}>
      <Grid2 container spacing={3} justifyContent={'center'}>
        <PresentationBloc
          number={1}
          title={'Identifiez le bien'}
          text={
            'Donnez-nous quelques informations de base, par exemple où il se trouve, sa nature et sa surface... \nMunissez-vous du numéro Ademe du DPE pour gagner du temps.'
          }
        />
        <PresentationBloc
          number={2}
          title={'Renseignez ses caractéristiques'}
          text={'Ajoutez des caractéristiques utiles, pour un meilleur pilotage.'}
        />
        <PresentationBloc
          number={3}
          title={'Terminez et pilotez'}
          text={'Ajoutez des contacts, vérifiez quelques détails, puis créez votre premier ordre de service.'}
        />
      </Grid2>

      <Grid2
        container
        spacing={3}
        justifyContent={'center'}
        sx={{
          mt: '20px',
          position: 'sticky', // Rend les boutons "collants"
          bottom: 0, // Position en bas de la fenêtre si sticky s'applique
          bgcolor: 'white', // Fond blanc pour couvrir le contenu en dessous
          zIndex: 10, // S'assure que les boutons sont au premier plan
          boxShadow: '0 -2px 5px rgba(0,0,0,0)', // Ajoute une ombre douce si besoin
          pb: 2 // Ajoute du padding vertical pour éviter une hauteur trop compacte
        }}
      >
        <Grid2 size={12} sx={{ pt: '30px', textAlign: 'center' }}>
          <Button
            fullWidth={mediaQueries.matchesMd ? false : true}
            variant={'contained'}
            color={'secondary'}
            sx={{ color: 'white' }}
            onClick={() => {
              setCurrentStep(2);
            }}
          >
            Commencer
          </Button>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default PresentationStep;
