import { Grid2, FormControl } from '@mui/material';
import { DesktopDatePicker, DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

export const DatesForm = ({ documentData, setDocumentData, documentTypeLayout }) => {
  const handleDateChange = (date) => {
    const data = { ...documentData.data };
    data.date = date;
    setDocumentData({ ...documentData, data: data });
  };

  const handleEndDateChange = (date) => {
    const data = { ...documentData.data };
    data.endDate = date;
    setDocumentData({ ...documentData, data: data });
  };

  return (
    <>
      {documentData &&
        documentData.data &&
        documentTypeLayout &&
        (documentTypeLayout.needDate || documentTypeLayout.needDateTime || documentTypeLayout.needEndDate) && (
          <>
            {documentTypeLayout.needDate && (
              <Grid2 size={12} style={{ paddingBottom: '15px' }}>
                <FormControl fullWidth>
                  <DesktopDatePicker
                    label={
                      documentData && documentData.category && documentData.category === 'Diagnostics'
                        ? 'Date du rapport'
                        : 'Date'
                    }
                    value={documentData.data.date ? dayjs(documentData.data.date) : null}
                    onChange={handleDateChange}
                  />
                </FormControl>
              </Grid2>
            )}

            {documentTypeLayout.needDateTime && (
              <>
                <Grid2 size={12} style={{ paddingBottom: '15px' }}>
                  <FormControl fullWidth>
                    <DateTimePicker
                      label={
                        documentData && documentData.category && documentData.category === 'Diagnostics'
                          ? 'Date du rapport'
                          : 'Date'
                      }
                      value={documentData.data.date ? dayjs(documentData.data.date) : null}
                      onChange={handleDateChange}
                    />
                  </FormControl>
                </Grid2>
              </>
            )}
            {documentTypeLayout.needEndDate && (
              <>
                <Grid2 size={12} style={{ paddingBottom: '15px' }}>
                  <FormControl fullWidth>
                    <DesktopDatePicker
                      label="Date de fin"
                      value={documentData.data.endDate ? dayjs(documentData.data.endDate) : null}
                      onChange={handleEndDateChange}
                    />
                  </FormControl>
                </Grid2>
              </>
            )}
          </>
        )}
    </>
  );
};
