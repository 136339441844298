import { Grid2, Button, Typography, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useContext, useState } from 'react';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';
import _ from 'lodash';
import { AppContext } from '~/utils/context/AppContext';

const AddressDatasStep = ({
  setTitle,
  setCurrentStep,
  addressToAttach,
  setAddressToAttach,
  productDatas,
  setProductDatas
}) => {
  const mediaQueries = useContext(MediaQueryContext);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [constructionPeriode, setConstructionPeriode] = useState(null);
  const [yearToPeriodeArr, setYearToPeriodeArr] = useState(null);
  const { referencial } = useContext(AppContext);

  const defaultMinDate = dayjs('0100');
  const defaultMaxDate = dayjs(new Date().getFullYear().toString());

  useEffect(() => {
    if (constructionPeriode) {
      setYearToPeriodeArr(
        constructionPeriode.map((periode, index) => {
          if (periode.name.indexOf('Après') !== -1) {
            return [periode.name.split(' ')[0], Number(periode.name.split(' ')[1])];
          } else if (periode.name.indexOf('Avant') !== -1) {
            return [periode.name.split(' ')[0], Number(periode.name.split(' ')[1])];
          } else {
            return periode.name.split('-').map((x) => {
              return Number(x);
            });
          }
        })
      );
    }
  }, [constructionPeriode]);

  useEffect(() => {
    setMinDate(defaultMinDate);
    setMaxDate(defaultMaxDate);

    if (addressToAttach) {
      setProductDatas({ ...productDatas, uuid_address: addressToAttach.uuid });
    }
  }, []);

  useEffect(() => {
    setConstructionPeriode(_.sortBy(referencial['building.construction_period'], ['id']));
  }, [referencial]);

  useEffect(() => {
    setTitle('Confirmez la localisation du bien');
  }, []);

  return (
    <>
      <Grid2 size={12} sx={{ textAlign: 'center' }}>
        <Typography variant={mediaQueries.matchesSm ? 'h5' : 'h6'} sx={{ lineHeight: '1.3' }}>
          {'En précisant la localisation, vous aiderez les intervenants futurs à le situer ou s’y rendre si besoin. '}
        </Typography>
      </Grid2>

      <Grid2 container spacing={0} justifyContent={'center'} sx={{ mt: '50px' }}>
        {mediaQueries.matchesMd && <Grid2 size={4} sx={{ textAlign: 'center' }}></Grid2>}
        <Grid2 size={{ xs: 12, md: 4 }}>
          <Grid2 container spacing={3} justifyContent={'center'} sx={{}}>
            <Grid2 size={12} sx={{ textAlign: 'center' }}>
              <TextField
                // disabled={addressToAttach ? true : false}
                fullWidth
                autoComplete="off"
                label="Nom du bâtiment"
                size="small"
                value={productDatas?.address?.building_name}
                onChange={(e) => {
                  setProductDatas({
                    ...productDatas,
                    address: {
                      ...productDatas.address,
                      building_name: e.target.value
                    }
                  });
                }}
                required
              ></TextField>
            </Grid2>

            <Grid2 size={12} sx={{ textAlign: 'center' }}>
              <TextField
                fullWidth
                autoComplete="off"
                label="Complément d'adresse"
                size="small"
                placeholder='Ex: "étage: 2, porte: 3"'
                value={productDatas?.address_complement}
                onChange={(e) => {
                  setProductDatas({ ...productDatas, address_complement: e.target.value });
                }}
              ></TextField>
            </Grid2>

            <Grid2 size={12} sx={{ textAlign: 'center' }}>
              <TextField
                fullWidth
                autoComplete="off"
                label="Complément d'acces bâtiment"
                size="small"
                placeholder='Ex: "Bâtiment au fond à droit, code: A2351"'
                value={productDatas?.address?.building_access}
                onChange={(e) => {
                  setProductDatas({
                    ...productDatas,
                    address: {
                      ...productDatas.address,
                      building_access: e.target.value
                    }
                  });
                }}
              ></TextField>
            </Grid2>
          </Grid2>
        </Grid2>
        {mediaQueries.matchesMd && <Grid2 size={4} sx={{ textAlign: 'center' }}></Grid2>}
      </Grid2>

      <Grid2
        container
        spacing={3}
        justifyContent={'center'}
        sx={{
          mt: '20px',
          position: 'sticky', // Rend les boutons "collants"
          bottom: 0, // Position en bas de la fenêtre si sticky s'applique
          bgcolor: 'white', // Fond blanc pour couvrir le contenu en dessous
          zIndex: 10, // S'assure que les boutons sont au premier plan
          boxShadow: '0 -2px 5px rgba(0,0,0,0)', // Ajoute une ombre douce si besoin
          pb: 2 // Ajoute du padding vertical pour éviter une hauteur trop compacte
        }}
      >
        <Grid2 container spacing={3} justifyContent={'center'} sx={{ mt: '0px' }}>
          <Grid2 size={6} sx={{ textAlign: 'right' }}>
            <Button
              fullWidth={mediaQueries.matchesMd ? false : true}
              color={'primary'}
              sx={{ textDecoration: 'underline' }}
              onClick={() => {
                setCurrentStep(4);
              }}
            >
              {'Retour'}
            </Button>
          </Grid2>
          <Grid2 size={6} sx={{ textAlign: 'left' }}>
            <Button
              disabled={productDatas?.address?.building_name === ''}
              fullWidth={mediaQueries.matchesMd ? false : true}
              variant={'contained'}
              color={'primary'}
              sx={{ color: 'white' }}
              onClick={() => {
                setCurrentStep(6);
              }}
            >
              suivant
            </Button>
          </Grid2>
        </Grid2>
      </Grid2>
    </>
  );
};

export default AddressDatasStep;
