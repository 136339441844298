import {
  CardMedia,
  Checkbox,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField
} from '@mui/material';
import { useEffect, useState } from 'react';
import { IProduct } from '~/interfaces/product';
import { assignProductToUser, getProducts, getUsers, unAssignProductToUser } from '~/api';
import { appTheme } from '~/utils/theme';
import './Attribution.scss';
import auth from '~/app/components/Auth/AuthApi';
import normalizedString from '~/utils/normalizedString';

export const Attribution = ({ spinnerOpen, setSpinnerOpen }) => {
  const [users, setUsers] = useState([]);
  const [currentUserData, setCurrentUserData] = useState<any>({});
  const [usersMenuItems, setUsersMenuItems] = useState([]);
  const [displayedProductList, setDisplayedProductList] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [allProductList, setAllProductList] = useState([]);
  const [search, setSearch] = useState<string>('');
  const [notAsignedFilter, setNotAsignedFilter] = useState(false);
  const [filteredProductList, setFilteredProductList] = useState([]);
  const [productsAttribution, setProductsAttribution] = useState({});

  const handleSearch = (e) => {
    setSearch(e.currentTarget.value);
  };

  const handleChangeNotAssigned = (e) => {
    setNotAsignedFilter(e.target.checked);
  };

  const handleCheckAttribution = (e) => {
    if (e.target.checked) {
      assignProductToUser(selectedUser, e.target.id).then((resp) => {
        let fullName = '';
        users.forEach((user) => {
          if (user.uuid === selectedUser) {
            fullName = user.first_name + ' ' + user.last_name;
          }
        });

        const newUserEntry = {
          full_name: fullName,
          uuid_product: e.target.id,
          uuid_user: selectedUser
        };

        const newProductsList = [...allProductList];

        newProductsList.forEach((product) => {
          if (product.uuid === e.target.id) {
            if (product.users_in_charge) {
              let allReadyAdded = false;

              product.users_in_charge.forEach((user) => {
                if (user.uuid === selectedUser) {
                  allReadyAdded = true;
                }
              });

              if (!allReadyAdded) {
                product.users_in_charge.push(newUserEntry);
              }
            } else {
              product.users_in_charge = [];
              product.users_in_charge.push(newUserEntry);
            }
          }
        });

        setAllProductList(newProductsList);
      });
    } else {
      unAssignProductToUser(selectedUser, e.target.id).then((resp) => {
        const newProductsList = [...allProductList];

        newProductsList.forEach((product) => {
          if (product.uuid === e.target.id) {
            product.users_in_charge.forEach((user, index) => {
              if (user.uuid_user === selectedUser) {
                product.users_in_charge.splice(index, 1);
              }
            });
          }
        });

        setAllProductList(newProductsList);
      });
    }
  };

  const buildUsersItems = () => {
    setUsersMenuItems(
      users.map((user) => {
        return (
          <MenuItem key={user.uuid} value={user.uuid}>
            {user.first_name + ' ' + user.last_name}
          </MenuItem>
        );
      })
    );
  };

  const checkAssignedCurrentUser = (productUuid) => {
    let response = false;
    if (productsAttribution[productUuid]) {
      productsAttribution[productUuid].forEach((userUuid) => {
        if (userUuid === currentUserData.uuid_user) {
          response = true;
        }
      });
    }
    return response;
  };

  const checkUserLevel = () => {
    const response =
      currentUserData.user_kind === 'super-admin' || currentUserData.user_kind === 'client-admin' ? false : true;

    return response;
  };

  const productCard = (product: IProduct) => {
    let checked = false;
    const users = [];
    let isInMyWallet = false;
    let bgColor = '#ededed';

    if (product.users_in_charge) {
      product.users_in_charge.forEach((user) => {
        let meColor = '#CCC';
        const userName = user.full_name;
        if (user.uuid_user === selectedUser) {
          checked = true;
          meColor = appTheme.palette.primary['main'];
          bgColor = '#fff';
          isInMyWallet = true;
        }

        users.push(<div key={user.uuid_user} style={{ color: meColor }}>{`• ${userName}`}</div>);
      });
    }

    return (
      <Grid2
        container
        className="Attribution__product"
        justifyContent="space-between"
        key={product.uuid}
        sx={[
          {
            background:
              users.length === 0
                ? 'repeating-linear-gradient(-45deg,#ededed,#ededed 20px,#FFF 20px,#FFF 40px)'
                : bgColor,
            border: isInMyWallet ? `solid 1px ${appTheme.palette.primary['main']}` : 'solid 1px #CCC',
            boxShadow: isInMyWallet ? '0px 0px 6px 2px rgba(0, Ø, 0, 0.08)' : '0px 0px 6px 2px rgba(0, 0, 0, 0.08)'
          },
          {
            '&:hover': {
              boxShadow: '0px 0px 12px 4px rgba(0, 0, 0, 0.2)'
            }
          }
        ]}
        style={{ marginBottom: '30px' }}
      >
        <Grid2 size={3} className="Attribution__product-card__image">
          <CardMedia
            sx={{ minHeight: '150px', maxHeight: '200px' }}
            component="img"
            title={product.name}
            image={product.image_url}
          />
        </Grid2>
        <Grid2 size={7} className="Attribution__product-card__info">
          <Grid2 container spacing={2}>
            {product.address && (
              <Grid2 size={12} sx={{ marginTop: '10px' }} className="product-card__info__adesse">
                {`${product.address.street}, ${product.address.zip_code} ${product.address.city}`}
              </Grid2>
            )}
            <Grid2 size={12} className="product-card__info__responsable">
              {product.users_in_charge && product.users_in_charge.length > 0 ? users : 'Responsable(s) : non affecté'}
            </Grid2>
            {product?.identification?.id_internal && product?.identification?.id_internal !== null && (
              <Grid2 size={12} className="product-card__info__id">
                {`Ref : ${product?.identification?.id_internal}`}
              </Grid2>
            )}
          </Grid2>
        </Grid2>
        <Grid2 container justifyContent="center" alignItems="flex-end">
          <Grid2 size={12} className="product-card__checkbox">
            <Switch
              checked={checked ? true : checkAssignedCurrentUser(product.uuid)}
              id={product.uuid}
              color="primary"
              disabled={checkUserLevel()}
              onChange={handleCheckAttribution}
            />
          </Grid2>
        </Grid2>
      </Grid2>
    );
  };

  /////////////////////////////
  // USE EFFECTS
  //////////////////////////

  useEffect(() => {
    setCurrentUserData(auth.getJWT());
    getUsers().then((users) => {
      setUsers(users);
      setSpinnerOpen(false);
    });
    getProducts().then((products) => {
      setAllProductList(products);
    });
  }, []);

  useEffect(() => {
    if (users && users.length > 0) {
      if (currentUserData.user_kind === 'super-admin') {
        users.forEach((user) => {
          if (user.kind === 'client-admin') {
            setSelectedUser(user.uuid);
          }
        });
      } else {
        setSelectedUser(currentUserData.uuid_user);
      }
      buildUsersItems();
    }
  }, [users]);

  useEffect(() => {
    const allProducts = allProductList.map((product) => {
      return productCard(product);
    });
    setDisplayedProductList(allProducts);

    const assignedProducts = {};

    allProductList.forEach((product) => {
      const uuid = product.uuid.toString();
      assignedProducts[uuid] = [];
      if (product.users_in_charge) {
        product.users_in_charge.forEach((user) => {
          assignedProducts[product.uuid].push(user.uuid);
        });
      }
    });

    setProductsAttribution(JSON.parse(JSON.stringify(assignedProducts)));
    if (search === '') {
      setFilteredProductList([...allProductList]);
    } else {
      setFilteredProductList([...filteredProductList]);
    }
  }, [allProductList]);

  useEffect(() => {
    const products = filteredProductList.map((product) => {
      if (notAsignedFilter && product.users_in_charge && product.users_in_charge.length > 0) {
        return null;
      }
      return productCard(product);
    });
    setDisplayedProductList(products);
  }, [filteredProductList, selectedUser, notAsignedFilter]);

  useEffect(() => {
    const filteredList = allProductList.filter((item: IProduct) => {
      const normalizedSearch = normalizedString(search);

      return item.address
        ? normalizedString(item.address.street).indexOf(normalizedSearch) >= 0 ||
            normalizedString(item.address.city).indexOf(normalizedSearch) >= 0 ||
            normalizedString(item.address.zip_code).indexOf(normalizedSearch) >= 0 ||
            (item.tenant && normalizedString(item.tenant.name).indexOf(normalizedSearch) >= 0) ||
            (item.owner && normalizedString(item.owner.name).indexOf(normalizedSearch) >= 0) ||
            (item.identification?.id_internal &&
              normalizedString(item.identification?.id_internal).indexOf(normalizedSearch) >= 0)
        : item;
    });
    setFilteredProductList(filteredList);
  }, [search]);

  /////////////////////////////
  // RENDER
  //////////////////////////
  return (
    <Grid2 container spacing={0}>
      <Grid2 size={12} className="Attribution__container">
        <div>
          <div>
            <Grid2 container spacing={2} sx={{ paddingTop: '0px' }}>
              <Grid2 size={{ xs: 12, md: 6 }} sx={{ paddingTop: '0px' }}>
                <Grid2 container spacing={2}>
                  <Grid2 size={12}>
                    <div className="Attribution__title">{'Portefeuille de tous les biens '}</div>
                  </Grid2>
                  <Grid2 size={12}>
                    <TextField
                      variant="standard"
                      fullWidth
                      color="primary"
                      label="Rechercher"
                      value={search}
                      autoComplete="off"
                      onChange={handleSearch}
                    />
                  </Grid2>

                  <Grid2 size={12}>
                    <Checkbox
                      checked={notAsignedFilter}
                      id="notAssigned"
                      color="primary"
                      onChange={handleChangeNotAssigned}
                    />
                    <label htmlFor="notAssigned">Non encore attribué</label>
                  </Grid2>
                </Grid2>
              </Grid2>

              <Grid2 size={{ xs: 12, md: 6 }}>
                <Grid2 container spacing={2} direction="row" justifyContent="flex-end" alignItems="flex-end">
                  <Grid2 size={12}>
                    <div className="Attribution__title">{"Utilisateurs de l'organisation"}</div>
                  </Grid2>

                  <Grid2 size={12}>
                    <FormControl fullWidth>
                      <InputLabel>Selectionner un utilisateur</InputLabel>
                      <Select
                        labelId="users"
                        label="Selectionner un utilisateur"
                        value={selectedUser}
                        fullWidth
                        onChange={(e) => {
                          setSelectedUser(e.target.value);
                        }}
                      >
                        {usersMenuItems}
                      </Select>
                    </FormControl>
                  </Grid2>
                </Grid2>
              </Grid2>
            </Grid2>
          </div>
        </div>
      </Grid2>
      <Grid2 size={12} className="Attribution__container">
        <div style={{ paddingBottom: '20px' }}>{displayedProductList}</div>
      </Grid2>
    </Grid2>
  );
};
