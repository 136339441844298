import { Grid2 } from '@mui/material';
import './EnergyClass.scss';

interface EnergyClassProps {
  energy: number;
  co2: number;
}

export const EnergyClass = ({ energy, co2 }: EnergyClassProps) => {
  const CLASS: string[] = ['a', 'b', 'c', 'd', 'e', 'f', 'g'];

  const handleEnergy = (energy: number) => {
    if (energy && energy <= 70) {
      return 'a';
    } else if (energy >= 71 && energy <= 110) {
      return 'b';
    } else if (energy >= 111 && energy <= 180) {
      return 'c';
    } else if (energy >= 181 && energy <= 250) {
      return 'd';
    } else if (energy >= 251 && energy <= 330) {
      return 'e';
    } else if (energy >= 331 && energy <= 420) {
      return 'f';
    } else if (energy >= 421) {
      return 'g';
    }
  };
  const handleCo2 = (energy: number) => {
    if (energy && energy <= 5) {
      return 'a';
    } else if (energy >= 6 && energy <= 10) {
      return 'b';
    } else if (energy >= 11 && energy <= 20) {
      return 'c';
    } else if (energy >= 21 && energy <= 35) {
      return 'd';
    } else if (energy >= 36 && energy <= 55) {
      return 'e';
    } else if (energy >= 56 && energy <= 80) {
      return 'f';
    } else if (energy >= 80) {
      return 'g';
    }
  };

  const getPixelWidth = (energy: string): number => {
    if (energy === 'a') {
      return 20;
    } else if (energy === 'b') {
      return 29;
    } else if (energy === 'c') {
      return 38;
    } else if (energy === 'd') {
      return 47;
    } else if (energy === 'e') {
      return 56;
    } else if (energy === 'f') {
      return 65;
    } else if (energy === 'g') {
      return 74;
    }
  };

  const renderEnergyRow = (item, energy) => {
    const isAfterActive = item && item > handleEnergy(energy);
    return (
      <div
        className={`EnergyClass__energy__${item} ${item === handleEnergy(energy) ? 'active' : ''}`}
        style={{ width: isAfterActive ? getPixelWidth(item) + 13 + 'px' : getPixelWidth(item) + 'px' }}
      >
        <label>{item.toUpperCase()} </label>
      </div>
    );
  };

  const renderCO2Row = (item, co2) => {
    const isAfterActive = item && item > handleCo2(co2);
    return (
      <div
        className={`EnergyClass__co2__${item} ${item === handleCo2(co2) ? 'active' : ''}`}
        style={{ width: isAfterActive ? getPixelWidth(item) + 13 + 'px' : getPixelWidth(item) + 'px' }}
      >
        <label>{item.toUpperCase()}</label>
      </div>
    );
  };

  return (
    <div>
      <Grid2 container spacing={0}>
        <Grid2 size={6}>
          <div className="EnergyClass__energy">
            {CLASS.map((item: string, key: number) => (
              <div
                className={`EnergyClass__row ${item === handleEnergy(energy) ? 'row_active' : ''}`}
                key={`class_energy_${key}`}
              >
                {item === handleEnergy(energy) ? (
                  <div className="EnergyClass__energy__active">
                    <div className="EnergyClass__energy__active__ergy">
                      <div>{Math.floor(energy)}</div>
                      <span>
                        kWh/m<sup>2</sup>
                      </span>
                    </div>
                    <div className="EnergyClass__energy__active__co2">
                      <div>{Math.floor(co2)}</div>
                      <span>
                        kgCO<sub>2</sub>/m<sup>2</sup>
                      </span>
                    </div>
                  </div>
                ) : (
                  <div style={{ width: '70px' }}></div>
                )}
                {renderEnergyRow(item, energy)}
              </div>
            ))}
          </div>
        </Grid2>
        <Grid2 size={6}>
          <div className="EnergyClass__co2">
            {CLASS.map((item: string, key: number) => (
              <div className={`EnergyClass__row`} key={`class_energy_${key}`}>
                {renderCO2Row(item, co2)}
                {item === handleCo2(co2) && (
                  <div className="EnergyClass__co2__active">
                    <div style={{ position: 'relative', top: '6px' }}>{Math.floor(co2)} </div>
                    <span style={{ position: 'relative', top: '4px' }}>
                      kgCO<sub>2</sub>/m<sup>2</sup>.an
                    </span>
                  </div>
                )}
              </div>
            ))}
          </div>
        </Grid2>
      </Grid2>
    </div>
  );
};

export default EnergyClass;
