import { InputAdornment, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { api } from '~/api';
import SearchIcon from './SearchIcon';

const AdemeSearchStep = ({ resetSearchField, setResetSearchField, setAdemeDatas }) => {
  const [dpeNumber, setDpeNumber] = useState('');
  const [error, setError] = useState(false);
  const [searchDpeResult, setSearchDpeResult] = useState('');

  const handleKeyPress = (event) => {
    setError(false); // Réinitialise l'erreur si la condition est respectée
    setSearchDpeResult(''); // Réinitialise le résultat de la recherche
    if (event.key === 'Enter') {
      checkDpeNumberValidity();
    }
    setResetSearchField('google-places');
  };

  useEffect(() => {
    if (resetSearchField === 'ademe') {
      setDpeNumber('');
      setError(false);
      setSearchDpeResult('');
      setResetSearchField('');
    }
  }, [resetSearchField]);

  const checkDpeNumberValidity = () => {
    console.log(dpeNumber);
    if (dpeNumber.length !== 13) {
      setError(true); // Active le message d'erreur
    } else {
      setError(false); // Réinitialise l'erreur si la condition est respectée
      searchAdeme();
    }
  };

  const searchAdeme = async () => {
    console.log('SEARCH ADEME !!!!');
    try {
      const ademeResult = await api.ademe.get(dpeNumber);
      console.log(ademeResult);
      setAdemeDatas(ademeResult.body);

      setSearchDpeResult(
        `${ademeResult.body.address.Street} ${ademeResult.body.address.ZipCode} ${ademeResult.body.address.City} `
      );
    } catch (error) {
      console.log(error);
      setSearchDpeResult('Aucun résultat trouvé');
    } finally {
      console.log('finally');
    }
  };

  return (
    <>
      <TextField
        autoComplete="off"
        fullWidth
        variant="outlined"
        placeholder="Numéro de DPE"
        onKeyDown={handleKeyPress}
        value={dpeNumber}
        onChange={(e) => setDpeNumber(e.target.value)} // Met à jour la valeur du champ
        error={error} // Active l'état visuel d'erreur
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                checkDpeNumberValidity();
              }}
            >
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />

      <Typography variant="body2" sx={{ mt: 1, color: error ? 'red' : 'text.secondary' }}>
        {searchDpeResult === '' ? 'Le numéro doit comporter 13 caractères.' : 'Aucune donnée trouvée...'}
      </Typography>
    </>
  );
};

export default AdemeSearchStep;
