import React, { useEffect, useState, useContext } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  Autocomplete,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  Grid2
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { api } from '~/api';
import { WizzardContact } from '~/app/components/WizzardContact/WizzardContact';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { AppContext } from '~/utils/context/AppContext';
import { format } from 'date-fns';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';

export const WizzardMissionOrderEdl = ({
  nextActiveStepViewer,
  previousActiveStepViewer,
  productSelected,
  previousStep,
  closeModal,
  isFurnished,
  buildingAccess,
  allContactsOrga,
  leases
}) => {
  const mediaQueries = useContext(MediaQueryContext);

  const [isEdlIn, setIsEdlIn] = useState(false);
  const [isEdlOut, setIsEdlOut] = useState(false);

  const [edlFormStep, setEdlFormStep] = useState(0);

  const [isEdl3D, setIsEdl3D] = useState(false);
  const [isEdlSimple, setIsEdlSimple] = useState(false);

  const [needScan, setNeedScan] = useState(false);

  const [hasScan, setHasScan] = useState(false);

  const [isFurnishedForm, setIsFurnishedForm] = useState(isFurnished);
  const [needInventory, setNeedInventory] = useState(false);

  const [refFolderEdl, setRefFolderEdl] = useState('');

  const [endLease, setEndLease] = React.useState<Dayjs | null>();
  const [startLease, setStartLease] = React.useState<Dayjs | null>();

  const [autocompleteComponents, setAutocompleteComponents] = useState([{ id: 0, value: null }]);
  const [residents, setResidents] = useState([]);

  const [allContactsOrgaForm, setAllContactsOrgaForm] = useState(allContactsOrga);

  const [addContact, setAddContact] = useState<boolean>(false);

  const [edlExpectedDate, setEdlExpectedDate] = React.useState<Dayjs | null>();

  const [contact, setContact] = useState(null);

  const [buildingAccessForm, setBuildingAccessForm] = useState(buildingAccess);

  const [commentary, setCommentary] = useState('');

  const [internAttribution, setInternAttribution] = useState(false);

  const [externAttribution, setExternAttribution] = useState(false);

  const [allUsersOrganization, setAllUsersOrganization] = useState([]);

  const [techEdl, setTechEdl] = useState(null);

  const [allTechs, setAllTechs] = useState([]);

  const [edlData, setEdlData] = useState({});

  const [kindEdl, setKindEdl] = useState(0);

  const [allContacts, setAllContacts] = useState([]);

  const [missionCreated, setMissionCreated] = useState(false);

  const [openHelperTextRefFolder, setOpenHelperTextRefFolder] = useState(false);

  const [othersEdlSameProduct, setOthersEdlSameProduct] = useState([]);

  const [externAssignation, setExternAssignation] = useState(false);

  const [typeLease, setTypeLease] = useState<number>(null);

  const [durationLease, setDurationLease] = useState<number>(null);

  const [selectedOption, setSelectedOption] = useState(null);

  const [createLease, setCreateLease] = useState(false);

  const [lease, setLease] = useState(null);

  const [leasesInProgess, setLeasesInProgress] = useState([]);

  const { referencial } = useContext(AppContext);

  const handleIsEdlIn = () => {
    setIsEdlIn(true);
    setIsEdlOut(false);
  };
  const handleIsEdlOut = () => {
    setIsEdlOut(true);
    setIsEdlIn(false);
  };

  const nextEdlStep = () => {
    setEdlFormStep(edlFormStep + 1);
  };
  const previousEdlStep = () => {
    setEdlFormStep(edlFormStep - 1);
  };

  const goEdlStep = (edlFormStep) => {
    setEdlFormStep(edlFormStep);
  };

  const handleIsEdl3D = () => {
    setIsEdl3D(true);
    setIsEdlSimple(false);
  };
  const handleIsEdlSimple = () => {
    setIsEdlSimple(true);
    setIsEdl3D(false);
  };

  const handleNeedScan = () => {
    setNeedScan(!needScan);
  };

  const handleNeedInventory = (event) => {
    if (event.target.value === 'true') {
      setNeedInventory(true);
    } else {
      setNeedInventory(false);
    }
  };

  const handleRefFolder = (event) => {
    setRefFolderEdl(event.target.value);
  };

  const handleStartLease = (date) => {
    setStartLease(date);
  };

  const handleEndLease = (date) => {
    setEndLease(date);
  };

  const handleAddAutocomplete = () => {
    const lastId = autocompleteComponents[autocompleteComponents.length - 1].id;
    setAutocompleteComponents([...autocompleteComponents, { id: lastId + 1, value: null }]);
  };

  const handleRemoveAutocomplete = (index) => {
    if (autocompleteComponents.length <= 1) {
      return;
    }

    const updatedComponents = [...autocompleteComponents];
    updatedComponents.splice(index, 1);
    setAutocompleteComponents(updatedComponents);

    const updatedResidents = [...residents];
    updatedResidents.splice(index, 1);
    setResidents(updatedResidents);
  };

  const handleResidents = (e, newValue, index) => {
    const updatedResidents = [...residents];

    if (newValue) {
      updatedResidents[index] = newValue;
    } else {
      updatedResidents.splice(index, 1);
    }
    setResidents(updatedResidents);
  };

  const handleDateEdl = (date) => {
    setEdlExpectedDate(date);
  };

  const handleContact = (e, newValue) => {
    if (!newValue) {
      setContact(null);
    } else {
      setContact(newValue);
    }
  };

  const handleBuildingCode = (e) => {
    setBuildingAccessForm(e.target.value);
  };

  const handleCommentary = (e) => {
    setCommentary(e.target.value);
  };

  const handleInternAttribution = () => {
    setInternAttribution(!internAttribution);
    setExternAttribution(false);
  };

  const handleExternAttribution = () => {
    setExternAttribution(!externAttribution);
    setInternAttribution(false);
  };

  const handleAssignTechEDL = (e, newValue) => {
    if (!newValue) return '';
    setTechEdl(newValue);
  };

  const generateRefFolder = (productSelected) => {
    let ext = '';
    if (isEdlIn && isEdlSimple) {
      ext = 'edl_entree';
    } else if (isEdlIn && isEdl3D) {
      ext = 'edl_3d_entree';
    } else if (isEdlOut && isEdlSimple) {
      ext = 'edl_sortie';
    } else if (isEdlOut && isEdl3D) {
      ext = 'edl_3d_sortie';
    }
    const refFolder = `${productSelected?.id_internal}-${ext}`;
    setRefFolderEdl(refFolder);
    return null;
  };

  const handleOpenHelperTextRefFolder = () => {
    setOpenHelperTextRefFolder(!openHelperTextRefFolder);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const theDate = format(date, 'dd/MM/yyyy');
    return theDate;
  };

  useEffect(() => {
    if (!productSelected) return;
    const tempArray = [];
    api.product.contact.get(productSelected.uuid).then((res) => {
      if (res) {
        setAllContacts(res);
        res.forEach((contact) => {
          if (contact.kind === 2) {
            tempArray.push(contact);
          }
        });
      }
    });
    setResidents(tempArray);
    if (tempArray.length > 1) {
      setAutocompleteComponents(
        tempArray.map((item, index) => {
          return { id: index, value: item };
        })
      );
    }
  }, [productSelected]);

  useEffect(() => {
    if (edlFormStep === 3) {
      generateRefFolder(productSelected);
    }
  }, [edlFormStep, productSelected]);

  useEffect(() => {
    api.missionOrders.getTechnicians().then((res) => {
      setAllTechs(res.technicians);
    });

    api.organizations.get(productSelected?.address.uuid_organization).then((res) => {
      setAllUsersOrganization(res.users);
    });
  }, []);

  useEffect(() => {
    if (productSelected?.matterport_scan) {
      setHasScan(true);
    }
  }, [productSelected]);

  useEffect(() => {
    setTechEdl(null);
  }, [internAttribution, externAttribution]);

  useEffect(() => {
    if (isEdlIn && isEdlSimple) {
      setKindEdl(1);
    } else if (isEdlIn && isEdl3D) {
      setKindEdl(3);
    } else if (isEdlOut && isEdlSimple) {
      setKindEdl(2);
    } else if (isEdlOut && isEdl3D) {
      setKindEdl(4);
    }
  }, [isEdlIn, isEdlOut, isEdlSimple, isEdl3D]);

  useEffect(() => {
    api.missionOrders.search().then((response) => {
      const filteredOdm = response.filter((item) => item.kind === 1 && item.uuid_property === productSelected.uuid);
      setOthersEdlSameProduct(filteredOdm);
    });
  }, [productSelected.uuid]);

  useEffect(() => {
    if (lease) {
      setTypeLease(lease?.kind);
      setDurationLease(lease?.duration);
      setStartLease(dayjs(lease?.start_at));
      setEndLease(dayjs(lease?.end_at));
      setResidents(lease?.contacts);
      setAutocompleteComponents(
        lease?.contacts.map((item, index) => {
          return { id: index, value: item };
        })
      );
    } else {
      setTypeLease(null);
      setDurationLease(null);
      setStartLease(null);
      setEndLease(null);
      setResidents([]);
      setAutocompleteComponents([{ id: 0, value: null }]);
    }
  }, [lease]);

  const handleLease = (event) => {
    setTypeLease(event.target.value);
    if (event.target.value === 2) {
      setIsFurnishedForm(true);
    } else {
      setIsFurnishedForm(false);
    }
  };

  const handleDurationLease = (event) => {
    setDurationLease(event.target.value);
  };

  const handleLeases = () => {
    const activeLeases = leases.filter((lease) => {
      return lease.status === 2;
    });

    if (activeLeases.length === 0) {
      setCreateLease(true);
    } else {
      setCreateLease(false);
      setLeasesInProgress(activeLeases);
    }
  };
  useEffect(() => {
    handleLeases();
  }, [leases]);

  useEffect(() => {
    if (isEdl3D && !hasScan) {
      handleNeedScan();
    }
  }, [edlFormStep]);

  const handleAssignation = async (idOdm: string, idUser: string) => {
    await api.missionOrders.assign(idOdm, idUser).then((response) => setMissionCreated(true));
  };

  const handleCloseMissionCreated = () => {
    setMissionCreated(false);
    closeModal();
  };

  const handleCreateLease = async () => {
    const leasePayload = {
      kind: typeLease,
      start_at: startLease.format(),
      end_at: endLease.format(),
      duration: Math.floor(durationLease),
      uuid_contacts: residents.map((item) => item.uuid),
      uuid_product: productSelected.uuid
    };

    api.lease
      .create(leasePayload)
      .then((response) => {
        createMissionOrderEdl(response.uuid);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const createMissionOrderEdl = async (uuidLease: string) => {
    const edlPayload = {
      expected_date: edlExpectedDate.format(),
      ref_internal: refFolderEdl,
      kind: 1,
      data: {
        with_inventory: needInventory,
        is_furnished: isFurnishedForm,
        building_access: buildingAccessForm,
        residents: residents,
        end_lease: endLease.format(),
        start_lease: startLease.format(),
        matterport_scan_to_create: needScan,
        edl_kind: kindEdl,
        contact: contact,
        commentary: commentary,
        uuid_lease: uuidLease
      },
      uuid_property: productSelected.uuid
    };
    setEdlData(edlPayload);

    await api.missionOrders
      .create(edlPayload)
      .then(async (responseEdl) => {
        const leasePayload = {
          kind: typeLease,
          start_at: startLease.format(),
          end_at: endLease.format(),
          duration: Math.floor(durationLease),
          uuid_contacts: residents.map((item) => item.uuid),
          uuid_product: productSelected.uuid,
          status: isEdlOut ? 3 : 2
        };
        if (lease) {
          await api.lease.update(lease.uuid, leasePayload);
        }
        if (externAssignation) {
          handleAssignation(responseEdl.uuid, techEdl?.users[0].uuid);
        } else {
          handleAssignation(responseEdl.uuid, techEdl?.uuid);
        }
        if (needScan) {
          const refFolderScan = `${productSelected?.id_internal}-scan_edl`;

          const scanPayload = {
            expected_date: edlExpectedDate.format(),
            ref_internal: refFolderScan,
            kind: 2,
            data: {
              is_furnished: isFurnishedForm,
              building_code: buildingAccessForm,
              contact: contact,
              commentary: commentary
            },
            uuid_property: productSelected.uuid
          };
          await api.missionOrders
            .create(scanPayload)
            .then((responseScan) => {
              api.missionOrders.odmLink(responseScan.uuid, responseEdl.uuid);
            })
            .catch((error) => {});
        }
      })
      .catch((error) => {});
  };

  return (
    <Grid2 container spacing={2} sx={{ ml: '.1vw' }}>
      <Grid2 size={12}>
        <Typography variant="h6" sx={{ color: 'primary.main' }}>
          Choix de service :
        </Typography>
      </Grid2>
      {edlFormStep !== 3 && edlFormStep !== 4 && edlFormStep !== 5 && (
        <Grid2 size={12} sx={{ mt: '1vh' }}>
          <Chip
            label="Etat des lieux"
            variant="filled"
            sx={{
              color: 'white',
              backgroundColor: '#929292',
              whiteSpace: 'nowrap',
              textAlign: 'center'
            }}
          />
          {isEdlIn && (
            <Chip
              label="Entrée"
              variant="filled"
              sx={{
                color: 'white',
                backgroundColor: '#929292',
                whiteSpace: 'nowrap',
                textAlign: 'center',
                ml: '1vw'
              }}
            />
          )}
          {isEdlOut && (
            <Chip
              label="Sortie"
              variant="filled"
              sx={{
                color: 'white',
                backgroundColor: '#929292',
                whiteSpace: 'nowrap',
                textAlign: 'center',
                ml: '1vw'
              }}
            />
          )}
          {isEdl3D && (
            <Chip
              label="EDL 3D"
              variant="filled"
              sx={{
                color: 'white',
                backgroundColor: '#929292',
                whiteSpace: 'nowrap',
                textAlign: 'center',
                ml: '1vw'
              }}
            />
          )}
          {isEdlSimple && (
            <Chip
              label="EDL Simple"
              variant="filled"
              sx={{
                color: 'white',
                backgroundColor: '#929292',
                whiteSpace: 'nowrap',
                textAlign: 'center',
                ml: '1vw'
              }}
            />
          )}
        </Grid2>
      )}

      {edlFormStep === 0 && (
        <>
          <Grid2 size={12} sx={{ mt: mediaQueries?.matchesMd ? '2vh' : '1vh' }}>
            <Typography variant="h6">Pour une :</Typography>
          </Grid2>
          <Grid2 size={{ xs: 3, md: 'auto' }}>
            <Button
              variant={isEdlIn ? 'contained' : 'outlined'}
              color={isEdlIn ? 'secondary' : 'primary'}
              sx={{
                width: '6vw',
                color: isEdlIn ? 'white' : 'primary.main',
                borderRadius: '10px',
                borderColor: 'lightgrey'
              }}
              onClick={handleIsEdlIn}
            >
              Entrée
            </Button>
          </Grid2>
          <Grid2 size={{ xs: 3, md: 'auto' }}>
            <Button
              variant={isEdlOut ? 'contained' : 'outlined'}
              color={isEdlOut ? 'secondary' : 'primary'}
              sx={{
                width: '6vw',
                color: isEdlOut ? 'white' : 'primary.main',
                borderRadius: '10px',
                borderColor: 'lightgrey'
              }}
              onClick={handleIsEdlOut}
            >
              Sortie
            </Button>
          </Grid2>
        </>
      )}

      {edlFormStep === 1 && (
        <>
          <Grid2 size={12} sx={{ mt: '2vh' }}>
            <Typography variant="h6">Choix du type d'état des lieux :</Typography>
          </Grid2>

          <>
            <Grid2 size={{ xs: 3, md: 'auto' }}>
              <Button
                variant={isEdl3D ? 'contained' : 'outlined'}
                color={isEdl3D ? 'secondary' : 'primary'}
                sx={{ color: isEdl3D ? 'white' : 'primary.main', borderRadius: '10px', borderColor: 'lightgrey' }}
                onClick={handleIsEdl3D}
              >
                EDL 3D
              </Button>
            </Grid2>
            <Grid2 size={{ xs: 4, md: 'auto' }}>
              <Button
                variant={isEdlSimple ? 'contained' : 'outlined'}
                color={isEdlSimple ? 'secondary' : 'primary'}
                onClick={handleIsEdlSimple}
                sx={{ color: isEdlSimple ? 'white' : 'primary.main', borderRadius: '10px', borderColor: 'lightgrey' }}
              >
                EDL Simple
              </Button>
            </Grid2>
          </>

          {isEdl3D && (
            <>
              {hasScan ? (
                <>
                  <Grid2 size={10} sx={{ mt: '2vh' }}>
                    <Typography variant={mediaQueries?.matchesMd ? 'h6' : 'body2'}>
                      Une modélisation 3D datant du {''}
                      {dayjs(productSelected?.matterport_scan?.scanned_at).format('DD/MM/YYYY')} existe pour ce bien.
                    </Typography>
                    <Typography variant="h6">Voulez-vous la mettre à jour ? </Typography>
                  </Grid2>
                  <Grid2 size={12}>
                    <FormControl component="fieldset">
                      <RadioGroup row aria-label="position" name="position" value={needScan} onChange={handleNeedScan}>
                        <FormControlLabel value={true} control={<Radio color="primary" />} label="Oui" />
                        <FormControlLabel value={false} control={<Radio color="primary" />} label="Non" />
                      </RadioGroup>
                    </FormControl>
                  </Grid2>
                </>
              ) : (
                <>
                  <Grid2 size={{ xs: 12, md: 12 }} />
                  <Grid2 size={{ xs: 1.5, md: 0.65 }}>
                    <Checkbox checked={needScan} onChange={handleNeedScan} color="primary" />
                  </Grid2>
                  <Grid2 size={10.5}>
                    <Typography variant={mediaQueries?.matchesMd ? 'h6' : 'body2'}>
                      Ce bien n'est pas encore doté d'une modélisation 3D.
                    </Typography>
                    <Typography variant={mediaQueries?.matchesMd ? 'h6' : 'body2'}>
                      Une mission de scan 3D sera programmée,
                    </Typography>
                    <Typography variant={mediaQueries?.matchesMd ? 'h6' : 'body2'}>
                      et devra être réalisée avant l'état des lieux.
                    </Typography>
                  </Grid2>
                </>
              )}
            </>
          )}
        </>
      )}

      {edlFormStep === 2 && (
        <>
          {leasesInProgess.length > 0 && (
            <>
              <Grid2 size={12} sx={{ mt: mediaQueries?.matchesMd ? '2vh' : '1vh' }}>
                {leasesInProgess.length > 1 ? (
                  <Typography variant="h6">
                    Nous avons trouvé des baux en cours à cette adresse, souhaitez-vous y rattacher l’état des lieux ?
                  </Typography>
                ) : (
                  <Typography variant="h6">
                    Nous avons trouvé un bail en cours à cette adresse, souhaitez-vous y rattacher l’état des lieux ?
                  </Typography>
                )}
              </Grid2>
              <Grid2 size={12}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="leases"
                    name="leases"
                    value={selectedOption}
                    onChange={(event) => {
                      const value = event.target.value;
                      setSelectedOption(value);

                      if (value === 'new') {
                        setCreateLease(true);
                        setLease(null);
                      } else {
                        const selectedLease = leasesInProgess.find((lease) => lease.uuid === value);
                        setLease(selectedLease);
                        setCreateLease(false);
                      }
                    }}
                  >
                    {leasesInProgess.map((lease, index) => {
                      const kindName =
                        referencial['lease.kind'].find((refLease) => refLease.id === lease?.kind)?.name || 'Inconnu';

                      const contactNames =
                        lease.contacts?.map((contact) => contact.name).join(', ') || 'Contacts inconnus';

                      return (
                        <FormControlLabel
                          key={index}
                          value={lease.uuid}
                          control={<Radio />}
                          label={`${kindName} - ${contactNames} - ${formatDate(
                            lease.start_at
                          )} - ${formatDate(lease.end_at)}`}
                        />
                      );
                    })}
                    <FormControlLabel value="new" control={<Radio />} label="Créer un nouveau bail" />
                  </RadioGroup>
                </FormControl>
              </Grid2>
            </>
          )}

          <Grid2 size={12} sx={{ mt: '1vh' }}>
            <Typography variant="h6">Faut-il réaliser un inventaire ?</Typography>
          </Grid2>
          <Grid2 size={6}>
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                value={needInventory}
                onChange={handleNeedInventory}
              >
                <FormControlLabel value={true} control={<Radio color="primary" />} label="Oui" />
                <FormControlLabel value={false} control={<Radio color="primary" />} label="Non" />
              </RadioGroup>
            </FormControl>
          </Grid2>
        </>
      )}

      {edlFormStep === 3 && (
        <Grid2
          container
          spacing={2}
          sx={{
            mt: '0.25vh',
            overflowY: 'auto',
            height: mediaQueries?.matchesMd ? '70vh' : '55vh',
            ml: '0.1vw',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none'
            }
          }}
        >
          <Grid2 size={12}>
            <Typography variant="h4" sx={{ color: 'primary.main' }}>
              Informations nécessaires pour la réalisation de l’état des lieux :
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 5.3 }} display={'flex'} alignItems={'center'}>
            <TextField
              label="Référence du dossier *"
              variant="outlined"
              size="small"
              fullWidth
              value={refFolderEdl}
              onChange={handleRefFolder}
            />
            <IconButton size="small" color="primary" sx={{ ml: '1.5vw' }} onClick={handleOpenHelperTextRefFolder}>
              <HelpOutlineIcon fontSize="large" />
            </IconButton>
          </Grid2>
          <Grid2 size={12}>
            <Grid2 size={{ xs: 10.5, md: 4.5 }}>
              <FormControl fullWidth>
                <InputLabel id="type-bail-label" size="small">
                  Régime locatif
                </InputLabel>
                <Select
                  labelId="type-bail-label"
                  value={typeLease}
                  onChange={handleLease}
                  required
                  label="Régime locatif"
                  size="small"
                >
                  {referencial['lease.kind']?.map((lease, index) => (
                    <MenuItem key={index} value={lease.id}>
                      {lease.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid2>
          </Grid2>
          <Grid2 size={{ xs: 5.2, md: 4.51 }} sx={{ mt: '1vh' }}>
            <TextField
              label="Durée du bail (mois)"
              variant="outlined"
              size="small"
              fullWidth
              onChange={handleDurationLease}
              type="number"
              value={durationLease}
            >
              {durationLease}
            </TextField>
          </Grid2>
          {!mediaQueries?.matchesMd && <Grid2 size={6} />}
          <Grid2 size={{ xs: 6, md: 12 }} sx={{ mt: '1vh' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
              <DatePicker
                label="Début de bail *"
                value={startLease}
                onChange={(date) => handleStartLease(date)}
                format="DD/MM/YYYY"
                sx={{ width: mediaQueries?.matchesMd ? '21.1vw' : '28vw' }}
              />
            </LocalizationProvider>
          </Grid2>
          <Grid2 size={{ xs: 4, md: 12 }} sx={{ mt: '1vh' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
              <DatePicker
                value={endLease}
                onChange={(date) => handleEndLease(date)}
                format="DD/MM/YYYY"
                label="Fin de bail *"
                sx={{ width: mediaQueries?.matchesMd ? '21.1vw' : '28vw' }}
              />
            </LocalizationProvider>
          </Grid2>
          <Grid2 size={12} sx={{ mt: '1vh' }}>
            <Typography variant="h6">
              {isEdlIn ? 'Locataire(s) entrant(s) * :' : 'Locataire(s) sortant(s) * :'}
            </Typography>
          </Grid2>
          {autocompleteComponents.map((component, index) => (
            <React.Fragment key={index}>
              <Grid2 size={{ xs: 11, md: 8 }}>
                <Autocomplete
                  options={allContactsOrgaForm}
                  getOptionLabel={(option) => option?.name || ''}
                  size="small"
                  onChange={(e, newValue) => handleResidents(e, newValue, index)}
                  isOptionEqualToValue={(option, value) => option?.name === value?.name}
                  value={residents[index] || null}
                  renderInput={(params) => <TextField {...params} label="Rechercher un locataire" variant="outlined" />}
                  noOptionsText="Aucun locataire trouvé"
                  sx={{ width: mediaQueries?.matchesMd ? '21.1vw' : '76vw' }}
                />
              </Grid2>
              {index === autocompleteComponents.length - 1 && (
                <Grid2 size={{ xs: 8, md: 12 }}>
                  {autocompleteComponents.length > 1 && (
                    <Button onClick={() => handleRemoveAutocomplete(index)}>
                      <PersonRemoveIcon color="secondary" />
                    </Button>
                  )}
                  <Button onClick={handleAddAutocomplete}>
                    <PersonAddIcon color="primary" />
                  </Button>
                </Grid2>
              )}
            </React.Fragment>
          ))}
          <Grid2 size={12}>
            <Button
              variant="contained"
              sx={{ borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => setAddContact(!addContact)}
            >
              <AddOutlinedIcon /> Créer un nouveau contact
            </Button>
          </Grid2>
        </Grid2>
      )}

      {edlFormStep === 4 && (
        <React.Fragment>
          <Grid2 size={12}>
            <Typography variant={mediaQueries?.matchesMd ? 'h4' : 'h5'} sx={{ color: 'primary.main' }}>
              Informations nécessaires pour la réalisation de l’état des lieux :
            </Typography>
          </Grid2>
          <Grid2 size={12}>
            <Typography variant={mediaQueries?.matchesMd ? 'h5' : 'h6'}>
              DATE SOUHAITÉE POUR LA RÉALISATION DE L’ÉTAT DES LIEUX :
            </Typography>
          </Grid2>

          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
            <Grid2 size={{ xs: 10, md: 4 }}>
              <DesktopDatePicker
                format="DD/MM/YYYY"
                value={edlExpectedDate}
                onChange={(date) => handleDateEdl(date)}
                label="Date *"
                sx={{ width: mediaQueries?.matchesMd ? '9vw' : '33vw', height: '3vh' }}
              />
            </Grid2>
          </LocalizationProvider>
          <Grid2 size={12} sx={{ mt: '3vh' }}>
            <Typography variant={mediaQueries?.matchesMd ? 'h5' : 'h6'}>
              Personne à contacter pour la prise de rendez-vous :
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 3.5 }}>
            <Autocomplete
              options={allContactsOrgaForm}
              getOptionLabel={(option) => option?.name || ''}
              onChange={handleContact}
              renderInput={(params) => <TextField {...params} label="Nom du contact *" variant="outlined" />}
              size="small"
              noOptionsText="Aucun contact trouvé"
              isOptionEqualToValue={(option, value) => option?.name === value}
              value={contact}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Button
              variant="contained"
              sx={{ borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              onClick={() => setAddContact(!addContact)}
            >
              <AddOutlinedIcon /> Créer un nouveau contact
            </Button>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 3.5 }}>
            <TextField
              label="Accès immeuble"
              variant="outlined"
              size="small"
              fullWidth
              value={buildingAccessForm}
              onChange={handleBuildingCode}
            />
          </Grid2>
          {mediaQueries?.matchesMd && <Grid2 size={12} sx={{ mt: '3vh' }} />}

          <Grid2 size={{ xs: 12, md: 6 }}>
            <TextField
              label="Commentaires"
              multiline
              rows={2}
              placeholder="Où se trouvent les clés, Où se trouvent les compteurs"
              variant="outlined"
              size="small"
              fullWidth
              value={commentary}
              onChange={handleCommentary}
            />
          </Grid2>
        </React.Fragment>
      )}

      {edlFormStep === 5 && (
        <React.Fragment>
          <Grid2 size={12}>
            <Typography variant={mediaQueries?.matchesMd ? 'h4' : 'h5'} sx={{ color: 'primary.main' }}>
              ATTRIBUER LA MISSION :
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 2.5 }}>
            <Button
              variant={internAttribution ? 'contained' : 'outlined'}
              color={internAttribution ? 'secondary' : 'primary'}
              sx={{
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                color: internAttribution ? 'white' : 'primary.main',
                borderColor: 'lightgrey'
              }}
              onClick={handleInternAttribution}
            >
              A UN COLLABORATEUR EN INTERNE
            </Button>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 2.5 }} sx={{ mb: '4vh' }}>
            <Button
              variant={externAttribution ? 'contained' : 'outlined'}
              color={externAttribution ? 'secondary' : 'primary'}
              sx={{
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center',
                color: externAttribution ? 'white' : 'primary.main',
                borderColor: 'lightgrey'
              }}
              onClick={handleExternAttribution}
            >
              A UN PRESTATAIRE EXTERIEUR
            </Button>
          </Grid2>
          {internAttribution && (
            <>
              <Grid2 size={12}>
                <Typography variant={mediaQueries?.matchesMd ? 'h5' : 'h6'} sx={{ color: 'primary' }}>
                  ATTRIBUER LA MISSION EDL A UN COLLABORATEUR
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 11, md: 5 }}>
                <Autocomplete
                  options={allUsersOrganization}
                  getOptionLabel={(option) => option?.first_name + ' ' + option?.last_name || ''}
                  value={techEdl}
                  onChange={(e, newValue) => {
                    handleAssignTechEDL(e, newValue);
                    setExternAssignation(false);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Rechercher un utilisateur *" variant="outlined" />
                  )}
                  size="small"
                  noOptionsText="Aucun contact trouvé"
                  isOptionEqualToValue={(option, value) => option?.name === value?.name}
                  onInputChange={(event, newInputValue) => {
                    if (!newInputValue) {
                      setTechEdl(null);
                    }
                  }}
                />
              </Grid2>
            </>
          )}

          {externAttribution && (
            <>
              <Grid2 size={12}>
                <Typography variant={mediaQueries?.matchesMd ? 'h5' : 'h6'} sx={{ color: 'primary' }}>
                  ATTRIBUER LA MISSION EDL A UN PRESTATAIRE
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 11, md: 5 }}>
                <Autocomplete
                  options={allTechs}
                  getOptionLabel={(option) => option?.name || ''}
                  value={techEdl}
                  onChange={(e, newValue) => {
                    handleAssignTechEDL(e, newValue);
                    setExternAssignation(true);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Rechercher un prestataire *" variant="outlined" />
                  )}
                  size="small"
                  noOptionsText="Aucun contact trouvé"
                  isOptionEqualToValue={(option, value) => {
                    return option?.name === value?.name;
                  }}
                  onInputChange={(event, newInputValue) => {
                    if (!newInputValue) {
                      setTechEdl(null);
                    }
                  }}
                />
              </Grid2>
            </>
          )}
        </React.Fragment>
      )}

      <Grid2 size={12} sx={{ mt: '1.5vh' }}>
        <Box sx={{ my: 2 }}>
          <Button
            sx={{ marginRight: 2 }}
            onClick={() => {
              if (edlFormStep === 0) {
                previousStep();
              }
              if (!hasScan && isEdlOut && edlFormStep === 2) {
                goEdlStep(0);
              }
              if (edlFormStep === 3 || edlFormStep === 4) {
                previousActiveStepViewer();
                previousEdlStep();
              } else {
                if (!hasScan && isEdlOut && edlFormStep === 2) {
                  goEdlStep(0);
                } else {
                  previousEdlStep();
                }
              }
            }}
          >
            Retour
          </Button>

          <Button
            variant="contained"
            disabled={
              (edlFormStep === 0 && !isEdlIn && !isEdlOut) ||
              (edlFormStep === 1 && !isEdl3D && !isEdlSimple) ||
              (edlFormStep === 2 && lease === null && !createLease) ||
              (edlFormStep === 3 && (!refFolderEdl || !endLease || !startLease || residents.length === 0)) ||
              (edlFormStep === 4 && (!edlExpectedDate || !contact?.uuid)) ||
              (edlFormStep === 5 && (!internAttribution || !externAttribution) && !techEdl) ||
              (isEdl3D && !hasScan && edlFormStep === 1 && !needScan)
            }
            onClick={() => {
              if (edlFormStep === 2 || edlFormStep === 3) {
                if (edlFormStep === 2 && (lease !== null || createLease)) {
                  nextActiveStepViewer();
                  nextEdlStep();
                } else if (edlFormStep === 3 && refFolderEdl && endLease && startLease && residents.length > 0) {
                  nextActiveStepViewer();
                  nextEdlStep();
                }
              } else if (edlFormStep === 5) {
                if (createLease) {
                  handleCreateLease();
                } else {
                  createMissionOrderEdl(lease.uuid);
                }
              } else {
                nextEdlStep();
              }
            }}
          >
            {edlFormStep === 5 ? 'Terminer' : 'Suivant'}
          </Button>
        </Box>
      </Grid2>

      {addContact && (
        <WizzardContact
          open={addContact}
          isProductPage={true}
          onClose={() => setAddContact(false)}
          isNewContact={true}
          productId={productSelected.uuid}
          contacts={allContacts}
          setContacts={setAllContacts}
        />
      )}

      <Dialog open={missionCreated} onClose={handleCloseMissionCreated}>
        <DialogContent>
          {externAttribution ? (
            <Grid2 container spacing={2} textAlign={'center'}>
              <Grid2 size={12}>
                <Typography variant="h6" color={'primary'}>
                  Votre commande a bien été prise en compte.
                </Typography>
              </Grid2>
              <Grid2 size={12}>
                <Typography variant="h6" color={'primary'}>
                  Vous pouvez suivre son état sur votre espace Régir.
                </Typography>
              </Grid2>
            </Grid2>
          ) : (
            <Grid2 container spacing={2} textAlign={'center'}>
              <Grid2 size={12}>
                <Typography variant="h6" color={'primary'}>
                  La mission a bien été attribuée.
                </Typography>
              </Grid2>
              <Grid2 size={12}>
                <Typography variant="h6" color={'primary'}>
                  Elle est en attente sur votre espace EDL 3D.
                </Typography>
              </Grid2>
            </Grid2>
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={openHelperTextRefFolder} onClose={handleOpenHelperTextRefFolder}>
        <Box sx={{ p: 3 }}>
          <Typography variant="h5" textAlign={'center'} color={'primary'}>
            La référence du dossier que vous allez renseigner nous servira de référence pour la facturation.
          </Typography>
        </Box>
      </Dialog>
    </Grid2>
  );
};
