import { useEffect, useState, useRef, useContext, JSX } from 'react';
import {
  Box,
  Button,
  Grid2,
  Typography,
  Modal,
  Backdrop,
  Fade,
  IconButton,
  InputBase,
  Pagination,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from '@mui/x-data-grid';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { appTheme } from '~/utils/theme';

import Breadcrumb from '~/app/components/Breadcrumb/Breadcrumb';

import { WizzardMissionOrderForm } from '~/app/components/WizzardMissionOrder/WizzardMissionOrderFom';
import { WizzardMissionOrderRecap } from '~/app/components/WizzardMissionOrder/WizzardMissionOrderRecap';

import { useLocation } from 'react-router-dom';
import { api } from '~/api';
import { format } from 'date-fns';
import Chip from '@mui/material/Chip';
import { AppContext } from '~/utils/context/AppContext';
import _ from 'lodash';

import '../../components/DocumentsList/DocumentsList.scss';
import normalizedString from '~/utils/normalizedString';
import { MediaQueryContext } from '~/utils/context/MediaQueryProvider';
import { getOdmChipIcon, getOdmStatusWording, getOdmTypeLabel, getOdmVariant } from '~/utils/references';

export const MissionOrder = ({ spinnerOpen, setSpinnerOpen }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [odms, setOdms] = useState([]);
  const [filteredOdm, setFilteredOdm] = useState([]);
  const [isSummary, _setIsSummary] = useState(false);
  const [rowData, setRowData] = useState({});
  const [searchInput, setSearchInput] = useState('');
  const [selectFinished, setSelectFinished] = useState(false);
  const [selectInProgress, setSelectInProgress] = useState(false);

  const { referencial } = useContext(AppContext);
  const mediaQueries = useContext(MediaQueryContext);

  const refIsSummary = useRef(isSummary);

  const setIsSummary = (data) => {
    refIsSummary.current = data;
    _setIsSummary(data);
  };

  const handleSelectFinished = (event) => {
    setSelectFinished(event.target.checked);
  };
  const handleSelectInProgress = (event) => {
    setSelectInProgress(event.target.checked);
  };

  useEffect(() => {
    api.missionOrders
      .search()
      .then((response) => {
        setOdms(response);
        if (searchInput.length > 0) {
          doTextSearch();
        } else {
          setFilteredOdm(response);
        }
      })
      .catch((error) => {});
  }, [open, isSummary]);

  useEffect(() => {
    if (searchInput.length > 0) {
      doTextSearch();
    } else {
      setFilteredOdm(odms);
    }
  }, [searchInput]);

  const statusSearch = () => {
    if (selectFinished && selectInProgress) {
      setFilteredOdm(odms);
    } else if (selectFinished) {
      const filteredOdms = odms.filter((odm) => {
        if (odm.kind === 1) {
          return odm.edl?.status === 4;
        } else {
          return odm.scan?.status === 2;
        }
      });
      setFilteredOdm(filteredOdms);
    } else if (selectInProgress) {
      const filteredOdms = odms.filter((odm) => {
        if (odm.kind === 1) {
          return odm.edl?.status !== 4;
        } else {
          return odm.scan?.status !== 2;
        }
      });
      setFilteredOdm(filteredOdms);
    } else {
      setFilteredOdm(odms);
    }
  };

  useEffect(() => {
    statusSearch();
  }, [selectFinished, selectInProgress]);

  const doTextSearch = () => {
    if (searchInput.length > 0) {
      const filteredOdms = filteredOdm.filter((odm) => {
        const normalizedSearchInput = normalizedString(searchInput);

        const matchesSearchInput = (str) => {
          if (str === null || str === undefined) {
            return false;
          } else {
            return normalizedString(str).includes(normalizedSearchInput);
          }
        };

        return (
          matchesSearchInput(odm.ref_internal) ||
          matchesSearchInput(odm.data.address.street) ||
          matchesSearchInput(odm.data.address.country) ||
          matchesSearchInput(odm.data.address.city) ||
          matchesSearchInput(odm.data.address.zip_code) ||
          matchesSearchInput(odm.data.address.building_infos.name) ||
          (odm.data.property && matchesSearchInput(odm.data.property.address_complement)) ||
          (odm.user_in_charge && matchesSearchInput(odm.user_in_charge.first_name)) ||
          (odm.user_in_charge && matchesSearchInput(odm.user_in_charge.last_name))
        );
      });

      setFilteredOdm(filteredOdms);
    } else {
      setFilteredOdm(odms);
    }
  };

  const getRowId = (row) => row.uuid;

  const concatAddress = (params) => {
    if (!params?.row?.data?.address) return '';
    let address = '';
    address = `${params?.row?.data?.address.street} ${params?.row?.data?.address.city}, ${params?.row?.data?.address.zip_code}`;
    if (params?.row?.data?.property?.address_complement) {
      address += ` - ${params?.row?.data?.property?.address_complement}`;
    }
    return (
      <Typography variant="body2" sx={{ mt: '15px' }}>
        {address}
      </Typography>
    );
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const theData = format(date, 'dd/MM/yyyy');
    return (
      <Typography variant="body2" sx={{ mt: '15px' }}>
        {theData}
      </Typography>
    );
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const theData = format(date, 'dd/MM/yyyy HH:mm');
    return (
      <Typography variant="body2" sx={{ mt: '15px' }}>
        {theData}
      </Typography>
    );
  };

  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    if (pageCount === 1) return <div></div>;
    return (
      <Pagination
        color="primary"
        count={pageCount}
        page={page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'ref_internal',
      headerName: 'Ref dossier',
      headerClassName: 'cibel-app-theme--header',
      width: 170,
      renderCell: (params) => (
        <Typography variant="body2" sx={{ mt: '15px' }}>
          {params?.row?.ref_internal}
        </Typography>
      )
    },

    {
      field: 'status',
      headerName: 'Statut',
      headerClassName: 'cibel-app-theme--header',
      width: 155,
      renderCell: (params) => {
        return (
          <Box>
            <Chip
              size="small"
              icon={getOdmChipIcon(params?.row)}
              label={getOdmStatusWording(params?.row, referencial)}
              sx={{
                color: getOdmVariant(params?.row) + '.main',
                backgroundColor: getOdmVariant(params?.row) + '.light'
              }}
            />
          </Box>
        );
      }
    },
    {
      field: 'kind',
      headerName: 'Type',
      headerClassName: 'cibel-app-theme--header',
      width: 100,
      renderCell: (params) => (
        <Typography variant="body2" sx={{ mt: '15px' }}>
          {getOdmTypeLabel(params?.row?.kind, params?.row?.edl?.kind)}
        </Typography>
      )
    },
    {
      field: `address`,
      headerName: 'Adresse',
      headerClassName: 'cibel-app-theme--header',
      width: 365,
      renderCell: concatAddress
    },
    {
      field: 'created_at',
      headerName: 'Date de création',
      headerClassName: 'cibel-app-theme--header',
      width: 140,
      renderCell: (params) => formatDate(params?.row?.created_at)
    },
    {
      field: 'end_lease',
      headerName: 'Date dédite',
      headerClassName: 'cibel-app-theme--header',
      width: 120,
      renderCell: (params) => {
        const endStartDate = params?.row?.data?.start_lease;
        return endStartDate && formatDate(endStartDate);
      }
    },
    {
      field: 'execution_date',
      headerName: 'Date et heure RDV',
      headerClassName: 'cibel-app-theme--header',
      width: 150,
      renderCell: (params) => {
        const appointmentDate = params?.row?.appointment_date;
        return appointmentDate !== null && formatDateTime(appointmentDate);
      }
    },
    {
      field: 'uuid_user_in_charge',
      headerName: 'Technicien assigné',
      headerClassName: 'cibel-app-theme--header',
      width: !mediaQueries.matchesMd ? 150 : 200,
      flex: !mediaQueries.matchesMd ? 0 : 1,
      renderCell: (params) => {
        return (
          <Typography variant="body2" sx={{ mt: '15px' }}>
            {params?.row?.user_in_charge?.first_name} {params?.row?.user_in_charge?.last_name}
          </Typography>
        );
      }
    }
  ];

  useEffect(() => {
    if (location?.state?.data) {
      setOpen(true);
    }
    setSpinnerOpen(false);
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(!open);
    if (location?.state?.data) location.state.data = null;
  };

  const handleIsSummary = () => {
    setIsSummary(!isSummary);
  };

  const testIfFooter = filteredOdm?.length < 10 ? true : false;

  return (
    <Grid2 container spacing={2}>
      <Grid2 size={12}>
        <Typography
          variant={mediaQueries.matchesXl ? 'h1' : mediaQueries.matchesLg ? 'h2' : mediaQueries.matchesMd ? 'h3' : 'h4'}
          sx={{ color: 'primary.main', paddingTop: '15px' }}
        >
          Ordres de services
        </Typography>
        <Breadcrumb
          data={[{ label: 'Accueil', href: '/' }]}
          last={`Ordres de services`}
          setSpinnerOpen={setSpinnerOpen}
        />
      </Grid2>
      <Grid2 size={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          color="secondary"
          variant="outlined"
          sx={{ color: 'secondary', borderRadius: '20px', paddingTop: '4px', borderColor: 'secondary.main' }}
          onClick={handleOpen}
          size="small"
        >
          <AddOutlinedIcon fontSize="small" style={{ paddingBottom: '2px' }} />
          <span style={{ fontSize: '15px' }}>&nbsp; Nouvelle demande</span>
        </Button>
      </Grid2>
      <Grid2 size={12}>
        <Typography variant="h5" sx={{ color: 'primary.main' }}>
          Tous les ordres de services
        </Typography>
      </Grid2>
      <Grid2 size={{ xs: 12, sm: 12, md: 9 }} sx={{ display: 'flex' }}>
        <div
          style={{
            alignItems: 'center',
            backgroundColor: appTheme.palette.primary[50],
            border: `1px solid`,
            borderColor: appTheme.palette.primary[100],
            borderRadius: '20px',
            display: 'flex',
            height: '36px',
            fontSize: '16px',
            width: '85%'
          }}
        >
          <InputBase
            autoComplete="off"
            placeholder="Rechercher"
            inputProps={{
              sx: {
                pl: 1.8
              }
            }}
            sx={{ flex: 1 }}
            onChange={(e) => {
              const input = normalizedString(e.target.value);
              setSearchInput(input);
            }}
          />
          <SearchOutlinedIcon
            sx={{
              color: 'primary.main',
              ml: 2,
              mr: 1
            }}
            fontSize="medium"
          />
        </div>
      </Grid2>

      <Grid2 size={{ xs: 12, sm: 12, md: 2 }} sx={{ display: 'flex', flexDirection: 'row' }}>
        <FormGroup sx={{ display: 'flex', flexDirection: 'row', ml: '0.5vw' }}>
          <FormControlLabel
            control={<Checkbox checked={selectFinished} onChange={handleSelectFinished} />}
            label="Terminés"
          />
          <FormControlLabel
            control={<Checkbox checked={selectInProgress} onChange={handleSelectInProgress} />}
            label="En cours"
          />
        </FormGroup>
      </Grid2>
      <Grid2 size={12}>
        <div
          style={{
            width: '100%'
          }}
        >
          {filteredOdm.length !== 0 && (
            <DataGrid
              className="DocumentsList"
              rows={filteredOdm}
              columns={columns}
              getRowId={getRowId}
              hideFooter={testIfFooter}
              onRowClick={(params) => {
                handleIsSummary();
                setRowData(params.row);
              }}
              sx={{
                boxShadow: 1,
                border: 1,
                borderRadius: '20px',
                overflow: 'scroll',
                overflowX: 'hidden',
                overflowY: 'hidden',
                mb: 5
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'created_at', sort: 'desc' }]
                },
                pagination: { paginationModel: { page: 0, pageSize: 10 } }
              }}
              slots={{
                pagination: CustomPagination
              }}
            />
          )}
        </div>
      </Grid2>
      <Modal open={isSummary} onClose={handleIsSummary}>
        <Box
          sx={{
            position: 'absolute',
            top: '7.5%',
            left: !mediaQueries.matchesMd ? '7.5%' : '25%',
            width: !mediaQueries.matchesMd ? '75%' : '50%',
            height: '75%',
            backgroundColor: 'white',
            borderRadius: '20px',
            p: 3
          }}
        >
          <WizzardMissionOrderRecap data={rowData} close={handleIsSummary} />
        </Box>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '98%',
              height: '100%',
              backgroundColor: 'white',
              p: 3
            }}
          >
            <Grid2 size={12} sx={{ display: 'flex', justifyContent: 'end' }}>
              <IconButton onClick={handleClose} sx={{ mr: mediaQueries?.matchesMd ? '2vw' : '5vw' }}>
                <CloseIcon />
              </IconButton>
            </Grid2>
            <WizzardMissionOrderForm
              setSpinnerOpen={setSpinnerOpen}
              spinnerOpen={spinnerOpen}
              handleClose={handleClose}
              productData={null}
              scanOrder={false}
            />
          </Box>
        </Fade>
      </Modal>
    </Grid2>
  );
};
