import { Grid2 } from '@mui/material';
import './StepsViewer.scss';
import { Step } from './Step';

export const StepsViewer = ({ steps, currentStep }) => {
  return (
    <Grid2
      container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {steps.map((step, index) => (
        <Step
          key={index}
          title={step}
          index={index}
          status={index === currentStep ? 'current' : index < currentStep ? 'done' : 'todo'}
        />
      ))}
    </Grid2>
  );
};
