import { axiosPost, axiosGet, axiosPatch, axiosDelete } from '.';

export const createLot = (data: any) => {
  return axiosPost('/lots', data);
};

export const deleteLot = (id: string) => {
  return axiosDelete(`/lots/${id}`);
};

export const getLot = (id: string) => {
  return axiosGet(`/lots/${id}`);
};

export const updateLot = (id: string, data: any) => {
  return axiosPatch(`/lots/${id}`, data);
};
